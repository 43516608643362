import React from "react";

const CurrencyFormatter = (props: any) => {
  if (props.value !== null && props.value === undefined) return <div>-</div>;
  if (props.value !== null && props.value !== "") return <div>${props.value}</div>;
  if (props.value === null) return <div>-</div>;

  return props.value;
};

export default CurrencyFormatter;
