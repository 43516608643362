import React from "react";
import styled from "styled-components";

import { ISelectedAppointmentForRescheduling, ITimeslot } from "./../../../Features/Tracker/Tracker.interfaces";
import { formatDate } from "./../../../Features/UI/helpers/index.helper";

interface IProps {
  selectedTimeslot: ISelectedAppointmentForRescheduling | null;
  trackerId: string;
  timezone: string;
  timeslot: ITimeslot;
  selectNewTimeslot: () => void;
}

const Timeslot = (props: IProps) => {
  const { selectedTimeslot, trackerId, timeslot, timezone, selectNewTimeslot } = props;
  const isSelected =
    selectedTimeslot?.target?.trackerId === trackerId && selectedTimeslot.target?.timeslotId === timeslot.id;

  return (
    <TimeslotStyled
      data-cy="free-timeslot"
      onClick={selectNewTimeslot}
      temporaryReserved={false}
      isSelected={isSelected}>
      {/*{timeslot.temporaryReserved ? (*/}
      {/*  <Tooltip placement="top" title="This timeslot is temporary reserved">*/}
      {/*    {formatDate(timeslot.startTime, timezone, "LT")}*/}
      {/*  </Tooltip>*/}
      {/*) : (*/}
      {/*  formatDate(timeslot.startTime, timezone, "LT")*/}
      {/*)}*/}
      {formatDate(timeslot.startDate, timezone, "LT")}
    </TimeslotStyled>
  );
};

export default Timeslot;

interface ITimeslotStyledProps {
  temporaryReserved: boolean;
  isSelected: boolean;
}

export const TimeslotStyled = styled.div`
  cursor: pointer;
  font-size: 12px;
  padding: 5px 0;
  width: 65px;
  text-align: center;
  border: ${(props: ITimeslotStyledProps) => (props.temporaryReserved ? "1px solid #c62828" : "1px solid #e8e8e8")};
  background-color: ${(props: ITimeslotStyledProps) => (props.isSelected ? "#1890ff" : "#ffffff")};
  color: ${(props: ITimeslotStyledProps) => (props.isSelected ? "#ffffff" : "#000000")};
  border-radius: 5px;
  margin: 5px;

  &:hover {
    border: 1px solid #1890ff;
    background-color: #1890ff;
    color: #ffffff;
  }
`;
