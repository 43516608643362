import React from "react";
import styled from "styled-components";

import Timeslot from "./../../../Features/UI/components/Timeslot.component";
import { ISelectedAppointmentForRescheduling, ITimeslot } from "../../Tracker/Tracker.interfaces";

interface IProps {
  selectedTimeslot: ISelectedAppointmentForRescheduling | null;
  trackerId: string;
  timezone: string;
  availableTimeslots: ITimeslot[];
  selectNewTimeslot: (payload: { trackerId: string; timeslotId: string }) => void;
}

const TimeslotsContainer = (props: IProps) => {
  const { selectedTimeslot, trackerId, availableTimeslots, timezone, selectNewTimeslot } = props;
  return (
    <Container>
      {availableTimeslots.map((timeslot) => {
        return (
          <Timeslot
            key={timeslot.id}
            trackerId={trackerId}
            selectedTimeslot={selectedTimeslot}
            timeslot={timeslot}
            timezone={timezone}
            selectNewTimeslot={() => selectNewTimeslot({ trackerId, timeslotId: timeslot.id })}
          />
        );
      })}
    </Container>
  );
};

export default TimeslotsContainer;

const Container = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
