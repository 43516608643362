import React from "react";
import { Dropdown, Menu, Icon, Modal } from "antd";
import { getColumnSettings } from "./../../../../Features/Tracker/Tracker.helpers";
import StatusFormatter from "./../../../../Features/Tracker/components/spreadsheet/StatusFormatter.component";
import ExamTypeFormatter from "./../../../../Features/Tracker/components/spreadsheet/TypeFormatter.component";
import DilationTypeFormatter from "./../../../../Features/Tracker/components/spreadsheet/DilationTypeFormatter.component";

const { confirm } = Modal;

interface IProps {
  type: string;
  row: any;
  updateValue: (propertyName: string, value: string | number | object) => void;
}

class DropdownFormatter extends React.Component<IProps> {
  public handleOnClick = (item: any) => {
    const { type, updateValue } = this.props;

    if (type === "status" && (item.value === "5" || item.value === 5)) {
      confirm({
        title: "Are you sure?",
        content: `Changing status to Checked Out will send available documents to the patient. Have you completed the patient's checkout in Uprise? `,
        okText: "Yes",
        cancelText: "No",
        onOk() {
          updateValue(type, item.value);
        },
      });
    } else if (type === "materialsInsurance" || type === "examInsurance") {
      updateValue(type, item);
    } else {
      updateValue(type, item.value);
    }
  };

  public returnCellContent = () => {
    const { row } = this.props.row;
    const { type } = this.props;

    switch (type) {
      case "status":
        return <StatusFormatter {...this.props.row} />;
      case "type":
        return <ExamTypeFormatter {...this.props.row} />;
      case "dilationType":
        return <DilationTypeFormatter {...this.props.row} />;
      case "examInsurance":
        const examInsurance = getColumnSettings("examInsurance").selectOptions.find(
          (e) => e.id === row.appointment.survey.primaryInsurance.insuranceId
        );
        return (
          <>
            <span>{examInsurance ? examInsurance.value : ""}</span> <Icon type="down" />
          </>
        );
      case "materialsInsurance":
        const materialsInsurance = getColumnSettings("materialsInsurance").selectOptions.find(
          (e) => e.id === row.appointment.survey.secondaryInsurance.insuranceId
        );
        return (
          <>
            <span>{materialsInsurance ? materialsInsurance.value : ""}</span> <Icon type="down" />
          </>
        );
      default:
        return (
          <>
            <span>{row.appointment[type]}</span> <Icon type="down" />
          </>
        );
    }
  };

  public render() {
    const { row } = this.props.row;
    const { type } = this.props;

    const menu = (
      <Menu>
        {type !== "status" && type !== "type" && (
          <Menu.Item onClick={() => this.handleOnClick({ value: null })}>-</Menu.Item>
        )}
        {getColumnSettings(type).selectOptions!.map((item) => {
          return (
            <Menu.Item key={item.id} onClick={() => this.handleOnClick(item)}>
              {item.title}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    return row.appointment ? (
      <Dropdown overlay={menu} trigger={["click"]}>
        <a className="ant-dropdown-link" href="#">
          {this.returnCellContent()}
        </a>
      </Dropdown>
    ) : null;
  }
}

export default DropdownFormatter;
