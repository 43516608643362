import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Dropdown, Icon, Menu, Button } from "antd";
import moment from "moment-timezone";
import Datepicker from "./../../Features/Topbar/components/Datepicker.component";
import Trackers from "./../../Features/Topbar/components/Trackers.component";
import { IStore } from "./../../Infrastructure/Store/store.interface";
import {
  updateDatepicker,
  selectTracker,
  getTrackers,
  clearSelectedTracker,
  selectInitialTracker,
  getTrackerFromParam,
  clearAvailableTrackers,
  getTrackerDate,
} from "./../../Features/Topbar/Topbar.actions";
import { IGetAvailableTrackersPayload } from "./../../Features/Topbar/Topbar.interfaces";

import { IState as ITopbarState } from "./Topbar.reducer";
import { IAction as ITopbarAction } from "./Topbar.actions";

interface IProps {
  topbar: ITopbarState;

  updateDatepicker: (date: Date) => ITopbarAction;
  getTrackers: (payload: IGetAvailableTrackersPayload) => ITopbarAction;
  selectTracker: (id: string) => ITopbarAction;
  selectInitialTracker: () => ITopbarAction;
  clearSelectedTracker: () => ITopbarAction;
  clearAvailableTrackers: () => ITopbarAction;
  getTrackerFromParam: (payload: { trackerId: string; date: Date }) => ITopbarAction;
  getTrackerDate: (payload: { trackerId: string }) => ITopbarAction;
}

export class Topbar extends Component<IProps> {
  public componentDidMount(): void {
    const { selectInitialTracker, getTrackerFromParam, getTrackerDate, updateDatepicker } = this.props;
    const params = new URLSearchParams(window.location.search);
    const trackerId = params.get("trackerId");
    const date = params.get("date");

    if (date && !trackerId) {
      updateDatepicker(moment(date).toDate());
    }

    if (!trackerId && !date) {
      selectInitialTracker();
    }

    if (trackerId && !date) {
      getTrackerDate({
        trackerId,
      });
    }

    if (date && trackerId) {
      getTrackerFromParam({
        trackerId,
        date: moment(date).toDate(),
      });
    }
  }

  public componentDidUpdate = (prevProps: IProps) => {
    const params = new URLSearchParams(window.location.search);
    const trackerId = params.get("trackerId");
    const { topbar, clearSelectedTracker, getTrackerFromParam } = this.props;
    if (topbar.selectedDate !== prevProps.topbar.selectedDate) {
      clearSelectedTracker();
      if (topbar.selectedDate) {
        this.getTrackersForSpecificDay(topbar.selectedDate.toISOString());

        if (trackerId) {
          getTrackerFromParam({
            trackerId,
            date: topbar.selectedDate,
          });
          window.history.replaceState("", "", "/");
        }
      }
    }
  };

  public getTrackersForSpecificDay = (date: string) => {
    this.props.getTrackers({
      startDate: moment(date).utc(true).startOf("day").toDate(),
      endDate: moment(date).utc(true).startOf("day").toDate(),
    });
  };

  public updateDate = (date: string) => {
    this.props.updateDatepicker(moment(date).toDate());
    this.getTrackersForSpecificDay(date);
  };

  public addDay = () => {
    const { topbar, updateDatepicker, clearAvailableTrackers } = this.props;
    const nextDay = moment(topbar.selectedDate).add(1, "d");
    clearAvailableTrackers();
    updateDatepicker(nextDay.toDate());
    this.getTrackersForSpecificDay(nextDay.format());
  };

  public subtractDay = () => {
    const { topbar, updateDatepicker, clearAvailableTrackers } = this.props;
    const previousDay = moment(topbar.selectedDate).subtract(1, "d");
    clearAvailableTrackers();
    updateDatepicker(previousDay.toDate());
    this.getTrackersForSpecificDay(previousDay.format());
  };

  public selectTracker = (id: string) => {
    const { selectTracker } = this.props;
    selectTracker(id);
  };

  public render() {
    const { topbar } = this.props;
    const menu = (
      <Menu>
        <Menu.Item key="1">
          <Link to="/calendar">Calendar</Link>
        </Menu.Item>
      </Menu>
    );
    return (
      <>
        <DatepickerContainer>
          <div style={{ display: "flex" }}>
            <Datepicker
              date={topbar.selectedDate}
              updateDate={this.updateDate}
              addDay={this.addDay}
              subtractDay={this.subtractDay}
            />
            <Link to="/patient-lookup" style={{ marginLeft: "20px" }}>
              <StyledButton icon="search">Patient Lookup</StyledButton>
            </Link>
          </div>
          <div>
            <Link to="/waiting-room" style={{ marginRight: "20px" }}>
              <StyledButton>Waiting Room</StyledButton>
            </Link>
            <DropdownStyled overlay={menu} trigger={["click"]}>
              <a className="ant-dropdown-link" style={{ color: "#1890FF" }} href="#">
                Patient Tracker <Icon type="down" />
              </a>
            </DropdownStyled>
          </div>
        </DatepickerContainer>
        <TrackersContainer>
          <Trackers
            selectedTrackerId={topbar.selectedTrackerId}
            availableTrackers={topbar.availableTrackers}
            selectTracker={this.selectTracker}
          />
        </TrackersContainer>
      </>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    topbar: state.topbar,
  };
};

const mapDispatchToProps = {
  updateDatepicker,
  selectTracker,
  getTrackers,
  clearSelectedTracker,
  selectInitialTracker,
  getTrackerFromParam,
  getTrackerDate,
  clearAvailableTrackers,
};

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Topbar);

const DatepickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  margin-bottom: 15px;
  padding: 15px 10px;
  border-bottom: 1px solid #dddddd;
`;

const TrackersContainer = styled.div`
  padding: 0px 10px;
  margin-bottom: 15px;
  overflow-x: auto;
`;

const DropdownStyled = styled(Dropdown)`
  margin-right: 10px;
`;

const StyledButton = styled(Button)`
  background-color: #f1c40f;
  border-color: #f1c40f;
  color: #363636;

  &:hover {
    background-color: #f1c40f;
    border-color: #f1c40f;
    color: #363636;
    opacity: 0.8;
  }
`;
