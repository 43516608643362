import { IEmployee, IUser } from "./../../Features/Staff/Staff.interfaces";

export const GET_EMPLOYEES = "GET_EMPLOYEES";
export const GET_USERS = "GET_USERS";
export const SAVE_USERS = "SAVE_USERS";
export const SAVE_EMPLOYEES = "SAVE_EMPLOYEES";
export const ADD_EMPLOYEES = "ADD_EMPLOYEES";
export const REMOVE_EMPLOYEE = "REMOVE_EMPLOYEE";

export const getEmployees = () => {
  return {
    type: GET_EMPLOYEES,
  };
};

export const saveEmployees = (employees: IEmployee[]) => {
  return {
    type: SAVE_EMPLOYEES,
    payload: employees,
  };
};

export const addEmployees = (employees: { id: string; position: string }[]) => {
  return {
    type: ADD_EMPLOYEES,
    payload: employees,
  };
};

export const removeEmployee = (userId: string) => {
  return {
    type: REMOVE_EMPLOYEE,
    payload: userId,
  };
};
