import { IAvailableTracker, IGetAvailableTrackersPayload } from "./../../Features/Topbar/Topbar.interfaces";
import {
  CLEAR_AVAILABLE_TRACKERS,
  CLEAR_SELECTED_TRACKER,
  GET_TRACKER_DATE,
  GET_TRACKER_FROM_PARAM,
  GET_TRACKERS,
  SAVE_TRACKERS,
  SELECT_INITIAL_TRACKER,
  SELECT_TRACKER,
  UPDATE_DATEPICKER,
} from "./Topbar.constants";

export interface IAction {
  type: string;
  payload?: IAvailableTracker[] | IGetAvailableTrackersPayload | Date | string | number | null;
}

export const updateDatepicker = (date: Date) => {
  return {
    type: UPDATE_DATEPICKER,
    payload: date,
  };
};

export const getTrackers = (payload: IGetAvailableTrackersPayload) => {
  return {
    type: GET_TRACKERS,
    payload,
  };
};

export const getTrackerFromParam = (payload: { trackerId: string; date: Date }) => {
  return {
    type: GET_TRACKER_FROM_PARAM,
    payload,
  };
};

export const saveTrackers = (trackers: IAvailableTracker[]) => {
  return {
    type: SAVE_TRACKERS,
    payload: trackers,
  };
};

export const selectTracker = (id: string) => {
  return {
    type: SELECT_TRACKER,
    payload: id,
  };
};

export const clearSelectedTracker = () => {
  return {
    type: CLEAR_SELECTED_TRACKER,
  };
};

export const clearAvailableTrackers = () => {
  return {
    type: CLEAR_AVAILABLE_TRACKERS,
  };
};

export const selectInitialTracker = () => {
  return {
    type: SELECT_INITIAL_TRACKER,
  };
};

export const getTrackerDate = (payload: { trackerId: string }) => {
  return {
    type: GET_TRACKER_DATE,
    payload,
  };
};
