import React from "react";
import { Modal, Table, Select, Button, Input } from "antd";
import styled from "styled-components";

import { IProduct, ISpreadsheetRow } from "./../../../../Features/Tracker/Tracker.interfaces";
import { IState as ISpreadsheetState } from "../../Tracker.reducer";
import API from "../../../../Infrastructure/API/index";

interface IProps {

  isGlassesModalVisible: boolean;
  selectedRow: ISpreadsheetRow;
  spreadsheet: ISpreadsheetState;
  toggleGlassesModal: () => void;
}

interface IState {
  isRequestLoading: boolean;
  updatedGlasses: IProduct[];
  newGlasses: IProduct[];
  existingGlassesToRemove: string[];
  onHoverId: string;
}

class GlassesModal extends React.Component<IProps, IState> {
  public state: IState = {
    isRequestLoading: false,
    updatedGlasses: [...this.props.selectedRow.row.appointment.products.filter((p) => p.type === "glasses")],
    newGlasses: [],
    existingGlassesToRemove: [],
    onHoverId: "",
  };

  public updateOnHoverId = (value: string) => {
    this.setState({
      onHoverId: value,
    });
  };

  public addNewGlasses = () => {
    const { newGlasses } = this.state;
    this.setState({
      newGlasses: [
        ...newGlasses,
        {
          id: `${newGlasses.length + 1}`,
          status: null,
          type: "glasses",
          frameStatus: null,
          notes: null,
        },
      ],
    });
  };

  public removeGlasses = (id: string) => {
    const { updatedGlasses, newGlasses, existingGlassesToRemove } = this.state;
    const isNew = newGlasses.find((glasses) => glasses.id === id);

    if (isNew) {
      this.setState({
        newGlasses: newGlasses.filter((glasses) => glasses.id !== id),
      });
    } else {
      this.setState({
        updatedGlasses: updatedGlasses.filter((glasses) => glasses.id !== id),
        existingGlassesToRemove: [...existingGlassesToRemove, id],
      });
    }
  };

  public updateStatus = (id: string, status: string) => {
    const { updatedGlasses, newGlasses } = this.state;
    const newPairOfGlasses = newGlasses.find((glasses) => glasses.id === id);

    if (newPairOfGlasses) {
      newPairOfGlasses.status = status;
      this.setState({
        newGlasses: newGlasses.map((glasses) => {
          if (glasses.id === id) {
            return newPairOfGlasses;
          }
          return glasses;
        }),
      });
    } else {
      const existingPairOfGlasses = {
        ...updatedGlasses.find((glasses) => glasses.id === id)!,
      };
      existingPairOfGlasses.status = status;
      this.setState({
        updatedGlasses: updatedGlasses.map((glasses) => {
          if (glasses.id === id) {
            return existingPairOfGlasses;
          }
          return glasses;
        }),
      });
    }
  };

  public updateFrameStatus = (id: string, frameStatus: string) => {
    const { updatedGlasses, newGlasses } = this.state;
    const newPairOfGlasses = newGlasses.find((glasses) => glasses.id === id);

    if (newPairOfGlasses) {
      newPairOfGlasses.frameStatus = frameStatus;
      this.setState({
        newGlasses: newGlasses.map((glasses) => {
          if (glasses.id === id) {
            return newPairOfGlasses;
          }
          return glasses;
        }),
      });
    } else {
      const existingPairOfGlasses = {
        ...updatedGlasses.find((glasses) => glasses.id === id)!,
      };
      existingPairOfGlasses.frameStatus = frameStatus;
      this.setState({
        updatedGlasses: updatedGlasses.map((glasses) => {
          if (glasses.id === id) {
            return existingPairOfGlasses;
          }
          return glasses;
        }),
      });
    }
  };

  public updateNotes = (id: string, notes: string) => {
    const { updatedGlasses, newGlasses } = this.state;
    const newPairOfGlasses = newGlasses.find((glasses) => glasses.id === id);

    if (newPairOfGlasses) {
      newPairOfGlasses.notes = notes;
      this.setState({
        newGlasses: newGlasses.map((glasses) => {
          if (glasses.id === id) {
            return newPairOfGlasses;
          }
          return glasses;
        }),
      });
    } else {
      const existingPairOfGlasses = {
        ...updatedGlasses.find((glasses) => glasses.id === id)!,
      };
      existingPairOfGlasses.notes = notes;
      this.setState({
        updatedGlasses: updatedGlasses.map((glasses) => {
          if (glasses.id === id) {
            return existingPairOfGlasses;
          }
          return glasses;
        }),
      });
    }
  };

  public onSubmit = async () => {
    const { selectedRow, toggleGlassesModal } = this.props;
    const { newGlasses, existingGlassesToRemove, updatedGlasses } = this.state;

    this.setState({ isRequestLoading: true });

    if (newGlasses.length > 0) {
      const requestBody = newGlasses.map((nG) => ({
        type: "glasses",
        status: nG.status,
        frameStatus: nG.frameStatus,
        notes: nG.notes,
      }));

      await API.addProductToAppointment(selectedRow.row.trackerId, selectedRow.row.appointment!.id, requestBody);
    }

    if (updatedGlasses.length > 0) {
      const requestBody = updatedGlasses.map((uG) => ({
        id: uG.id,
        type: "glasses",
        status: uG.status,
        frameStatus: uG.frameStatus,
        notes: uG.notes,
      }));

      await API.updateAppointmentProduct(selectedRow.row.trackerId, selectedRow.row.appointment!.id, requestBody);
    }

    for (const eG of existingGlassesToRemove) {
      await API.removeAppointmentProduct(selectedRow.row.trackerId, selectedRow.row.appointment!.id, eG);
    }
    this.setState({ isRequestLoading: false });
    toggleGlassesModal();
  };

  public render() {
    const { spreadsheet, isGlassesModalVisible, toggleGlassesModal } = this.props;
    const { updatedGlasses, newGlasses, onHoverId, isRequestLoading } = this.state;
    const dataSource = [...updatedGlasses, ...newGlasses].map((glasses, index) => ({
      key: glasses.id,
      name: `#${index + 1}`,
      id: glasses.id,
      status: glasses.status,
      frameStatus: glasses.frameStatus,
      notes: glasses.notes,
    }));

    const columns = [
      {
        title: "Item",
        dataIndex: "name",
        key: "name",
        align: "center",
      },
      {
        title: "Order status",
        dataIndex: "status",
        key: "status",
        render: (value: string, glasses: IProduct) => {
          return (
            <Select
              defaultValue={value}
              style={{ width: 120 }}
              onChange={(value: string) => this.updateStatus(glasses.id, value)}>
              <Select.Option value={null} disabled>
                -
              </Select.Option>
              {spreadsheet.options.products.glasses.orderStatus.map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          );
        },
      },
      {
        title: "Frame status",
        dataIndex: "frameStatus",
        key: "frameStatus",
        render: (value: string, glasses: IProduct) => {
          return (
            <Select
              defaultValue={value}
              style={{ width: 200 }}
              onChange={(value: string) => this.updateFrameStatus(glasses.id, value)}>
              <Select.Option value={null}>-</Select.Option>
              {spreadsheet.options.products.glasses.frameStatus.map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          );
        },
      },
      {
        title: "Notes",
        dataIndex: "notes",
        key: "notes",
        render: (value: string, glasses: IProduct) => {
          return (
            <Input.TextArea
              placeholder="Example note..."
              defaultValue={value}
              onBlur={(e) => this.updateNotes(glasses.id, e.target.value)}
              rows={1}
            />
          );
        },
      },
      {
        title: "",
        dataIndex: "id",
        key: "id",
        render: (id: string, glasses: IProduct) => {
          return (
            <RemoveGlassesContainer>
              {onHoverId === id && (
                <Button
                  id="remove-button"
                  type="danger"
                  shape="circle"
                  icon="delete"
                  onClick={() => this.removeGlasses(glasses.id)}
                />
              )}
            </RemoveGlassesContainer>
          );
        },
      },
    ];

    return (
      <div>
        <Modal
          title="How many pairs would the customer like to order?"
          width={850}
          visible={isGlassesModalVisible}
          footer={[
            <Button key="back" onClick={toggleGlassesModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={isRequestLoading} onClick={this.onSubmit}>
              Save
            </Button>,
          ]}>
          <div>
            {dataSource.length > 0 && (
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                onRow={(record) => {
                  return {
                    onMouseEnter: () => this.updateOnHoverId(record.id),
                    onMouseLeave: () => this.updateOnHoverId(""),
                  };
                }}
              />
            )}
          </div>
          <AntdButton icon="plus" glasseslength={dataSource.length} onClick={this.addNewGlasses}>
            Add new item
          </AntdButton>
        </Modal>
      </div>
    );
  }
}

export default GlassesModal;

const AntdButton = styled(Button)`
  margin-top: ${({ glasseslength }: { glasseslength: number }) => (glasseslength > 0 ? "20px" : 0)};
`;

const RemoveGlassesContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 35px;
`;
