import { IAddPatientToWaitlist, IInsuranceProvider, IStatusOfQuestionnaire, ITracker } from "./Tracker.interfaces";
import {
  ADD_PATIENT_TO_WAITLIST,
  CLEAR_TRACKER_DATA,
  GET_ANOTHER_TRACKERS,
  GET_INSURANCE_PROVIDERS,
  GET_TRACKER_DATA,
  REFRESH_TRACKER_DATA,
  SAVE_ANOTHER_TRACKERS,
  SAVE_INSURANCE_PROVIDERS,
  SAVE_STATUS_OF_QUESTIONNAIRES,
  SAVE_TRACKER_DATA,
  UPDATE_APPOINTMENT,
  UPDATE_LOADING_STATUS_SPREADSHEET,
  UPDATE_PATIENT,
} from "./Tracker.constants";

export interface IAction {
  type: string;
  payload?:
    | ITracker
    | ITracker[]
    | IInsuranceProvider[]
    | IUpdateLoadingStatus
    | IUpdateAppointment
    | IUpdatePatient
    | IStatusOfQuestionnaire[]
    | string
    | IAddPatientToWaitlist;
}

export interface IUpdateLoadingStatus {
  property: string;
  value: boolean;
}

export interface IUpdateAppointment {
  timeslotId: string;
  appointmentId: string;
  property: string;
  value: string | number | null | undefined;
}

export interface IUpdatePatient {
  patientId: string;
  property: string;
  value: string | number;
}

export const getTrackerData = (trackerId: string) => {
  return {
    type: GET_TRACKER_DATA,
    payload: trackerId,
  };
};

export const saveTrackerData = (data: ITracker) => {
  return {
    type: SAVE_TRACKER_DATA,
    payload: data,
  };
};

export const getAnotherTrackers = () => {
  return {
    type: GET_ANOTHER_TRACKERS,
  };
};

export const saveAnotherTrackers = (data: ITracker[]) => {
  return {
    type: SAVE_ANOTHER_TRACKERS,
    payload: data,
  };
};

export const getInsuranceProviders = () => {
  return {
    type: GET_INSURANCE_PROVIDERS,
  };
};

export const saveInsuranceProviders = (payload: IInsuranceProvider) => {
  return {
    type: SAVE_INSURANCE_PROVIDERS,
    payload,
  };
};

export const clearTrackerData = () => {
  return {
    type: CLEAR_TRACKER_DATA,
  };
};

export const updateLoadingStatus = (payload: IUpdateLoadingStatus) => {
  return {
    type: UPDATE_LOADING_STATUS_SPREADSHEET,
    payload,
  };
};

export const refreshTrackerData = (trackerId: string) => {
  return {
    type: REFRESH_TRACKER_DATA,
    payload: trackerId,
  };
};

export const updateAppointment = (payload: IUpdateAppointment) => {
  return {
    type: UPDATE_APPOINTMENT,
    payload,
  };
};

export const updatePatient = (payload: IUpdatePatient) => {
  return {
    type: UPDATE_PATIENT,
    payload,
  };
};

export const saveStatusOfQuestionnaires = (payload: IStatusOfQuestionnaire[]) => {
  return {
    type: SAVE_STATUS_OF_QUESTIONNAIRES,
    payload,
  };
};

export const addPatientToWaitlist = (payload: IAddPatientToWaitlist) => {
  return {
    type: ADD_PATIENT_TO_WAITLIST,
    payload,
  };
};
