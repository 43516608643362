export const GET_TRACKER_DATA = "GET_TRACKER_DATA";
export const SAVE_TRACKER_DATA = "SAVE_TRACKER_DATA";
export const REFRESH_TRACKER_DATA = "REFRESH_TRACKER_DATA";
export const GET_ANOTHER_TRACKERS = "GET_ANOTHER_TRACKERS";
export const SAVE_ANOTHER_TRACKERS = "SAVE_ANOTHER_TRACKERS";
export const GET_INSURANCE_PROVIDERS = "GET_INSURANCE_PROVIDERS";
export const SAVE_INSURANCE_PROVIDERS = "SAVE_INSURANCE_PROVIDERS";
export const CLEAR_TRACKER_DATA = "CLEAR_TRACKER_DATA";
export const UPDATE_LOADING_STATUS_SPREADSHEET = "UPDATE_LOADING_STATUS_SPREADSHEET";
export const UPDATE_APPOINTMENT = "UPDATE_APPOINTMENT";
export const UPDATE_PATIENT = "UPDATE_PATIENT";
export const SAVE_STATUS_OF_QUESTIONNAIRES = "SAVE_STATUS_OF_QUESTIONNAIRES";
export const ADD_PATIENT_TO_WAITLIST = "ADD_PATIENT_TO_WAITLIST";
