import React, { Component } from "react";
import "antd/dist/antd.css";
import "./Features/UI/styles/index.css";
import "react-data-grid-multiline-header/style.css";
import Main from "./Main";
import { Spin } from "antd";
import Authentication from "./Features/Authentication/Authentication.container";
import { checkLoggedIn } from "./Features/Authentication/Authentication.actions";
import { IStore } from "./Infrastructure/Store/store.interface";
import { connect } from "react-redux";

interface IProps {
  isAuthenticated: boolean;
  isLoading: boolean;
  checkLoggedIn: () => void;
}

class App extends Component<IProps> {
  public async componentDidMount() {
    this.props.checkLoggedIn();
  }

  public render() {
    if (this.props.isLoading)
      return (
        <div style={{ textAlign: "center", marginTop: 50 }}>
          <Spin size="large" />
        </div>
      );

    return this.props.isAuthenticated ? <Main /> : <Authentication />;
  }
}

const mapDispatchToProps = {
  checkLoggedIn,
};

const mapStateToProps = (state: IStore) => {
  return {
    isAuthenticated: state.authentication.isAuthenticated,
    isLoading: state.authentication.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
