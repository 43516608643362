import { IUserDetailsResponse } from "./../../Features/Authentication/Authentication.interfaces";

export const SAVE_LOGIN_DETAILS = "SAVE_LOGIN_DETAILS";
export const CHECK_LOGGED_IN = "CHECK_LOGGED_IN";
export const CHECK_LOGGED_IN_FAILURE = "CHECK_LOGGED_IN_FAILURE";

export const checkLoggedIn = (userDetailsResponse: IUserDetailsResponse) => {
  return {
    type: CHECK_LOGGED_IN,
    payload: userDetailsResponse,
  };
};

export const checkLoggedInFailure = () => {
  return {
    type: CHECK_LOGGED_IN_FAILURE,
  };
};

export const saveUserDetails = (userDetailsResponse: IUserDetailsResponse) => {
  return {
    type: SAVE_LOGIN_DETAILS,
    payload: userDetailsResponse,
  };
};
