import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import styled from "styled-components";
import { Form } from "antd";
import { FormComponentProps } from "antd/es/form";

import { IStore } from "./../../../Infrastructure/Store/store.interface";
import { IState as INotes, IAction } from "./../../../Features/Notes/Notes.interfaces";

import { getSiteNotes, updateField, updateSiteNotes } from "./../../../Features/Notes/Notes.actions";

import SiteNotesComponent from "./../../../Features/Notes/components/SiteNotes.component";

interface IProps extends FormComponentProps {
  notes: INotes;
  selectedTracker: number;
  isTrackerLoading: boolean;
  getSiteNotes: (trackerId: number) => IAction;
  updateField: (payload: { property: string; value: string }) => IAction;
  updateSiteNotes: (payload: { trackerId: number; notes: string }) => IAction;
}

class SiteNotes extends Component<IProps> {
  public componentDidMount = () => {
    const { selectedTracker, getSiteNotes } = this.props;
    getSiteNotes(selectedTracker);
  };

  public componentDidUpdate = (prevProps: IProps) => {
    const { selectedTracker, getSiteNotes } = this.props;
    if (selectedTracker !== prevProps.selectedTracker && selectedTracker !== null) {
      getSiteNotes(selectedTracker);
    }
  };

  public updateSiteNotes = () => {
    const { notes, selectedTracker, updateSiteNotes } = this.props;
    updateSiteNotes({ trackerId: selectedTracker, notes: notes.siteNotes });
  };

  public render() {
    const { selectedTracker, isTrackerLoading, notes, updateField, form } = this.props;
    return (
      <Container>
        {selectedTracker !== null && !isTrackerLoading && (
          <SiteNotesComponent
            form={form}
            notes={notes}
            updateField={updateField}
            updateSiteNotes={this.updateSiteNotes}
          />
        )}
      </Container>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    notes: state.notes,
    selectedTracker: state.trackers.selectedTracker,
    isTrackerLoading: state.spreadsheet.isLoading.tracker,
  };
};

const mapDispatchToProps = {
  getSiteNotes,
  updateField,
  updateSiteNotes,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), Form.create({}))(SiteNotes);

const Container = styled.div``;
