import React, { Component } from "react";
import ReactDataGrid from "react-data-grid";
import { Editors } from "react-data-grid-addons";
import { Icon, Popconfirm, Select, Modal, message } from "antd";
import { orderBy } from "lodash";
import moment from "moment-timezone";
import styled from "styled-components";
const { DropDownEditor } = Editors;

import { IAddPatientToWaitlist, ISpreadsheetRow } from "./../../../Features/Tracker/Tracker.interfaces";
import {
  cancelledAppointmentStatuses,
  getColumnSettings,
  validAppointmentStatuses,
} from "./../../../Features/Tracker/Tracker.helpers";
import RowRenderer from "./../../../Features/Tracker/components/spreadsheet/RowRenderer.component";
import DropdownFormatter from "./../../../Features/Tracker/components/spreadsheet/DropdownFormatter.component";
import CurrencyFormatter from "./../../../Features/Tracker/components/spreadsheet/CurrencyFormatter.component";
import NotesFormatter from "./../../../Features/Tracker/components/spreadsheet/NotesFormatter.component";
import GlassesModal from "./../../../Features/Tracker/components/spreadsheet/GlassesModal.component";
import ContactsModal from "./../../../Features/Tracker/components/spreadsheet/ContactsModal.component";
import { IState as ITopbarState } from "../../Topbar/Topbar.reducer";
import { IState as ISpreadsheetState } from "../Tracker.reducer";
import { IAction as ISpreadsheetAction, IUpdateAppointment, IUpdatePatient } from "../Tracker.actions";
import API from "../../../Infrastructure/API/index";
import Config from "../../../config/config";
import PopoverMenu from "./../../../Features/Tracker/components/spreadsheet/PopoverMenu.component";

interface IProps {
  history: any;
  topbar: ITopbarState;
  spreadsheet: ISpreadsheetState;
  toggleTimepickerModal: (row: ISpreadsheetRow) => void;
  updateAppointment: (payload: IUpdateAppointment) => ISpreadsheetAction;
  updatePatient: (payload: IUpdatePatient) => ISpreadsheetAction;
  addPatientToWaitlist: (payload: IAddPatientToWaitlist) => ISpreadsheetAction;
}

interface IState {
  appointmentsStatusFilter: "active" | "cancelled" | "all";
  isGlassesModalVisible: boolean;
  isContactsModalVisible: boolean;
  selectedRow: any;
}

class TrackerSpreadsheet extends Component<IProps, IState> {
  public state: IState = {
    appointmentsStatusFilter: "active",
    isGlassesModalVisible: false,
    isContactsModalVisible: false,
    selectedRow: {},
  };
  public defaultColumnProperties = {
    editable: true,
    resizable: true,
    draggable: false,
  };

  public updateAppointmentsStatusFilter = (value: IState["appointmentsStatusFilter"]) => {
    this.setState({ appointmentsStatusFilter: value });
  };

  public toggleGlassesModal = (selectedRow: any = {}) => {
    this.setState({
      isGlassesModalVisible: !this.state.isGlassesModalVisible,
      selectedRow,
    });
  };

  public toggleContactsModal = (selectedRow: any = {}) => {
    this.setState({
      isContactsModalVisible: !this.state.isContactsModalVisible,
      selectedRow,
    });
  };

  public adjustColumnsToAppointmentType = (columns: any, appointmentType: string) => {
    if (this.hiddenColumns[appointmentType]) {
      return columns.filter((column) => !this.hiddenColumns[appointmentType].includes(column.key));
    }

    return columns;
  };

  public hiddenColumns = {
    ro_private: [],
    covid: [],
    telehealth: ["materialsInsurance", "materialsResponsibility", "contacts", "glasses", "cloverPayments"],
    styling: ["examInsurance", "examResponsibility", "examCode"],
  };

  public columns = [
    {
      key: "time",
      name: "Time",
      width: 120,
      formatter: (row: any) => {
        return row.isDisabled ? (
          <LineThroughText>{moment(new Date(row.row.startDate)).tz(row.row.timezone).format("LT")}</LineThroughText>
        ) : (
          <span>{moment(new Date(row.row.startDate)).tz(row.row.timezone).format("LT")}</span>
        );
      },
    },
    {
      key: "name",
      name: "Name",
      width: 120,
      formatter: (row: ISpreadsheetRow) => <PopoverMenu row={row} field="name" />,
    },
    {
      key: "status",
      name: "Status",
      width: 120,
      editor: <DropDownEditor options={getColumnSettings("status").selectOptions!} />,
      formatter: (row: any) => (
        <DropdownFormatter
          type="status"
          row={row}
          updateValue={(property, value) => {
            if (cancelledAppointmentStatuses.includes(value)) {
              this.addPatientToWaitlistAction(row);
            }
            this.props.updateAppointment({
              timeslotId: row.row.id,
              appointmentId: row.row.appointment.id,
              property,
              value,
            });
          }}
        />
      ),
    },
    {
      key: "type",
      name: "Type",
      width: 88,
      editor: <DropDownEditor options={getColumnSettings("type").selectOptions!} />,
      formatter: (row: ISpreadsheetRow) => (
        <DropdownFormatter
          type="type"
          row={row}
          updateValue={(property, value) =>
            this.props.updateAppointment({
              timeslotId: row.row.id,
              appointmentId: row.row.appointment.id,
              property,
              value,
            })
          }
        />
      ),
    },
    {
      key: "examInsurance",
      name: "Exam Insurance",
      width: 100,
      editor: <DropDownEditor options={getColumnSettings("examInsurance").selectOptions!} />,
      formatter: (row: ISpreadsheetRow) => (
        <DropdownFormatter
          type="examInsurance"
          row={row}
          updateValue={(property, value) =>
            this.props.updateAppointment({
              timeslotId: row.row.id,
              appointmentId: row.row.appointment.id,
              property,
              value,
            })
          }
        />
      ),
    },
    {
      key: "examResponsibility",
      name: "Exam Responsibility",
      width: 110,
      formatter: (row: ISpreadsheetRow) => (
        <CurrencyFormatter value={row.row.appointment ? row.row.appointment.examResponsibility : ""} />
      ),
    },
    {
      key: "materialsInsurance",
      name: "Materials Insurance",
      width: 100,
      editor: <DropDownEditor options={getColumnSettings("materialsInsurance").selectOptions!} />,
      formatter: (row: ISpreadsheetRow) => (
        <DropdownFormatter
          type="materialsInsurance"
          row={row}
          updateValue={(property, value) =>
            this.props.updateAppointment({
              timeslotId: row.row.id,
              appointmentId: row.row.appointment.id,
              property,
              value,
            })
          }
        />
      ),
    },
    {
      key: "materialsResponsibility",
      name: "Materials Responsibility",
      width: 110,
      formatter: (row: ISpreadsheetRow) => (
        <CurrencyFormatter value={row.row.appointment ? row.row.appointment.materialsResponsibility : ""} />
      ),
    },
    {
      key: "contacts",
      name: "Contact Lenses",
      width: 70,
      formatter: (row: ISpreadsheetRow) => {
        const { appointment } = row.row;
        if (appointment) {
          const contactLenses = appointment.products.filter((p) => p.type === "contact_lenses");
          return (
            <ContactLensesCellFormater onClick={() => this.toggleContactsModal(row)}>
              {contactLenses.length}
            </ContactLensesCellFormater>
          );
        }
        return "";
      },
    },
    {
      key: "glasses",
      name: "Glasses",
      width: 70,
      formatter: (row: ISpreadsheetRow) => {
        const { appointment } = row.row;
        if (appointment) {
          const glasses = appointment.products.filter((p) => p.type === "glasses");
          return (
            <GlassesCellFormater onClick={() => this.toggleGlassesModal(row)}>{glasses.length}</GlassesCellFormater>
          );
        }
        return "";
      },
    },
    {
      key: "cloverPayments",
      name: "Clover payments",
      width: 100,
      editor: <DropDownEditor options={getColumnSettings("cloverPayments").selectOptions!} />,
      formatter: (row: ISpreadsheetRow) => (
        <DropdownFormatter
          type="payment"
          row={row}
          updateValue={(property, value) =>
            this.props.updateAppointment({
              timeslotId: row.row.id,
              appointmentId: row.row.appointment.id,
              property,
              value,
            })
          }
        />
      ),
    },
    // {
    //   key: "dilationType",
    //   name: "Dilation",
    //   width: 88,
    //   editor: (
    //     <DropDownEditor
    //       options={getColumnSettings("dilationType").selectOptions!}
    //     />
    //   ),
    //   formatter: (row: ISpreadsheetRow) => (
    //     <DropdownFormatter
    //       type="dilationType"
    //       row={row}
    //       updateValue={(property, value) =>
    //         this.props.updateAppointment({
    //           timeslotId: row.row.id,
    //           appointmentId: row.row.appointment.id,
    //           property,
    //           value
    //         })
    //       }
    //     />
    //   )
    // },
    // ------------------------------------------
    // The column (examCode) has been temporarily removed, it is possible that it will reappear in the future
    // ------------------------------------------
    // {
    //   key: "examCode",
    //   name: "Exam Code",
    //   width: 88,
    //   formatter: (row: ISpreadsheetRow) => (
    //     <ExamCodeFormatter
    //       row={row}
    //       options={this.props.spreadsheet.options.examCodes}
    //       updateValue={(property, value) =>
    //         this.props.updateAppointment({
    //           timeslotId: row.row.id,
    //           appointmentId: row.row.appointment.id,
    //           property,
    //           value,
    //         })
    //       }
    //     />
    //   ),
    // },
    {
      key: "notes",
      name: "Appointment Notes",
      width: 500,
      formatter: (row: ISpreadsheetRow) => (
        <NotesFormatter
          row={row}
          updateValue={(property, value) =>
            this.props.updateAppointment({
              timeslotId: row.row.id,
              appointmentId: row.row.appointment.id,
              property,
              value,
            })
          }
        />
      ),
    },
  ].map((c) => ({
    ...c,
    ...this.defaultColumnProperties,
    editable: getColumnSettings(c.key).editable,
  }));

  private onCheckCellIsEditable(data: any) {
    return data.row.appointment;
  }

  public onGridRowsUpdated = (input: any) => {
    const { updateAppointment, updatePatient } = this.props;
    const { cellKey, fromRowData, updated } = input;

    if (cellKey === "ehrId") {
      updatePatient({
        patientId: fromRowData.appointment.patientId,
        property: cellKey,
        value: updated[cellKey],
      });
    } else if (["materialsResponsibility", "examResponsibility"].includes(cellKey)) {
      updateAppointment({
        timeslotId: fromRowData.id,
        appointmentId: fromRowData.appointment.id,
        property: cellKey,
        value: Number(updated[cellKey]) >= 0 ? Number(updated[cellKey]) : undefined,
      });
    } else {
      updateAppointment({
        timeslotId: fromRowData.id,
        appointmentId: fromRowData.appointment.id,
        property: cellKey,
        value: !!(updated[cellKey] * 1) || updated[cellKey] * 1 === 0 ? updated[cellKey] * 1 : updated[cellKey],
      });
    }
  };

  public addPatientToWaitlistAction(row: any) {
    const { addPatientToWaitlist, spreadsheet } = this.props;
    const modal = Modal.confirm();
    modal.update({
      width: 500,
      maskClosable: true,
      content: "Would you like to add patient to waitlist for this location?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        addPatientToWaitlist({
          locationId: spreadsheet.tracker.locationId,
          email: row.row.appointment.patient.email,
          firstName: row.row.appointment.patient.firstName,
          lastName: row.row.appointment.patient.lastName,
          phoneNumber: row.row.appointment.patient.phoneNumber,
          timezone: row.row.timezone,
          timePreference: {
            morning: true,
            afternoon: true,
          },
        });
        Modal.destroyAll();
      },
    });
  }

  public getProperAction(row: ISpreadsheetRow) {
    const { history, topbar } = this.props;
    const { id, trackerId, companyId, isBooked, isDisabled, appointment } = row;

    if (appointment && appointment.id) {
      return {
        icon: !isDisabled ? (
          <TimeIcon data-cy="reschedule-appointment-icon" type="clock-circle" />
        ) : (
          <DisabledSlotIcon type="close" />
        ),
        callback: () => !isDisabled && this.props.toggleTimepickerModal(row),
      };
    }

    if (!isBooked && !isDisabled && !appointment) {
      return {
        icon: !isDisabled ? (
          <AddIcon data-cy="add-new-appointment-icon" type="user-add" />
        ) : (
          <DisabledSlotIcon type="close" />
        ),
        callback: () => {
          if (!isDisabled) {
            //@ts-ignore
            window
              .open(
                `${Config.get(
                  "ADMIN_PANEL_URL"
                )}/appointments/create?companyId=${companyId}&visitId=${trackerId}&timeslotId=${id}&closeTab=true`,
                "_blank"
              )
              .focus();
          }
        },
      };
    }

    if (!isBooked) {
      return {
        icon: !isDisabled ? (
          <Popconfirm
            placement="right"
            title="Are you sure you want to make this time available to patients?"
            onConfirm={
              () => {}
              // API.transformEmptyTimeslotToAvailable(topbar.selectedTrackerId, startTime)
            }
            okText="Yes"
            cancelText="No">
            <AddSlotIcon type="plus" />
          </Popconfirm>
        ) : (
          <DisabledSlotIcon type="close" />
        ),
        callback: () => {},
      };
    }

    return { callback: () => {} };
  }

  public getCellActions = (column: any, row: any) => {
    const cellActions: any = {
      time: [this.getProperAction(row)],
    };

    return cellActions[column.key];
  };

  public exportToCSV = async () => {
    const { spreadsheet } = this.props;
    const { tracker } = spreadsheet;
    const csv = await API.generateTrackerCSV(tracker!.id);

    const filename = `${tracker!.name}.csv`;
    const mimetype = "text/csv;charset=utf-8";
    const blob = new Blob([csv], {
      type: mimetype || "application/octet-stream",
    });

    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
      return;
    }

    const lnk = document.createElement("a");
    const url = window.URL;
    let objectURL;

    if (mimetype) {
      lnk.type = mimetype;
    }

    lnk.download = filename || "untitled";
    lnk.href = objectURL = url.createObjectURL(blob);
    lnk.dispatchEvent(new MouseEvent("click"));
    setTimeout(url.revokeObjectURL.bind(url, objectURL));
  };

  public filterAppointments = (slots: any) => {
    const { appointmentsStatusFilter } = this.state;

    switch (appointmentsStatusFilter) {
      case "active":
        return slots.filter((row: ISpreadsheetRow) => {
          if (row.appointment && row.appointment.id) {
            return validAppointmentStatuses.includes(row.appointment.status);
          }
          return !row.appointment;
        });
      case "cancelled":
        return slots.filter((row: ISpreadsheetRow) => {
          if (row.appointment && row.appointment.id) {
            return cancelledAppointmentStatuses.includes(row.appointment.status);
          }
          return false;
        });
      default:
        return slots;
    }
  };

  public returnAllSlots = (): ISpreadsheetRow[] => {
    const { spreadsheet } = this.props;
    const slots: ISpreadsheetRow[] = [];

    if (spreadsheet.tracker) {
      spreadsheet.tracker.timeslots.forEach((timeslot) => {
        const slot = {
          id: timeslot.id,
          trackerId: spreadsheet.tracker!.id,
          companyId: spreadsheet.tracker!.company.id,
          timezone: spreadsheet.tracker!.timezone,
          startDate: timeslot.startDate,
          endDate: timeslot.endDate,
          isDisabled: timeslot.isDisabled,
          isBooked: timeslot.isBooked,
          appointment: null,
        };
        if (!timeslot.appointments.length) {
          slots.push(slot);
        } else {
          timeslot.appointments.forEach((appointment) => {
            if (
              validAppointmentStatuses.includes(appointment.status) ||
              cancelledAppointmentStatuses.includes(appointment.status)
            ) {
              slots.push({
                ...slot,
                appointment,
                ehrId: appointment.patient.ehrId,
                notes: appointment.notes,
                materialsResponsibility: appointment.materialsResponsibility,
                examResponsibility: appointment.examResponsibility,
              });
            }
          });

          const validAppointmentsLength = timeslot.appointments.filter((appointment) =>
            validAppointmentStatuses.includes(appointment.status)
          ).length;
          if (validAppointmentsLength === 0) {
            slots.push(slot);
          }
        }
      });
    }
    slots.forEach((slot) => {
      if (slot.appointment) {
        const status = spreadsheet.statusOfQuestionnaires.find(
          (status) => slot.appointment!.patientId === status.patientId
        );
        if (status) {
          slot.isQuestionnaireCompleted = status.isQuestionnaireCompleted;
        }
      }
    });
    return orderBy(slots, "startDate", "asc");
  };

  public render() {
    const { topbar, spreadsheet } = this.props;
    const { appointmentsStatusFilter, selectedRow, isGlassesModalVisible, isContactsModalVisible } = this.state;
    const slots = this.filterAppointments(this.returnAllSlots());

    return (
      <>
        <OptionsTab>
          <Select
            data-cy="tracker-select-appointments-status"
            value={appointmentsStatusFilter}
            style={{ width: 200 }}
            onChange={this.updateAppointmentsStatusFilter}>
            <Select.Option value="active">Active appointments</Select.Option>
            <Select.Option value="cancelled">Cancelled appointments</Select.Option>
            <Select.Option value="all">All appointments</Select.Option>
          </Select>
          <ButtonsContainer>
            <a
              href={`${Config.get("ADMIN_PANEL_URL")}/appointments/create_retail?companyId=${
                spreadsheet.tracker!.company!.id
              }&visitId=${topbar.selectedTrackerId}&closeTab=true`}
              target="_blank">
              <ButtonStyled data-cy="add-retail-only" bgColor="#2ecc71">
                Add retail only appointment
              </ButtonStyled>
            </a>
            <a
              href={`${Config.get("ADMIN_PANEL_URL")}/appointments/create?companyId=${
                spreadsheet.tracker!.company!.id
              }&visitId=${topbar.selectedTrackerId}&closeTab=true`}
              target="_blank">
              <ButtonStyled data-cy="add-new-appointment-button" bgColor="#2ecc71">
                Add new appointment
              </ButtonStyled>
            </a>
            <ButtonStyled bgColor="#34495e" onClick={this.exportToCSV}>
              Export Tracker to CSV
            </ButtonStyled>
          </ButtonsContainer>
        </OptionsTab>
        <div className="react-grid-multiline-header">
          <ReactDataGrid
            columns={this.adjustColumnsToAppointmentType(this.columns, spreadsheet.tracker?.appointmentType)}
            headerRowHeight={60}
            rowHeight={40}
            onGridRowsUpdated={this.onGridRowsUpdated}
            onCheckCellIsEditable={this.onCheckCellIsEditable}
            getCellActions={this.getCellActions}
            rowRenderer={RowRenderer}
            rowGetter={(i) => slots[i]}
            rowsCount={slots.length}
            enableCellSelect={true}
            minHeight={540}
          />
        </div>
        {isGlassesModalVisible && (
          <GlassesModal
            isGlassesModalVisible={isGlassesModalVisible}
            selectedRow={selectedRow}
            spreadsheet={spreadsheet}
            toggleGlassesModal={this.toggleGlassesModal}
          />
        )}
        {isContactsModalVisible && (
          <ContactsModal
            isContactsModalVisible={isContactsModalVisible}
            selectedRow={selectedRow}
            spreadsheet={spreadsheet}
            toggleContactsModal={this.toggleContactsModal}
          />
        )}
      </>
    );
  }
}

export default TrackerSpreadsheet;

const TimeIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
`;

const AddIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #2dcc71;
  cursor: pointer;
`;

const GlassesCellFormater = styled.div`
  text-align: center;
  cursor: pointer;
`;

const ContactLensesCellFormater = styled.div`
  text-align: center;
  cursor: pointer;
`;

const AddSlotIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #27ae60;
  cursor: pointer;
`;

const DisabledSlotIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #c0392b;
`;

const OptionsTab = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;

  button {
    margin-right: 10px;
  }
`;

const ButtonStyled = styled.button`
  background-color: ${(props: { bgColor: string }) => (props.bgColor ? props.bgColor : "transparent")};
  color: #ffffff;
  border-radius: 5px;
  padding: 7px 15px;
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

const LineThroughText = styled.span`
  text-decoration: line-through;
`;
