import React from "react";
import { Row, Col, Typography } from "antd";
import styled from "styled-components";

import { formatStringField, formatLogicField } from "../SalesforceAccount.helpers";
import { ISalesforceAccountInfo } from "../SalesforceAccount.interfaces";

interface IProps {
  salesforceAccount: ISalesforceAccountInfo;
}

const InsuranceInfo = (props: IProps) => {
  const { salesforceAccount } = props;
  const table = [
    {
      label: "SOB verified:",
      value: formatStringField(salesforceAccount.dateclientLastVerifiedSOB),
    },
    {
      label: "Insurance notes:",
      value: formatStringField(salesforceAccount.insuranceNotes),
    },
    {
      label: "Multiple insurance providers:",
      value: formatStringField(salesforceAccount.multipleInsuranceProviders),
    },
    {
      label: "Company pays for:",
      value: formatStringField(salesforceAccount.companyPaysFor),
    },
  ];

  const table2 = [
    {
      label: "Medical insurance plan name:",
      value: formatStringField(salesforceAccount.medicalInsurancePlanName),
    },
    {
      label: "Eye exam covered by medical:",
      value: formatLogicField(salesforceAccount.eyeExamCoveredMedical),
    },
    {
      label: "Exam frequency:",
      value: formatStringField(salesforceAccount.examFrequencyMedical),
    },
    {
      label: "Exam copay:",
      value: formatStringField(salesforceAccount.copayMedical),
    },
  ];

  const table3 = [
    {
      label: "Vision insurance plan name:",
      value: formatStringField(salesforceAccount.visionInsurancePlanName),
    },
    {
      label: "Eye exam covered by vision:",
      value: formatLogicField(salesforceAccount.eyeExamCoveredVision),
    },
    {
      label: "Exam frequency:",
      value: formatStringField(salesforceAccount.examFrequencyVision),
    },
    {
      label: "Exam copay:",
      value: formatStringField(salesforceAccount.copayVision),
    },
  ];

  return (
    <Row gutter={16}>
      <Col className="gutter-row" span={8}>
        <Typography.Title level={4}>General</Typography.Title>
        <TableStyled style={{ marginBottom: "42px" }}>
          <tbody>
            {table.map((row) => (
              <tr>
                <td className="label">{row.label}</td>
                <td>{row.value}</td>
              </tr>
            ))}
          </tbody>
        </TableStyled>
      </Col>
      <Col className="gutter-row" span={8}>
        <Typography.Title level={4}>Medical insurance</Typography.Title>
        <TableStyled style={{ marginBottom: "42px" }}>
          <tbody>
            {table2.map((row) => (
              <tr>
                <td className="label">{row.label}</td>
                <td>{row.value}</td>
              </tr>
            ))}
          </tbody>
        </TableStyled>
      </Col>
      <Col className="gutter-row" span={8}>
        <Typography.Title level={4}>Vision insurance</Typography.Title>
        <TableStyled style={{ marginBottom: "42px" }}>
          <tbody>
            {table3.map((row) => (
              <tr>
                <td className="label">{row.label}</td>
                <td>{row.value}</td>
              </tr>
            ))}
          </tbody>
        </TableStyled>
      </Col>
    </Row>
  );
};

export default InsuranceInfo;

export const TableStyled = styled.table`
  width: 100%;
  tr {
    border-bottom: 1px solid #dbdbdb;
  }
  td {
    padding: 10px;
  }
  .label {
    font-weight: bold;
    background-color: #eeeeee;
    width: 50%;
  }
`;
