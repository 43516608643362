import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";

import { ITrackerStatistics } from "./../../../Features/Statistics/Statistics.interfaces";
import TableRow from "./../../../Features/Statistics/components/TableRow.component";

interface IProps {
  statistics: ITrackerStatistics;
}

const StatisticsTables = (props: IProps) => {
  const { statistics } = props;
  const table = [
    { label: "Exams Completed:", value: statistics.examsCompleted },
    { label: "No Shows:", value: statistics.noShowAppointments },
    { label: "Late Cancels:", value: statistics.lateCancelAppointments },
    { label: "Refused Exams:", value: statistics.refusedExamAppointments },
    { label: "Sign Ups:", value: statistics.signUps },
    { label: "Retail Only:", value: statistics.retailOnly },
  ];
  const table2 = [
    { label: "Glasses Ordered:", value: statistics.soldGlasses },
    { label: "Remake Glasses:", value: statistics.remakeGlasses },
    { label: "Contacts Sold:", value: statistics.soldContacts },
  ];

  const table3 = [
    {
      label: "Attachment Rate:",
      value: (statistics.attachmentRate * 100).toFixed() + "%",
    },
    { label: "Attachment Rate Goal:", value: statistics.attachmentRateGoal },
  ];

  const table4 = [
    {
      label: "Exam Total:",
      value: `$${statistics.totalExamResponsibility.toFixed(2)}`,
    },
    {
      label: "Material Total:",
      value: `$${statistics.totalMaterialsResponsibility.toFixed(2)}`,
    },
    {
      label: "Grand Total:",
      value: `$${(statistics.totalMaterialsResponsibility + statistics.totalExamResponsibility).toFixed(2)}`,
    },
  ];
  return (
    <div>
      <Row gutter={16}>
        <Col className="gutter-row" span={12}>
          <TableStyled style={{ marginBottom: "42px" }}>
            <tbody>
              {table.map((row) => (
                <TableRow key={row.label} label={row.label} value={row.value} />
              ))}
            </tbody>
          </TableStyled>
          <TableStyled>
            <tbody>
              {table3.map((row) => (
                <TableRow key={row.label} label={row.label} value={row.value} />
              ))}
            </tbody>
          </TableStyled>
        </Col>
        <Col className="gutter-row" span={12}>
          <TableStyled style={{ marginBottom: "42px" }}>
            <tbody>
              {table2.map((row) => (
                <TableRow key={row.label} label={row.label} value={row.value} />
              ))}
            </tbody>
          </TableStyled>
          <TableStyled>
            <tbody>
              {table4.map((row) => (
                <TableRow key={row.label} label={row.label} value={row.value} />
              ))}
            </tbody>
          </TableStyled>
        </Col>
      </Row>
    </div>
  );
};

export default StatisticsTables;

export const TableStyled = styled.table`
  width: 100%;
  tr {
    border-bottom: 1px solid #dbdbdb;
  }
  td {
    padding: 10px;
  }
  .label {
    font-weight: bold;
    background-color: #eeeeee;
    width: 50%;
  }
`;
