import { call, put, takeEvery, all, select, takeLatest } from "redux-saga/effects";
import moment from "moment-timezone";

import { saveTrackers, selectTracker, updateDatepicker } from "./../../Features/Topbar/Topbar.actions";
import API from "./../../Infrastructure/API";
import Logger from "./../../Infrastructure/Logger";
import { IStore } from "../../Infrastructure/Store/store.interface";
import { IAvailableTracker } from "../Topbar/Topbar.interfaces";
import { GET_TRACKER_DATE, GET_TRACKER_FROM_PARAM, GET_TRACKERS, SELECT_INITIAL_TRACKER } from "./Topbar.constants";

function* getTrackersAsync(action: { type: string; payload: { startDate: Date; endDate: Date } }): Generator {
  try {
    const { startDate, endDate } = action.payload;

    const trackers: any = yield call(API.getTrackers, {
      startDate,
      endDate,
    });

    yield put(saveTrackers(trackers as IAvailableTracker[]));
  } catch (error) {
    Logger.error(error);
  }
}

function* selectInitialTrackerAsync(): Generator {
  // @ts-ignore
  const state: IStore = yield select();
  const date = state.topbar.selectedDate;

  const trackers: any = yield call(API.getTrackers, {
    startDate: moment(date)
      .utc(true)
      .startOf("day")
      .toDate(),
    endDate: moment(date)
      .utc(true)
      .startOf("day")
      .toDate(),
  });

  yield put(saveTrackers(trackers as IAvailableTracker[]));

  if (trackers.length) {
    yield put(selectTracker(trackers[0].id));
  }
}

function* getTrackerFromParamAsync(action: { type: string; payload: { trackerId: string; date: Date } }): Generator {
  try {
    const { trackerId, date } = action.payload;
    const startDate = moment(date)
      .utc(true)
      .startOf("day")
      .toDate();
    const endDate = moment(date)
      .utc(true)
      .startOf("day")
      .toDate();

    yield put(updateDatepicker(date));

    const trackers: any = yield call(API.getTrackers, {
      startDate,
      endDate,
    });
    yield put(saveTrackers(trackers));

    yield put(selectTracker(trackerId));
  } catch (error) {
    Logger.error(error);
  }
}

function* getTrackerDateAsync(action: { type: string; payload: { trackerId: string } }): Generator {
  try {
    const { trackerId } = action.payload;
    const tracker: any = yield call(API.getSingleTracker, trackerId);
    const date = moment
      .tz(tracker.date, "America/New_York")
      .hours(6)
      .toDate();

    yield put(updateDatepicker(date));
  } catch (error) {
    Logger.error(error);
  }
}

export default function* root(): Generator {
  yield all([
    takeEvery(GET_TRACKERS, getTrackersAsync),
    takeEvery(SELECT_INITIAL_TRACKER, selectInitialTrackerAsync),
    takeEvery(GET_TRACKER_DATE, getTrackerDateAsync),
    takeLatest(GET_TRACKER_FROM_PARAM, getTrackerFromParamAsync),
  ]);
}
