import { call, put, takeEvery, all } from "redux-saga/effects";
import { message } from "antd";

import {
  GET_VISIT_NOTES,
  saveVisitNotes,
  UPDATE_VISIT_NOTES,
  GET_TRACKING_NUMBER,
  saveTrackingNumber,
  UPDATE_TRACKING_NUMBER,
  saveSiteNotes,
  GET_SITE_NOTES,
  UPDATE_SITE_NOTES,
  GET_ISSUE_NOTES,
  saveIssueNotes,
  UPDATE_ISSUE_NOTES,
  SYNCHRONIZE_TRACKER,
} from "./../../Features/Notes/Notes.actions";
import API from "./../../Infrastructure/API";
import Logger from "./../../Infrastructure/Logger";

function* getVisitNotesAsync(action: { type: string; payload: number }): Generator {
  try {
    const trackerId = action.payload;

    const notes: any = yield call(API.getTrackerVisitNotes, trackerId);

    yield put(saveVisitNotes(notes.visitNotes));
  } catch (error) {
    Logger.error(error);
  }
}

function* updateVisitNotesAsync(action: { type: string; payload: { trackerId: number; notes: string } }): Generator {
  try {
    const { trackerId, notes } = action.payload;

    yield call(API.updateTrackerVisitNotes, trackerId, notes);
    message.success("Visit notes updated");
  } catch (error) {
    message.error("Visit notes can not be updated");
    Logger.error(error);
  }
}

function* getSiteNotesAsync(action: { type: string; payload: number }): Generator {
  try {
    const trackerId = action.payload;

    const notes: any = yield call(API.getSiteNotes, trackerId);

    yield put(saveSiteNotes(notes.siteNotes));
  } catch (error) {
    Logger.error(error);
  }
}

function* updateSiteNotesAsync(action: { type: string; payload: { trackerId: number; notes: string } }): Generator {
  try {
    const { trackerId, notes } = action.payload;

    yield call(API.updateSiteNotes, trackerId, notes);
    message.success("Site notes updated");
  } catch (error) {
    message.error("Site notes can not be updated");
    Logger.error(error);
  }
}

function* getTrackingNumberAsync(action: { type: string; payload: number }): Generator {
  try {
    const trackerId = action.payload;

    const { trackingNumber }: any = yield call(API.getTrackingNumber, trackerId);

    yield put(saveTrackingNumber(trackingNumber));
  } catch (error) {
    Logger.error(error);
  }
}

function* updateTrackingNumberAsync(action: {
  type: string;
  payload: { trackerId: number; trackingNumber: string };
}): Generator {
  try {
    const { trackerId, trackingNumber } = action.payload;

    yield call(API.updateTrackingNumber, trackerId, trackingNumber);
    message.success("Tracking number updated");
  } catch (error) {
    message.error("Tracking number can not be updated");
    Logger.error(error);
  }
}

function* getIssueNotesAsync(action: { type: string; payload: number }): Generator {
  try {
    const trackerId = action.payload;

    const notes: any = yield call(API.getTrackerIssueNotes, trackerId);

    yield put(saveIssueNotes(notes.issueNotes));
  } catch (error) {
    Logger.error(error);
  }
}

function* updateIssueNotesAsync(action: { type: string; payload: { trackerId: number; notes: string } }): Generator {
  try {
    const { trackerId, notes } = action.payload;

    yield call(API.updateTrackerIssueNotes, trackerId, notes);
    message.success("Issue notes updated");
  } catch (error) {
    message.error("Issue notes can not be updated");
    Logger.error(error);
  }
}

function* synchronizeTracker(action: { type: string; payload: { trackerId: string } }): Generator {
  try {
    const { trackerId } = action.payload;

    yield call(API.synchronizeTracker, trackerId);
    message.success("Synchronized tracker with saleforce");
  } catch (error) {
    message.error("Tracker could not be synchronized with salesforce");
    Logger.error(error);
  }
}

export default function* root(): Generator {
  yield all([
    takeEvery(GET_VISIT_NOTES, getVisitNotesAsync),
    takeEvery(UPDATE_VISIT_NOTES, updateVisitNotesAsync),
    takeEvery(GET_TRACKING_NUMBER, getTrackingNumberAsync),
    takeEvery(UPDATE_TRACKING_NUMBER, updateTrackingNumberAsync),
    takeEvery(GET_SITE_NOTES, getSiteNotesAsync),
    takeEvery(UPDATE_SITE_NOTES, updateSiteNotesAsync),
    takeEvery(GET_ISSUE_NOTES, getIssueNotesAsync),
    takeEvery(UPDATE_ISSUE_NOTES, updateIssueNotesAsync),
    takeEvery(SYNCHRONIZE_TRACKER, synchronizeTracker),
  ]);
}
