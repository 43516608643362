import React from "react";
import styled from "styled-components";

interface IProps {
  content: string;
}

const Header = (props: IProps) => {
  return <Container>{props.content}</Container>;
};

export default Header;

export const Container = styled.div`
  background-color: #21124d;
  color: #ffffff;
  text-align: center;
`;
