import { call, put, takeEvery, all } from "redux-saga/effects";

import { GET_TRACKER_STATISTICS } from "./../../Features/Statistics/Statistics.constants";
import { saveTrackerStatistics } from "./../../Features/Statistics/Statistics.actions";
import { ITrackerStatistics } from "./../../Features/Statistics/Statistics.interfaces";
import API from "./../../Infrastructure/API";
import Logger from "./../../Infrastructure/Logger";

function* getTrackerStatistics(action: { type: string; payload: string }): Generator {
  try {
    const trackerId = action.payload;
    const statistics = yield call(API.getTrackerStatistics, trackerId);
    yield put(saveTrackerStatistics(statistics as ITrackerStatistics));
  } catch (error) {
    Logger.error(error);
  }
}

export default function* root(): Generator {
  yield all([takeEvery(GET_TRACKER_STATISTICS, getTrackerStatistics)]);
}
