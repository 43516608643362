import React, { Component } from "react";
import { Typography, Divider, Form, Input, Button, Row, Col, notification, DatePicker } from "antd";
import styled from "styled-components";

import { IAction, ISearchQuery } from "./../../../Features/PatientLookup/PatientLookup.interfaces";

interface IProps {
  form: any;
  isLoading: boolean;
  searchPatients: (payload: ISearchQuery) => IAction;
}

class Finder extends Component<IProps> {
  public handleSubmit = (e) => {
    e.preventDefault();
    const { form, searchPatients } = this.props;
    const fields = form.getFieldsValue(["firstName", "lastName", "email", "phoneNumber", "birth"]);

    if (Object.values(fields).some((value) => value)) {
      searchPatients({
        ...(fields.firstName ?? { firstName: fields.firstName }),
        ...(fields.lastName ?? { lastName: fields.lastName }),
        ...(fields.email ?? { email: fields.email }),
        ...(fields.phoneNumber ?? { phoneNumber: fields.phoneNumber }),
        ...(fields.birth ?? { birth: fields.birth }),
        limit: 25,
        offset: 0,
      });
    } else {
      notification.warning({
        message: "Please fill out the search form",
      });
    }
  };

  public render() {
    const { form, isLoading } = this.props;
    const { getFieldDecorator } = form;

    return (
      <Row>
        <Col span={24}>
          <Typography.Title level={2} style={{ marginBottom: "5px" }}>
            Patient Lookup
          </Typography.Title>
          <Divider style={{ marginTop: 0 }} />
          <Form onSubmit={this.handleSubmit}>
            <Row gutter={16}>
              <Col span={5}>
                <Form.Item>
                  {getFieldDecorator("firstName")(
                    <>
                      <Label>First name:</Label>
                      <Input
                        placeholder="e.g. John"
                        onChange={(e) => {
                          form.setFieldsValue({ firstName: e.target.value });
                        }}
                      />
                    </>
                  )}
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item>
                  {getFieldDecorator("lastName")(
                    <>
                      <Label>Last name:</Label>
                      <Input
                        placeholder="e.g. Doe"
                        onChange={(e) => {
                          form.setFieldsValue({ lastName: e.target.value });
                        }}
                      />
                    </>
                  )}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item>
                  {getFieldDecorator("birth")(
                    <>
                      <Label>Date of birth:</Label>
                      <DatePicker
                        style={{ width: "100%" }}
                        placeholder="e.g. 11/29/1987"
                        format="MM/DD/YYYY"
                        showToday={false}
                        onChange={(e) => {
                          form.setFieldsValue({
                            birth: e.format("MM/DD/YYYY"),
                          });
                        }}
                      />
                    </>
                  )}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item>
                  {getFieldDecorator("email")(
                    <>
                      <Label>Email:</Label>
                      <Input
                        type="email"
                        placeholder="e.g. john.doe@example.com"
                        onChange={(e) => {
                          form.setFieldsValue({ email: e.target.value });
                        }}
                      />
                    </>
                  )}
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item>
                  {getFieldDecorator("phoneNumber")(
                    <>
                      <Label>Phone number:</Label>
                      <Input
                        placeholder="e.g. 1234567890"
                        onChange={(e) => {
                          form.setFieldsValue({ phoneNumber: e.target.value });
                        }}
                      />
                    </>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <ButtonContainer>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Search
              </Button>
            </ButtonContainer>
          </Form>
        </Col>
      </Row>
    );
  }
}

export default Finder;

const Label = styled.div`
  font-weight: bold;
  margin: -10px 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
