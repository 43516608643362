import { IState, IActions, ISalesforceAccountInfo } from "./SalesforceAccount.interfaces";
import {
  SAVE_SALESFORCE_ACCOUNT_INFO,
  SAVE_SALESFORCE_ACCOUNT_INFO_ERROR,
  SALESFORCE_ACCOUNT_TOGGLE_LOADING_STATUS,
} from "./SalesforceAccount.actions";

const initialState: IState = {
  error: null,
  isLoading: false,
  dateclientLastVerifiedSOB: "",
  insuranceNotes: "",
  multipleInsuranceProviders: "",
  eyeExamCoveredMedical: false,
  medicalInsurancePlanName: "",
  examFrequencyMedical: "",
  copayMedical: "",
  eyeExamCoveredVision: false,
  visionInsurancePlanName: "",
  examFrequencyVision: "",
  copayVision: "",
  companyPaysFor: "",
  cafe: "",
  restroom: "",
  MVCDriveTime: "",
  parkingLocationDetails: "",
  visitForEmployeesOnly: "",
  primarySiteContactName: "",
  primarySiteContactEmail: "",
  primarySiteContactPhoneNumber: "",
  dayOfSiteContact: "",
  dayOfSiteContactEmail: "",
  dayOfSiteContactPhone: "",
};

const salesforceAccountReducer = (state = initialState, action: IActions): IState => {
  switch (action.type) {
    case SAVE_SALESFORCE_ACCOUNT_INFO: {
      const payload = action.payload as ISalesforceAccountInfo;
      return { ...state, ...payload };
    }
    case SAVE_SALESFORCE_ACCOUNT_INFO_ERROR: {
      const payload = action.payload as string | null;
      return { ...state, error: payload };
    }
    case SALESFORCE_ACCOUNT_TOGGLE_LOADING_STATUS: {
      const payload = action.payload as boolean;
      return { ...state, isLoading: payload };
    }
    default:
      return state;
  }
};

export default salesforceAccountReducer;
