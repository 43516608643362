import React from "react";
import { Button } from "antd";

import { IAvailableTracker } from "./../../../Features/Topbar/Topbar.interfaces";

interface IProps {
  selectedTrackerId: string;
  availableTrackers: IAvailableTracker[];
  selectTracker: (id: string) => void;
}

const Trackers = (props: IProps) => {
  const { selectedTrackerId, availableTrackers, selectTracker } = props;
  return (
    <div>
      {availableTrackers?.length > 0 && (
        <Button.Group>
          {availableTrackers.map((r: IAvailableTracker) => {
            const style: any = {};

            if (r.status.includes("inactive")) {
              style["color"] = "#f9ca24";
            }

            if (r.status.includes("cancelled")) {
              style["color"] = "#eb4d4b";
            }

            return (
              <Button
                key={r.id}
                type={selectedTrackerId === r.id ? "primary" : "default"}
                onClick={() => selectTracker(r.id)}
                style={style}>
                {r.name}
              </Button>
            );
          })}
        </Button.Group>
      )}
    </div>
  );
};

export default Trackers;
