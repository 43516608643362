import React from "react";
import { Modal, Table, Select, Button, Input } from "antd";
import styled from "styled-components";

import { IProduct, ISpreadsheetRow } from "./../../../../Features/Tracker/Tracker.interfaces";
import { IState as ISpreadsheetState } from "../../Tracker.reducer";
import API from "../../../../Infrastructure/API/index";

interface IProps {
  isContactsModalVisible: boolean;
  selectedRow: ISpreadsheetRow;
  spreadsheet: ISpreadsheetState;
  toggleContactsModal: () => void;
}

interface IState {
  isRequestLoading: boolean;
  updatedContacts: IProduct[];
  newContacts: IProduct[];
  existingContactsToRemove: string[];
  onHoverId: string;
}

class ContactsModal extends React.Component<IProps, IState> {
  public state: IState = {
    isRequestLoading: false,
    updatedContacts: [...this.props.selectedRow.row.appointment.products.filter((p) => p.type === "contact_lenses")],
    newContacts: [],
    existingContactsToRemove: [],
    onHoverId: "",
  };

  public updateOnHoverId = (value: string) => {
    this.setState({
      onHoverId: value,
    });
  };

  public addNewContacts = () => {
    const { newContacts } = this.state;
    this.setState({
      newContacts: [
        ...newContacts,
        {
          id: `${newContacts.length + 1}`,
          status: null,
          type: "contact_lenses",
          notes: null,
        },
      ],
    });
  };

  public removeContacts = (id: string) => {
    const { updatedContacts, newContacts, existingContactsToRemove } = this.state;
    const isNew = newContacts.find((contacts) => contacts.id === id);

    if (isNew) {
      this.setState({
        newContacts: newContacts.filter((contacts) => contacts.id !== id),
      });
    } else {
      this.setState({
        updatedContacts: updatedContacts.filter((contacts) => contacts.id !== id),
        existingContactsToRemove: [...existingContactsToRemove, id],
      });
    }
  };

  public updateStatus = (id: string, status: string) => {
    const { updatedContacts, newContacts } = this.state;
    const newPairOfContacts = newContacts.find((c) => c.id === id);

    if (newPairOfContacts) {
      newPairOfContacts.status = status;
      this.setState({
        newContacts: newContacts.map((contacts) => {
          if (c.id === id) {
            return newPairOfContacts;
          }
          return contacts;
        }),
      });
    } else {
      const existingPairOfContacts = {
        ...updatedContacts.find((c) => c.id === id)!,
      };
      existingPairOfContacts.status = status;
      this.setState({
        updatedContacts: updatedContacts.map((contacts) => {
          if (contacts.id === id) {
            return existingPairOfContacts;
          }
          return contacts;
        }),
      });
    }
  };

  public updateNotes = (id: string, notes: string) => {
    const { updatedContacts, newContacts } = this.state;
    const newPairOfContacts = newContacts.find((contacts) => contacts.id === id);

    if (newPairOfContacts) {
      newPairOfContacts.notes = notes;
      this.setState({
        newContacts: newContacts.map((contacts) => {
          if (contacts.id === id) {
            return newPairOfContacts;
          }
          return contacts;
        }),
      });
    } else {
      const existingPairOfContacts = {
        ...updatedContacts.find((c) => c.id === id)!,
      };
      existingPairOfContacts.notes = notes;
      this.setState({
        updatedContacts: updatedContacts.map((contacts) => {
          if (contacts.id === id) {
            return existingPairOfContacts;
          }
          return contacts;
        }),
      });
    }
  };

  public onSubmit = async () => {
    const { selectedRow, toggleContactsModal } = this.props;
    const { newContacts, existingContactsToRemove, updatedContacts } = this.state;

    this.setState({ isRequestLoading: true });

    if (newContacts.length > 0) {
      const requestBody = newContacts.map((nC) => ({
        type: "contact_lenses",
        status: nC.status,
        frameStatus: nC.frameStatus,
        notes: nC.notes,
      }));

      await API.addProductToAppointment(selectedRow.row.trackerId, selectedRow.row.appointment!.id, requestBody);
    }

    if (updatedContacts.length > 0) {
      const requestBody = updatedContacts.map((uC) => ({
        id: uC.id,
        type: "contact_lenses",
        status: uC.status,
        frameStatus: uC.frameStatus,
        notes: uC.notes,
      }));

      await API.updateAppointmentProduct(selectedRow.row.trackerId, selectedRow.row.appointment!.id, requestBody);
    }

    for (const eC of existingContactsToRemove) {
      await API.removeAppointmentProduct(selectedRow.row.trackerId, selectedRow.row.appointment!.id, eC);
    }
    this.setState({ isRequestLoading: false });
    toggleContactsModal();
  };

  public render() {
    const { spreadsheet, isContactsModalVisible, toggleContactsModal } = this.props;
    const { updatedContacts, newContacts, onHoverId, isRequestLoading } = this.state;
    const dataSource = [...updatedContacts, ...newContacts].map((contacts, index) => ({
      key: contacts.id,
      name: `#${index + 1}`,
      id: contacts.id,
      status: contacts.status,
      notes: contacts.notes,
    }));

    const columns = [
      {
        title: "Item",
        dataIndex: "name",
        key: "name",
        align: "center",
      },
      {
        title: "Order status",
        dataIndex: "status",
        key: "status",
        render: (value: string, contacts: IProduct) => {
          return (
            <Select
              defaultValue={value}
              style={{ width: 120 }}
              onChange={(value: string) => this.updateStatus(contacts.id, value)}>
              <Select.Option value={null} disabled>
                -
              </Select.Option>
              {spreadsheet.options.products.contacts.orderStatus.map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          );
        },
      },
      {
        title: "Notes",
        dataIndex: "notes",
        key: "notes",
        render: (value: string, contacts: IProduct) => {
          return (
            <Input.TextArea
              placeholder="Example note..."
              defaultValue={value}
              onBlur={(e) => this.updateNotes(contacts.id, e.target.value)}
              rows={1}
            />
          );
        },
      },
      {
        title: "",
        dataIndex: "id",
        key: "id",
        render: (id: string, contacts: IProduct) => {
          return (
            <RemoveContactsContainer>
              {onHoverId === id && (
                <Button
                  id="remove-button"
                  type="danger"
                  shape="circle"
                  icon="delete"
                  onClick={() => this.removeContacts(contacts.id)}
                />
              )}
            </RemoveContactsContainer>
          );
        },
      },
    ];

    return (
      <div>
        <Modal
          title="How many pairs would the customer like to order?"
          width={850}
          visible={isContactsModalVisible}
          footer={[
            <Button key="back" onClick={toggleContactsModal}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" loading={isRequestLoading} onClick={this.onSubmit}>
              Save
            </Button>,
          ]}>
          <div>
            {dataSource.length > 0 && (
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                onRow={(record) => {
                  return {
                    onMouseEnter: () => this.updateOnHoverId(record.id),
                    onMouseLeave: () => this.updateOnHoverId(""),
                  };
                }}
              />
            )}
          </div>
          <AntdButton icon="plus" contactsLength={dataSource.length} onClick={this.addNewContacts}>
            Add new item
          </AntdButton>
        </Modal>
      </div>
    );
  }
}

export default ContactsModal;

const AntdButton = styled(Button)`
  margin-top: ${({ contactsLength }: { contactsLength: number }) => (contactsLength > 0 ? "20px" : 0)};
`;

const RemoveContactsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 35px;
`;
