import React from "react";
import styled from "styled-components";
import { Row, Col, Card, Button } from "antd";
import { IEmployee } from "./../../../Features/Staff/Staff.interfaces";
import TableRow from "./../../../Features/Staff/components/TableRow.component";
import { IState as ISpreadsheetState } from "../../Tracker/Tracker.reducer";

interface IProps {
  truckName: string;
  spreadsheet: ISpreadsheetState;
  staff: IEmployee[];
  addEmployees: (employees: { id: string; position: string }[]) => void;
  removeEmployee: (userId: string) => void;
}

interface ISelectedPerson {
  uuid: string;
  position: { i: number; name: string };
}

interface IState {
  selectedStaff: ISelectedPerson[];
  positions: { i: number; name: string }[];
}

export class Table extends React.Component<IProps, IState> {
  public state = {
    selectedStaff: [],
    positions: [
      { i: 0, name: "CS" },
      { i: 0, name: "OD" },
      { i: 0, name: "SM" },
      { i: 0, name: "ASM" },
      { i: 0, name: "EXTRA" },
      { i: 1, name: "EXTRA" },
      { i: 2, name: "EXTRA" },
      { i: 3, name: "EXTRA" },
    ],
  };

  public selectPerson = (person: { uuid: string; position: { i: number; name: string } }) => {
    const { selectedStaff, positions } = this.state;
    const position = positions.filter((p) => p.name === person.position.name);

    const currentPositionCount = selectedStaff.filter((p: ISelectedPerson) => p.position.name === person.position.name)
      .length;

    if (position.length > currentPositionCount) {
      this.setState({
        selectedStaff: [...selectedStaff, person],
      });
    } else if (position.length === currentPositionCount) {
      const existingPerson = selectedStaff.find(
        (p: ISelectedPerson) => p.position.name === person.position.name && p.position.i === person.position.i,
      );

      if (existingPerson) {
        this.setState({
          selectedStaff: [...selectedStaff.filter((p: ISelectedPerson) => p.uuid !== existingPerson.uuid), person],
        });
      }
    }
  };

  private saveStaff = () => {
    const { selectedStaff }: { selectedStaff: ISelectedPerson[] } = this.state;

    this.props.addEmployees(selectedStaff.map((p) => ({ id: p.uuid, position: p.position.name })));
    this.setState({ selectedStaff: [] });
  };

  private getTagColor = (name: string): string => ({
    "cs": "blue",
    "od": "cyan",
    "sm": "purple",
    "asm": "magenta",
    "extra": "geekblue",
  }[name] ?? "blue");

  public render() {
    const { positions } = this.state;
    const { spreadsheet, truckName, staff, removeEmployee } = this.props;

    return (
      <Row>
        <Col span={24}>
          <Card size="small" title={<div>Staff</div>} extra={<strong>{truckName}</strong>}>
            <TableStyled>
              <tbody>
              {positions.map((position, index) => (
                <TableRow
                  key={index}
                  position={position}
                  color={this.getTagColor(position.name.toLowerCase())}
                  staff={staff}
                  trackerEmployee={
                    spreadsheet.tracker?.employees.filter((e) => e.position === position.name)[position.i]
                  }
                  selectPerson={this.selectPerson}
                  removeEmployee={removeEmployee}
                />
              ))}
              </tbody>
            </TableStyled>
            <ButtonContainer>
              <Button type="primary" onClick={this.saveStaff} disabled={!this.state.selectedStaff.length}>
                Save
              </Button>
            </ButtonContainer>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Table;

const TableStyled = styled.table`
  width: 100%;

  tr {
    border-bottom: 1px solid #eeeeee;
  }

  td {
    padding: 7px;
  }

  .label {
    font-weight: bold;
    width: 50px;
    text-align: center;
  }
`;

const ButtonContainer = styled.div`
  margin: 10px 8px 0 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
