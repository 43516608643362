import React from "react";
import { Row, Col, Card } from "antd";
import styled from "styled-components";

import { formatStringField } from "../SalesforceAccount.helpers";
import { ISalesforceAccountInfo } from "../SalesforceAccount.interfaces";

interface IProps {
  salesforceAccount: ISalesforceAccountInfo;
  SiteNotesComponent: any;
}

const SiteInfo = (props: IProps) => {
  const { salesforceAccount, SiteNotesComponent } = props;
  const table = [
    {
      label: "Cafe:",
      value: formatStringField(salesforceAccount.cafe),
    },
    {
      label: "Restroom:",
      value: formatStringField(salesforceAccount.restroom),
    },
  ];

  const table2 = [
    {
      label: "MVC drive time:",
      value: formatStringField(salesforceAccount.MVCDriveTime),
    },
    {
      label: "Parking location:",
      value: formatStringField(salesforceAccount.parkingLocationDetails),
    },
  ];

  return (
    <Row gutter={16}>
      <Col className="gutter-row" span={16}>
        <Card size="small" title="Site info">
          <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <TableStyled style={{ marginBottom: "42px" }}>
                <tbody>
                  {table.map((row) => (
                    <tr>
                      <td className="label">{row.label}</td>
                      <td>{row.value}</td>
                    </tr>
                  ))}
                </tbody>
              </TableStyled>
            </Col>
            <Col className="gutter-row" span={12}>
              <TableStyled style={{ marginBottom: "42px" }}>
                <tbody>
                  {table2.map((row) => (
                    <tr>
                      <td className="label">{row.label}</td>
                      <td>{row.value}</td>
                    </tr>
                  ))}
                </tbody>
              </TableStyled>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col className="gutter-row" span={8}>
        {/*{<SiteNotesComponent />}*/}
      </Col>
    </Row>
  );
};

export default SiteInfo;

export const TableStyled = styled.table`
  width: 100%;
  tr {
    border-bottom: 1px solid #dbdbdb;
  }
  td {
    padding: 10px;
  }
  .label {
    font-weight: bold;
    background-color: #eeeeee;
    width: 50%;
  }
`;
