import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { IStore } from "./../../Infrastructure/Store/store.interface";
import Table from "./../../Features/Staff/components/Table.component";
import { addEmployees, getEmployees, removeEmployee } from "./../../Features/Staff/Staff.actions";
import { IEmployee } from "./../../Features/Staff/Staff.interfaces";

import { IState as ISpreadsheetState } from "../Tracker/Tracker.reducer";

interface IProps {
  spreadsheet: ISpreadsheetState;
  staff: IEmployee[];
  getEmployees: () => void;
  addEmployees: (employees: { id: string; position: string }[]) => void;
  removeEmployee: (userId: string) => void;
}

export class Staff extends React.Component<IProps> {
  public componentDidMount(): void {
    const { getEmployees } = this.props;
    getEmployees();
  }

  public render() {
    const { spreadsheet, staff, removeEmployee, addEmployees } = this.props;

    return (
      <Container>
        <Table
          truckName={spreadsheet.tracker && spreadsheet.tracker.truck ? spreadsheet.tracker.truck : "-"}
          spreadsheet={spreadsheet}
          staff={staff}
          addEmployees={addEmployees}
          removeEmployee={removeEmployee}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    spreadsheet: state.spreadsheet,
    staff: state.staff,
  };
};

const mapDispatchToProps = {
  getEmployees,
  addEmployees,
  removeEmployee,
};

export default connect(mapStateToProps, mapDispatchToProps)(Staff);

const Container = styled.div``;
