import React from "react";
import moment from "moment-timezone";
import { IState as ISpreadsheetState } from "../../Tracker/Tracker.reducer";
import { Table } from "antd";

interface IProps {
  spreadsheet: ISpreadsheetState;
}

export class TableOrderedProducts extends React.Component<IProps> {
  public render() {
    const { spreadsheet } = this.props;
    const validStatuses = ["confirmed", "checked_in", "ready_for_exam", "exam", "checked_out"];

    const data = spreadsheet.tracker?.timeslots
      .map((timeslot) =>
        timeslot.appointments.map((obj) => ({
          ...obj,
          timezone: spreadsheet.tracker?.timezone,
          startDate: timeslot.startDate,
        }))
      )
      .reduce((a, b) => a.concat(b), [])
      .filter((timeslot) => validStatuses.includes(timeslot.status))
      .map((obj) =>
        obj.products.map((product) => ({
          ...product,
          startDate: obj.startDate,
          patient: obj.patient,
          timezone: obj.timezone,
        }))
      )
      .reduce((a, b) => a.concat(b), []);

    const frameStatusFilters = spreadsheet.options.products.glasses.frameStatus.map((status) => ({
      text: status,
      value: status,
    }));

    const orderStatusFilters = spreadsheet.options.products.glasses.orderStatus.map((status) => ({
      text: status,
      value: status,
    }));

    const columns = [
      {
        title: "Time",
        key: "time",
        width: 120,
        render: (row: any) => {
          return <span>{moment(new Date(row.startDate)).tz(row.timezone).format("LT")}</span>;
        },
        sorter: (a, b) => {
          const timeA = moment(new Date(a.startDate)).tz(a.timezone);
          const timeB = moment(new Date(b.startDate)).tz(b.timezone);
          return timeA.diff(timeB);
        },
      },
      {
        title: "Name",
        key: "name",
        render: (row: any) => {
          return `${row.patient.lastName}, ${row.patient.firstName}`;
        },
      },
      {
        title: "Order status",
        dataIndex: "status",
        key: "orderStatus",
        filters: orderStatusFilters,
        onFilter: (value, record) => record.status.includes(value),
      },
      {
        title: "Frame status",
        dataIndex: "frameStatus",
        key: "frameStatus",
        filters: frameStatusFilters,
        onFilter: (value, record) => record.frameStatus.includes(value),
      },
      {
        title: "Notes",
        dataIndex: "notes",
        key: "notes",
      },
    ];
    return <Table columns={columns} dataSource={data} pagination={false} />;
  }
}
