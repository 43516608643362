import { call, put, takeEvery, all } from "redux-saga/effects";
import {
  CHECK_LOGGED_IN,
  checkLoggedInFailure,
  saveUserDetails,
} from "./../../Features/Authentication/Authentication.actions";
import API from "./../../Infrastructure/API";
import { IUserDetails } from "./../../Features/Authentication/Authentication.interfaces";

function* doCheckLoggedIn(action: { type: string; payload: IUserDetails }): Generator {
  try {
    const userDetails: any = yield call(API.isUserAuthenticated);
    const userData = userDetails.data;
    if (!userData.data || (userData.data && !["admin", "user"].includes(userData.data.role))) {
      throw new Error("Insufficient permissions!");
    }
    yield put(saveUserDetails(userDetails.data));
  } catch (error) {
    yield put(checkLoggedInFailure());
  }
}

export default function* root(): Generator {
  yield all([takeEvery(CHECK_LOGGED_IN, doCheckLoggedIn)]);
}
