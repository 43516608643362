import React, { Component } from "react";
import { connect } from "react-redux";
import { Spin } from "antd";
import styled from "styled-components";

import { IState } from "../SalesforceAccount.interfaces";
import SalesforceResourcesError from "../components/SalesforceResourcesError.component";
import SiteInfoComponent from "../components/SiteInfo.component";
import SiteNotes from "../../Notes/containers/SiteNotes.container";

interface IProps {
  salesforceAccount: IState;
}

export class SiteInfo extends Component<IProps> {
  public render() {
    const { salesforceAccount } = this.props;

    if (salesforceAccount.isLoading) {
      return (
        <SpinWraper>
          <Spin />
        </SpinWraper>
      );
    }

    if (!salesforceAccount.isLoading && salesforceAccount.error) {
      return <SalesforceResourcesError message={salesforceAccount.error} />;
    }

    if (!salesforceAccount.isLoading && !salesforceAccount.error) {
      return <SiteInfoComponent salesforceAccount={salesforceAccount} SiteNotesComponent={SiteNotes} />;
    }

    return null;
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    salesforceAccount: state.salesforceAccount,
  };
};

export default connect(mapStateToProps)(SiteInfo);

const SpinWraper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
