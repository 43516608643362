import { IAction, IEmployee } from "./../../Features/Staff/Staff.interfaces";
import { SAVE_EMPLOYEES } from "./../../Features/Staff/Staff.actions";

export const staffReducer = (state: IEmployee[] = [], action: IAction): IEmployee[] => {
  switch (action.type) {
    case SAVE_EMPLOYEES:
      return action.payload;
    default:
      return state;
  }
};
