import produce from "immer";
import { IInsuranceProvider, IStatusOfQuestionnaire, ITracker } from "./../../Features/Tracker/Tracker.interfaces";
import {
  SAVE_TRACKER_DATA,
  CLEAR_TRACKER_DATA,
  UPDATE_LOADING_STATUS_SPREADSHEET,
  SAVE_INSURANCE_PROVIDERS,
  SAVE_ANOTHER_TRACKERS,
  SAVE_STATUS_OF_QUESTIONNAIRES,
} from "./Tracker.constants";
import { IAction, IUpdateLoadingStatus } from "./Tracker.actions";

export interface IState {
  isLoading: {
    [key: string]: boolean;
    tracker: boolean;
    anotherTrackers: boolean;
  };
  options: {
    statuses: { id: string; name: string }[];
    examTypes: { id: string; name: string }[];
    clover: string[];
    dilation: { id: string; name: string }[];
    examCodes: string[];
    products: {
      glasses: {
        orderStatus: string[];
        frameStatus: string[];
      };
      contacts: {
        orderStatus: string[];
        frameStatus: string[];
      };
    };
  };
  tracker: ITracker | null;
  insuranceProviders: IInsuranceProvider[];
  anotherTrackers: ITracker[];
  statusOfQuestionnaires: IStatusOfQuestionnaire[];
}

const initialState: IState = {
  isLoading: {
    tracker: false,
    anotherTrackers: false,
  },
  options: {
    statuses: [
      { id: "confirmed", name: "Confirmed" },
      { id: "checked_in", name: "Checked in" },
      { id: "ready_for_exam", name: "Ready for exam" },
      { id: "exam", name: "Exam" },
      { id: "checked_out", name: "Checked out" },
      { id: "cancelled_not_eligible", name: "Cancelled - Not eligible" },
      { id: "cancelled_refused_exam", name: "Cancelled - Refused exam" },
      { id: "cancelled_day_of_issue", name: "Cancelled - Day-of Issue" },
      { id: "cancelled_no_show", name: "Cancelled - No show" },
      { id: "cancelled_late_cancel", name: "Cancelled - Late cancel" },
      { id: "cancelled_patient_late", name: "Cancelled - Patient late" },
      { id: "cancelled_admin_cancel", name: "Cancelled - Admin cancel" },
      { id: "cancelled_early_cancel", name: "Cancelled - Early cancel" },
    ],
    examTypes: [
      { id: "exam", name: "Exam" },
      { id: "cl_exam", name: "CL Exam" },
      { id: "retail_only", name: "Retail only" },
      { id: "f/u", name: "F/U" },
    ],
    clover: ["visa", "master_card", "amex", "discover", "multiple", "other"],
    examCodes: ["92004", "92014", "92015", "92310", "S0620", "S0621", "99202", "99213"],
    dilation: [
      { id: "hdri", name: "HDRI" },
      { id: "full", name: "Full" },
    ],
    products: {
      glasses: {
        orderStatus: ["ordered", "purchased", "quote", "remake"],
        frameStatus: [
          "Sent from DC",
          "Special Ordered",
          "Sent from MVC",
          "POF (from MVC)",
          "POF (from Patient)",
          "Davis Frame from Lab",
          "Other",
        ],
      },
      contacts: {
        orderStatus: ["ordered", "purchased"],
        frameStatus: ["Special Ordered", "Shipped to lab today", "Customer's Own Frame (shipped today)"],
      },
    },
  },
  insuranceProviders: [],
  anotherTrackers: [],
  tracker: null,
  statusOfQuestionnaires: [],
};

const spreadsheetReducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case SAVE_TRACKER_DATA: {
      const tracker = action.payload as ITracker;
      return produce(state, (draftState: IState) => {
        draftState.tracker = tracker;
      });
    }
    case SAVE_ANOTHER_TRACKERS: {
      const trackers = action.payload as ITracker[];
      return produce(state, (draftState: IState) => {
        draftState.anotherTrackers = trackers;
      });
    }
    case SAVE_INSURANCE_PROVIDERS: {
      const insuranceProviders = action.payload as IInsuranceProvider[];
      return produce(state, (draftState: IState) => {
        draftState.insuranceProviders = insuranceProviders;
      });
    }
    case CLEAR_TRACKER_DATA: {
      return produce(state, (draftState: IState) => {
        draftState.tracker = null;
      });
    }
    case UPDATE_LOADING_STATUS_SPREADSHEET: {
      const { property, value } = action.payload as IUpdateLoadingStatus;
      return produce(state, (draftState: IState) => {
        draftState.isLoading[property] = value;
      });
    }
    case SAVE_STATUS_OF_QUESTIONNAIRES: {
      const statusOfQuestionnaires = action.payload as IStatusOfQuestionnaire[];
      return produce(state, (draftState: IState) => {
        draftState.statusOfQuestionnaires = statusOfQuestionnaires;
      });
    }
    default:
      return state;
  }
};

export default spreadsheetReducer;
