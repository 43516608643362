import React from "react";
import { Spin, Alert, Button } from "antd";
import { orderBy } from "lodash";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import styled from "styled-components";
import { compose } from "redux";
import { connect } from "react-redux";
import { IStore } from "./../../Infrastructure/Store/store.interface";
import { getWaitingRoomPatients, selectPatientForExam } from "./../../Features/WaitingRoom/WaitingRoom.actions";
import { IState as IWaitingRoomState } from "./../../Features/WaitingRoom/WaitingRoom.reducer";
import WaitingPatientsTable from "./Components/WaitingPatientsTable.component";
import { IAction as IWaitingRoomAction } from "./WaitingRoom.iterfaces";

interface IProps extends RouteComponentProps {
  waitingRoom: IWaitingRoomState;
  getWaitingRoomPatients: () => IWaitingRoomAction;
  selectPatientForExam: (trackerId: string, timeslotId: string, appointmentId: string, history: any) => void;
}
export class WaitingRoom extends React.Component<IProps> {
  public componentDidMount = () => {
    this.props.getWaitingRoomPatients();
  };

  public returnWaitingRoomPatients = () => {
    const { waitingRoom } = this.props;
    const patients: any = [];

    if (waitingRoom.trackers.length) {
      waitingRoom.trackers.forEach((tracker) => {
        tracker.timeslots.forEach((timeslot) => {
          timeslot.appointments.forEach((appointment) => {
            if (appointment.status === "ready_for_exam") {
              patients.push({
                appointment,
                startDate: timeslot.startDate,
                timezone: tracker.timezone,
                truck: tracker.truck,
                trackerId: tracker.id,
                timeslotId: timeslot.id,
              });
            }
          });
        });
      });
    }

    return orderBy(patients, "startDate", "asc");
  };

  render() {
    const { history, waitingRoom, selectPatientForExam } = this.props;

    if (waitingRoom.isLoading) {
      return (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      );
    }

    const waitingPatients = this.returnWaitingRoomPatients();

    return (
      <div>
        <Topbar>
          <TopbarMenu>
            <Link to="/">
              <Button icon="left">Back to Patient Tracker</Button>
            </Link>
          </TopbarMenu>
        </Topbar>
        {waitingPatients.length > 0 ? (
          <div>
            <WaitingPatientsTable
              waitingPatients={waitingPatients}
              selectPatientForExam={selectPatientForExam}
              history={history}
            />
          </div>
        ) : (
          <Alert
            message="Waiting Room Alert"
            style={{
              maxWidth: 700,
              margin: "10px auto 0 auto",
            }}
            type="info"
            description="All patients currently taken care of."
            showIcon
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    waitingRoom: state.waitingRoom,
  };
};

const mapDispatchToProps = {
  getWaitingRoomPatients,
  selectPatientForExam,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(WaitingRoom);

const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Topbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  padding: 15px 10px;
  border-bottom: 1px solid #dddddd;
`;

const TopbarMenu = styled.div`
  width: 980px;
  margin: 0 auto;
`;
