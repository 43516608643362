import { all, call, put, takeEvery } from "redux-saga/effects";
import API from "./../../Infrastructure/API";
import Logger from "./../../Infrastructure/Logger";
import { SEARCH_PATIENTS } from "./../../Features/PatientLookup/PatientLookup.constants";
import { saveSearchResult, updateLoadingStatus } from "./../../Features/PatientLookup/PatientLookup.actions";
import { IPatient, ISearchQuery } from "./../../Features/PatientLookup/PatientLookup.interfaces";

function* searchPatientsAsync(action: { type: string; payload: ISearchQuery }): Generator {
  try {
    const filters = action.payload;

    yield put(updateLoadingStatus(true));
    const patientsResult: any = yield call(API.searchPatients, filters);
    const patientIds = patientsResult.data.map((p) => p.id);

    const trackers: any = yield call(API.getTrackers, {
      patientIds,
    });

    const patients: IPatient[] = [];

    for (const patient of patientsResult.data) {
      const appointments: any = [];
      trackers.forEach((t) => {
        t.timeslots.forEach((timeslot) => {
          timeslot.appointments.forEach((a) => {
            if (a.patientId === patient.id) {
              appointments.push({
                trackerId: t.id,
                timezone: t.timezone,
                startDate: timeslot.startDate,
                name: t.name,
              });
            }
          });
        });
      });

      patients.push({
        ...patient,
        appointments,
      });
    }
    yield put(saveSearchResult({ ...patientsResult, result: patients }));
  } catch (error) {
    Logger.error(error);
  } finally {
    yield put(updateLoadingStatus(false));
  }
}

export default function* root(): Generator {
  yield all([takeEvery(SEARCH_PATIENTS, searchPatientsAsync)]);
}
