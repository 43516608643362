import React from "react";

interface IProps {
  label: string;
  value: string | number;
}

const TableRow = (props: IProps) => {
  const { label, value } = props;
  return (
    <tr>
      <td className="label">{label}</td>
      <td>{value}</td>
    </tr>
  );
};

export default TableRow;
