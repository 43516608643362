import { orderBy } from "lodash";
import { store } from "./../../Infrastructure/Store/store";
import moment from "moment-timezone";
import { ITimeslot } from "./Tracker.interfaces";

export const TIMESLOT_TEMPORARY_LOCK_LENGTH = 10; // minutes

export const validAppointmentStatuses = ["confirmed", "checked_in", "ready_for_exam", "exam", "checked_out"];

export const cancelledAppointmentStatuses = [
  "cancelled_not_eligible",
  "cancelled_refused_exam",
  "cancelled_day_of_issue",
  "cancelled_no_show",
  "cancelled_late_cancel",
  "cancelled_patient_late",
  "cancelled_admin_cancel",
  "cancelled_early_cancel",
];

export const getColumnSettings = (name: string) => {
  const { options, insuranceProviders } = store.getState().spreadsheet;
  switch (name) {
    case "time": {
      return { editable: false };
    }
    case "name": {
      return { editable: false };
    }
    case "ehrId": {
      return { editable: true };
    }
    case "status": {
      return {
        editable: true,
        selectOptions: options.statuses.map((status: { id: string; name: string }) => ({
          id: status.id,
          title: status.name,
          value: status.id,
          text: status.id,
        })),
      };
    }
    case "examInsurance": {
      return {
        editable: true,
        selectOptions: orderBy(insuranceProviders, [(i) => i.name.toLowerCase()], "asc")
          .filter((p) => !p.isDisabled)
          .map((p) => ({ id: p.id, title: p.name, value: p.name, text: p.name })),
      };
    }
    case "examResponsibility": {
      return {
        editable: true,
      };
    }
    case "materialsInsurance": {
      return {
        editable: true,
        selectOptions: orderBy(insuranceProviders, [(i) => i.name.toLowerCase()], "asc")
          .filter((p) => !p.isDisabled)
          .map((p) => ({ id: p.id, title: p.name, value: p.name, text: p.name })),
      };
    }
    case "materialsResponsibility": {
      return {
        editable: true,
      };
    }
    case "glasses": {
      return {
        editable: false,
      };
    }
    case "payment": {
      return {
        editable: true,
        selectOptions: options.clover.map((option) => createOptionObject(option)),
      };
    }
    case "frameStatus": {
      return {
        editable: true,
        selectOptions: options.products.glasses.frameStatus.map((option) => createOptionObject(option)),
      };
    }
    case "type": {
      return {
        editable: true,
        selectOptions: options.examTypes.map((type: { id: string; name: string }) => ({
          id: type.id,
          title: type.name,
          value: type.id,
          text: type.id,
        })),
      };
    }
    case "dilationType": {
      return {
        editable: true,
        selectOptions: options.dilation.map((option: { id: string; name: string }) => ({
          id: option.id,
          title: option.name,
          value: option.id,
          text: option.id,
        })),
      };
    }

    case "examCode": {
      return {
        editable: true,
        selectOptions: options.examCodes.map((option) => createOptionObject(option)),
      };
    }

    case "notes": {
      return { editable: true };
    }

    default:
      return {};
  }
};

export const createOptionObject = (option: string) => {
  return {
    id: option,
    title: option,
    value: option,
    text: option,
  };
};

export const assignRowColor = (statusId: string) => {
  switch (statusId) {
    case "confirmed":
      return "transparent";
    case "checked_in":
      return "blue";
    case "ready_for_exam":
      return "blue";
    case "exam":
      return "blue";
    case "checked_out":
      return "green";
    case "cancelled_not_eligible":
    case "cancelled_refused_exam":
    case "cancelled_day_of_issue":
    case "cancelled_no_show":
    case "cancelled_late_cancel":
    case "cancelled_patient_late":
    case "cancelled_admin_cancel":
    case "cancelled_early_cancel":
      return "red";
    default:
      return "transparent";
  }
};

export const getStatus = (id: string) => {
  const statuses = store.getState().spreadsheet.options.statuses;
  const status = statuses.find((status) => status.id === id);
  if (status) return status;

  return { id: "", name: "" };
};

export const getType = (id: string) => {
  const examTypes = store.getState().spreadsheet.options.examTypes;
  const status = examTypes.find((type) => type.id === id);
  if (status) return status;

  return { id: "", name: "" };
};

export const getDilationType = (id: string) => {
  const dilationTypes = store.getState().spreadsheet.options.dilation;
  const status = dilationTypes.find((type) => type.id === id);
  if (status) return status;

  return { id: "", name: "" };
};

export const isTimeslotAvailableToBook = (timeslot: ITimeslot) => {
  if (timeslot.isDisabled) {
    return false;
  }

  if (
    timeslot.temporaryLockedAt &&
    moment.utc().diff(timeslot.temporaryLockedAt, "minutes") <= TIMESLOT_TEMPORARY_LOCK_LENGTH
  ) {
    return false;
  }

  if (timeslot.appointments.some((appointment) => validAppointmentStatuses.includes(appointment.status))) {
    return false;
  }

  return true;
};
