import React from "react";
import { Row, Col, Typography } from "antd";
import styled from "styled-components";

import { formatStringField } from "../SalesforceAccount.helpers";
import { ISalesforceAccountInfo } from "../SalesforceAccount.interfaces";

interface IProps {
  salesforceAccount: ISalesforceAccountInfo;
}

const ContactInfo = (props: IProps) => {
  const { salesforceAccount } = props;
  const table = [
    {
      label: "Primary site contact:",
      value: formatStringField(salesforceAccount.primarySiteContactName),
    },
    {
      label: "Primary site contact email:",
      value: formatStringField(salesforceAccount.primarySiteContactEmail),
    },
    {
      label: "Primary site contact phone:",
      value: formatStringField(salesforceAccount.primarySiteContactPhoneNumber),
    },
  ];

  const table2 = [
    {
      label: "Day of site contact:",
      value: formatStringField(salesforceAccount.dayOfSiteContact),
    },
    {
      label: "Day of site contact email:",
      value: formatStringField(salesforceAccount.dayOfSiteContactEmail),
    },
    {
      label: "Day of site contact phone:",
      value: formatStringField(salesforceAccount.dayOfSiteContactPhone),
    },
  ];

  return (
    <Row gutter={16}>
      <Col className="gutter-row" span={12}>
        <Typography.Title level={4}>Primary site contact</Typography.Title>
        <TableStyled style={{ marginBottom: "42px" }}>
          <tbody>
            {table.map((row) => (
              <tr>
                <td className="label">{row.label}</td>
                <td>{row.value}</td>
              </tr>
            ))}
          </tbody>
        </TableStyled>
      </Col>
      <Col className="gutter-row" span={12}>
        <Typography.Title level={4}>Day-of contact</Typography.Title>
        <TableStyled style={{ marginBottom: "42px" }}>
          <tbody>
            {table2.map((row) => (
              <tr>
                <td className="label">{row.label}</td>
                <td>{row.value}</td>
              </tr>
            ))}
          </tbody>
        </TableStyled>
      </Col>
    </Row>
  );
};

export default ContactInfo;

export const TableStyled = styled.table`
  width: 100%;
  tr {
    border-bottom: 1px solid #dbdbdb;
  }
  td {
    padding: 10px;
  }
  .label {
    font-weight: bold;
    background-color: #eeeeee;
    width: 50%;
  }
`;
