import React, { useState } from "react";
import { Row, Col, Input, Button, Card, Form } from "antd";
import styled from "styled-components";

import { IState as INotes, IAction } from "./../../../Features/Notes/Notes.interfaces";

interface IProps {
  form: any;
  notes: INotes;
  updateField: (payload: { property: string; value: string }) => IAction;
  updateSiteNotes: () => void;
}

const SiteNotes = (props: IProps) => {
  const [siteNotesLoading, updateSiteNotesLoadingStatus] = useState(false);

  const { notes, updateField, updateSiteNotes, form } = props;
  const { getFieldDecorator } = form;

  return (
    <>
      <Row>
        <Col span={24}>
          <Card size="small" title="Site notes">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                updateSiteNotesLoadingStatus(true);
                updateSiteNotes();
                setTimeout(() => updateSiteNotesLoadingStatus(false), 1000);
              }}>
              <Form.Item style={{ marginBottom: "10px" }}>
                {getFieldDecorator("siteNotes", {
                  initialValue: notes.siteNotes,
                  rules: [{ required: false, message: "Please input site notes!" }],
                })(
                  <Input.TextArea
                    rows={4}
                    onBlur={(e) =>
                      updateField({
                        property: "siteNotes",
                        value: e.target.value,
                      })
                    }
                  />
                )}
              </Form.Item>
              <Form.Item style={{ marginBottom: "10px" }}>
                <ButtonContainer>
                  <Button type="primary" htmlType="submit" loading={siteNotesLoading}>
                    Save
                  </Button>
                </ButtonContainer>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SiteNotes;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
