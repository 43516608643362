import { SagaIterator } from "redux-saga";
import { all, fork } from "redux-saga/effects";

import topbar from "./../../Features/Topbar/Topbar.saga";
import tracker from "./../../Features/Tracker/Tracker.saga";
import statistics from "./../../Features/Statistics/Statistics.saga";
import authentication from "./../../Features/Authentication/Authentication.saga";
import staff from "./../../Features/Staff/Staff.saga";
import calendar from "./../../Features/Calendar/Calendar.saga";
import notes from "./../../Features/Notes/Notes.saga";
import patientLookup from "./../../Features/PatientLookup/PatientLookup.saga";
import waitingRoom from "./../../Features/WaitingRoom/WaitingRoom.saga";
import salesforceAccount from "./../../Features/SalesforceAccount/SalesforceAccount.saga";

export default function* root(): SagaIterator {
  yield all([
    fork(topbar),
    fork(tracker),
    fork(statistics),
    fork(authentication),
    fork(staff),
    fork(calendar),
    fork(notes),
    fork(patientLookup),
    fork(waitingRoom),
    fork(salesforceAccount),
  ]);
}
