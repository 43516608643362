import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Form } from "antd";
import styled from "styled-components";
import { FormComponentProps } from "antd/es/form";

import { IStore } from "./../../../Infrastructure/Store/store.interface";
import { IAction } from "./../../../Features/Notes/Notes.interfaces";

import { synchronizeTracker } from "./../../../Features/Notes/Notes.actions";

import TrackingNumberComponent from "./../../../Features/Notes/components/TrackingNumber.component";
import { IState as ISpreadsheetState } from "../../Tracker/Tracker.reducer";
import API from "../../../Infrastructure/API/index";

interface IProps extends FormComponentProps {
  spreadsheet: ISpreadsheetState;
  synchronizeTracker: (payload: { trackerId: string }) => IAction;
}

interface IState {
  isUpdating: boolean;
}

class TrackingNumber extends Component<IProps, IState> {
  public state: IState = {
    isUpdating: false,
  };

  public updateTrackingNumber = async () => {
    const { form, spreadsheet } = this.props;
    const { tracker } = spreadsheet;

    this.setState({ isUpdating: true });

    await API.updateTracker(tracker!.id, {
      name: tracker!.name,
      date: tracker!.date,
      locationId: tracker!.locationId,
      timezone: tracker!.timezone,
      parkingLocationDetails: tracker!.parkingLocationDetails,
      SFVisitId: tracker!.SFVisitId,
      notes: tracker!.notes,
      issueNotes: tracker!.issueNotes,
      trackingNumber: form.getFieldValue("trackingNumber"),
    });

    this.setState({ isUpdating: false });
  };

  public render() {
    const { isUpdating } = this.state;
    const { form, spreadsheet, synchronizeTracker } = this.props;
    return (
      <Container>
        <TrackingNumberComponent
          form={form}
          trackingNumber={spreadsheet.tracker?.trackingNumber}
          trackerId={spreadsheet.tracker?.id}
          isUpdating={isUpdating}
          updateTrackingNumber={this.updateTrackingNumber}
          synchronizeTracker={synchronizeTracker}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    spreadsheet: state.spreadsheet,
  };
};

const mapDispatchToProps = {
  synchronizeTracker,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), Form.create({}))(TrackingNumber);

const Container = styled.div``;
