import React, { Component } from "react";
import moment from "moment";
import { Button, Icon, Popover, Calendar, Select, Row, Col, Radio } from "antd";

interface IProps {
  date: Date;
  updateDate: (value: any) => void;
  addDay: () => void;
  subtractDay: () => void;
}

interface IState {
  calendarVisible: boolean;
}

export class Datepicker extends Component<IProps, IState> {
  public state = {
    calendarVisible: false,
  };

  private changeCalendarVisibility = (isVisible: boolean) => {
    this.setState({ calendarVisible: isVisible });
  };

  public render() {
    const { date, updateDate, addDay, subtractDay } = this.props;
    return (
      <div>
        <Button.Group>
          <Button onClick={subtractDay}>
            <Icon type="left" />
          </Button>
          <Popover
            placement="bottom"
            trigger="click"
            visible={this.state.calendarVisible}
            onVisibleChange={this.changeCalendarVisibility}
            content={
              <Calendar
                style={{ width: "350px" }}
                fullscreen={false}
                onSelect={(date) => {
                  updateDate(date);
                  this.changeCalendarVisibility(false);
                }}
                headerRender={({ value, type, onChange, onTypeChange }) => {
                  const today = moment();

                  const start = 0;
                  const end = 12;
                  const monthOptions = [];

                  const current = value.clone();
                  const localeData = value.localeData();
                  const months = [];
                  for (let i = 0; i < 12; i++) {
                    current.month(i);
                    months.push(localeData.monthsShort(current));
                  }

                  for (let index = start; index < end; index++) {
                    monthOptions.push(
                      <Select.Option className="month-item" key={`${index}`}>
                        {months[index]}
                      </Select.Option>
                    );
                  }
                  const month = value.month();

                  const year = value.year();
                  const options = [];
                  for (let i = year - 10; i < year + 10; i += 1) {
                    options.push(
                      <Select.Option key={i} value={i} className="year-item">
                        {i}
                      </Select.Option>
                    );
                  }
                  return (
                    <div style={{ padding: 8 }}>
                      <Row justify="space-around">
                        <Col span={5}>
                          <Select
                            size="small"
                            dropdownMatchSelectWidth={false}
                            value={String(month)}
                            onChange={(selectedMonth) => {
                              const newValue = value.clone();
                              newValue.month(parseInt(selectedMonth, 10));
                              onChange(newValue);
                            }}>
                            {monthOptions}
                          </Select>
                        </Col>
                        <Col span={6}>
                          <Select
                            size="small"
                            dropdownMatchSelectWidth={false}
                            className="my-year-select"
                            onChange={(newYear) => {
                              const now = value.clone().year(newYear);
                              onChange(now);
                            }}
                            value={String(year)}>
                            {options}
                          </Select>
                        </Col>
                        <Col span={9}>
                          <Radio.Group size="small" onChange={(e) => onTypeChange(e.target.value)} value={type}>
                            <Radio.Button value="month">Month</Radio.Button>
                            <Radio.Button value="year">Year</Radio.Button>
                          </Radio.Group>
                        </Col>
                        <Col span={4}>
                          <Button
                            size="small"
                            type="primary"
                            onClick={() => {
                              onChange(today);
                              updateDate(today);
                            }}>
                            Today
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  );
                }}
              />
            }>
            <Button data-testid="date-button">{moment(date).format("MM/DD/YYYY")}</Button>
          </Popover>
          <Button onClick={addDay}>
            <Icon type="right" />
          </Button>
        </Button.Group>
      </div>
    );
  }
}

export default Datepicker;
