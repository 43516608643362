import React from "react";
import styled from "styled-components";
import { Cell } from "react-data-grid";

import { IProduct } from "./../../../../Features/Tracker/Tracker.interfaces";
import { assignRowColor, getStatus } from "./../../../../Features/Tracker/Tracker.helpers";

const CellRenderer = (props: any) => {
  const { rowData, column } = props;
  const glasses = rowData.appointment ? rowData.appointment.products.filter((p: IProduct) => p.type === "glasses") : [];

  if (rowData.isDisabled) {
    return (
      <Content>
        <Cell {...props} className="empty-timeslot" />
      </Content>
    );
  }

  if (!rowData.isDisabled && !rowData.isBooked && !rowData.appointment) {
    return (
      <Content>
        <Cell {...props} className="no-appointment" />
      </Content>
    );
  }

  if (column.key === "notes" && rowData.appointment) {
    return (
      <Content notesColor={rowData.appointment.notesColor}>
        <Cell {...props} value={rowData.appointment.notes} className="notes-color" />
      </Content>
    );
  }

  if (column.name === "Glasses" && glasses.length > 0) {
    const allCorrect = glasses.every(
      (glasses: IProduct) => glasses.status === "ordered" || glasses.status === "remake"
    );
    return (
      <Content>
        <Cell {...props} className={allCorrect ? "green" : "yellow"} />
      </Content>
    );
  }

  return (
    <Content>
      <Cell {...props} className={assignRowColor(getStatus(rowData.appointment.status).id)} />
    </Content>
  );
};

export default CellRenderer;

const Content = styled.div`
  .notes-color {
    background-color: ${(props: { notesColor?: string }) => `${props.notesColor} !important`};
  }
  .red {
    background-color: #f4c7c3 !important;
  }

  .green {
    background-color: #b7e1cd !important;
  }

  .yellow {
    background-color: #fce8b2 !important;
  }

  .blue {
    background-color: #a4c2f4 !important;
  }

  .violet {
    background-color: #b4a7d6 !important;
  }

  .no-appointment {
    background-color: #f4f5f6 !important;
  }

  .empty-timeslot {
    background-color: #dfdfdf !important;
    height: 40px;
    border-bottom: 1px solid #eeeeee;

    p {
      padding: 10px 8px;
    }

    .time {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .transparent {
    background-color: transparent !important;
  }

  .react-grid-Cell__value,
  .react-grid-HeaderCell {
    white-space: normal !important;
  }

  .react-grid-Cell__value div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
