import { call, put, takeEvery, all } from "redux-saga/effects";
import _ from "lodash";
import { GET_CALENDAR_TRACKERS, saveCalendarTrackers } from "./../../Features/Calendar/Calendar.actions";
import API from "./../../Infrastructure/API";
import Logger from "./../../Infrastructure/Logger";
import { IWeek } from "./../../Features/Calendar/Calendar.interfaces";
import moment from "moment-timezone";

function* getCalendarTrackersAsync(action: { type: string; payload: Date }): Generator {
  try {
    const date = action.payload;
    const startDate = moment(date).startOf("month");
    const endDate = moment(date).endOf("month");

    const trackers: any = yield call(API.getTrackers, {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });

    const trucksResponse: any = yield call(API.findTrucks);

    const result = _.range(
      startDate.weeks(),
      endDate.weeks() === 1 ? startDate.weeks() + 5 : endDate.weeks() + 1,
      1
    ).map((weekNumber) => {
      const trackersWithinWeek: any = [];
      trackers.forEach((t) => {
        if (t.timeslots.length) {
          const timeslot = t.timeslots[0];
          if (moment(timeslot.startDate).weeks() === weekNumber) {
            trackersWithinWeek.push({
              id: t.id,
              date: timeslot.startDate,
              truck: t.truck,
              name: t.name,
              weekDay: moment(timeslot.startDate).days(),
            });
          }
        }
      });

      const trucks = trucksResponse.map((t) => t.name);

      return {
        weekNumber,
        trucks: trucks.map((truck) => {
          return {
            name: truck,
            trackers: trackersWithinWeek.filter((t) => {
              if (t.truck === truck) {
                return true;
              }
              return false;
            }),
          };
        }),
      };
    });

    yield put(saveCalendarTrackers(result as IWeek[]));
  } catch (error) {
    Logger.error(error);
  }
}

export default function* root(): Generator {
  yield all([takeEvery(GET_CALENDAR_TRACKERS, getCalendarTrackersAsync)]);
}
