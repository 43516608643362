import { call, put, takeEvery, all } from "redux-saga/effects";

import {
  GET_SALESFORCE_ACCOUNT_INFO,
  saveSalesforceAccountInfo,
  saveSalesforceAccountInfoError,
  toggleLoadingStatus,
} from "./SalesforceAccount.actions";
import API from "../../Infrastructure/API/index";
import Logger from "../../Infrastructure/Logger/index";

function* getSalesforceAccountInfoAsync(action: { type: string; payload: string }): Generator {
  try {
    const SFAccountId = action.payload;
    if (SFAccountId) {
      yield put(toggleLoadingStatus(true));
      const salesforceAccountInfo: any = yield call(API.getSalesforceAccountInfo, SFAccountId);

      if (salesforceAccountInfo) {
        yield put(saveSalesforceAccountInfoError(null));
        yield put(saveSalesforceAccountInfo(salesforceAccountInfo));
        yield put(toggleLoadingStatus(false));
      }
    } else {
      yield put(saveSalesforceAccountInfoError("SFAccountId is not assigned!"));
    }
  } catch (error) {
    if (error.response) {
      yield put(saveSalesforceAccountInfoError(error.response.data.message));
      yield put(toggleLoadingStatus(false));
    }
    Logger.error(error);
  }
}

export default function* root(): Generator {
  yield all([takeEvery(GET_SALESFORCE_ACCOUNT_INFO, getSalesforceAccountInfoAsync)]);
}
