import React from "react";
import { Alert } from "antd";

interface IProps {
  message: string;
}

const SalesforceResourcesError = (props: IProps) => {
  const { message } = props;
  return (
    <div>
      <Alert message={message} type="error" />
    </div>
  );
};

export default SalesforceResourcesError;
