import { call, put, takeEvery, all, select } from "redux-saga/effects";

import { GET_EMPLOYEES, saveEmployees, ADD_EMPLOYEES, REMOVE_EMPLOYEE } from "./../../Features/Staff/Staff.actions";
import API from "./../../Infrastructure/API";
import Logger from "./../../Infrastructure/Logger";

function* getEmployeesAsync(): Generator {
  try {
    const employees: any = yield call(API.getEmployees);
    yield put(saveEmployees(employees));
  } catch (error) {
    Logger.error(error);
  }
}

function* addEmployeesAsync(action: { type: string; payload: { id: string; position: string }[] }): Generator {
  try {
    const trackerId: string = yield select((state) => state.spreadsheet.tracker.id);

    for (const employee of action.payload) {
      yield call(API.addEmployeeToTracker, trackerId, employee);
    }
  } catch (error) {
    Logger.error(error);
  }
}

function* removeEmployeeAsync(action: { type: string; payload: string }): Generator {
  try {
    const employeeId = action.payload;
    const trackerId: string = yield select((state) => state.spreadsheet.tracker.id);
    yield call(API.removeEmployeeFromVisit, trackerId, employeeId);
  } catch (error) {
    Logger.error(error);
  }
}

export default function* root(): Generator {
  yield all([
    takeEvery(GET_EMPLOYEES, getEmployeesAsync),
    takeEvery(ADD_EMPLOYEES, addEmployeesAsync),
    takeEvery(REMOVE_EMPLOYEE, removeEmployeeAsync),
  ]);
}
