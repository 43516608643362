import {
  CHECK_LOGGED_IN,
  CHECK_LOGGED_IN_FAILURE,
  SAVE_LOGIN_DETAILS,
} from "./../../Features/Authentication/Authentication.actions";
import produce from "immer";
import {
  Actions,
  IAuthenticationState,
  IUserDetailsResponse,
} from "./../../Features/Authentication/Authentication.interfaces";

const initialState: IAuthenticationState = {
  isLoading: true,
  isAuthenticated: false,
  email: "",
};

const authenticationReducer = (state = initialState, action: Actions): IAuthenticationState => {
  switch (action.type) {
    case CHECK_LOGGED_IN:
      return produce(state, (draftState: IAuthenticationState) => {
        draftState.email = "";
        draftState.isAuthenticated = false;
        draftState.isLoading = true;
      });
    case SAVE_LOGIN_DETAILS:
      const userDetails = action.payload as IUserDetailsResponse;
      return produce(state, (draftState: IAuthenticationState) => {
        draftState.email = userDetails.email;
        draftState.isAuthenticated = true;
        draftState.isLoading = false;
      });
    case CHECK_LOGGED_IN_FAILURE:
      return { ...state, isLoading: false, isAuthenticated: false };
    default:
      return state;
  }
};

export default authenticationReducer;
