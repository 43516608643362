import { GET_TRACKER_STATISTICS, SAVE_TRACKER_STATISTICS } from "./Statistics.constants";
import { ITrackerStatistics } from "./Statistics.interfaces";

export const getTrackerStatistics = (trackerId: number) => {
  return {
    type: GET_TRACKER_STATISTICS,
    payload: trackerId,
  };
};

export const saveTrackerStatistics = (trackerStatistics: ITrackerStatistics) => {
  return {
    type: SAVE_TRACKER_STATISTICS,
    payload: trackerStatistics,
  };
};
