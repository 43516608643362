import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Typography, Table, Tag, Icon } from "antd";
import moment from "moment-timezone";

import { IPatient } from "./../../../Features/PatientLookup/PatientLookup.interfaces";

interface IProps {
  patient: IPatient;
}

const ListItem = (props: IProps) => {
  const { patient } = props;
  const columns = [
    {
      width: "100px",
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (text: string, row: any) =>
        row.startDate && row.timezone ? (
          <Tag>{moment(row.startDate).tz(row.timezone).format("MM/DD/YYYY")}</Tag>
        ) : (
          <Tag color="red">Invalid date</Tag>
        ),
    },
    {
      width: "100px",
      title: "Time",
      dataIndex: "time",
      key: "time",
      render: (text: string, row: any) =>
        row.startDate && row.timezone ? (
          <Tag>{moment(row.startDate).tz(row.timezone).format("LT")}</Tag>
        ) : (
          <Tag color="red">Invalid time</Tag>
        ),
    },
    {
      width: "300px",
      title: "Tracker name",
      dataIndex: "companyName",
      key: "companyName",
      render: (text: string, row: any) => <div>{row.name}</div>,
    },
    {
      title: "Tracker",
      dataIndex: "trackerId",
      key: "trackerId",
      render: (text: string, row: any) => (
        <div>
          <Link to={`/?trackerId=${row.trackerId}&date=${row.startDate}`}>
            <Icon type="link" style={{ marginRight: "5px" }} />
            Link
          </Link>
        </div>
      ),
    },
  ];

  const dataSource = patient.appointments.map((appointment) => {
    return {
      name: appointment.name,
      timezone: appointment.timezone,
      startDate: appointment.startDate,
      trackerId: appointment.trackerId,
    };
  });

  return (
    <Container>
      <ItemHeader>
        <div>
          <Typography.Title level={4} style={{ display: "inline" }}>
            {patient.firstName} {patient.lastName}
          </Typography.Title>
          <span style={{ marginLeft: "5px" }}>(#{patient.id})</span>
          <div>DOB: {moment(patient.birth).format("MM/DD/YYYY")}</div>
        </div>
        <ContactInfoContainer>
          <div>
            <Icon type="mail" style={{ marginRight: "2px" }} /> {patient.email}
          </div>
          <div style={{ margin: "0 5px" }}> | </div>
          <div>
            <Icon type="phone" style={{ marginRight: "2px" }} /> {patient.phoneNumber}
          </div>
        </ContactInfoContainer>
      </ItemHeader>
      <div>
        <Table columns={columns} dataSource={dataSource} pagination={false} />
      </div>
    </Container>
  );
};

export default ListItem;

const Container = styled.div`
  margin-bottom: 50px;
`;

const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ContactInfoContainer = styled.div`
  display: flex;
`;
