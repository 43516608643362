import React from "react";
import { Icon, Popover, Button, Input } from "antd";
import { GithubPicker } from "react-color";
import { hex } from "wcag-contrast";
import styled from "styled-components";

interface IProps {
  row: any;
  updateValue: (property: string, value: string) => void;
}

class NotesFormatter extends React.Component<IProps> {
  recognizeUrlsInText = (text: string) => {
    const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    const urls: string[] = [];
    text.replace(urlRegex, (url) => urls.push(url));
    return urls;
  };

  public render() {
    const { updateValue } = this.props;
    const { row } = this.props.row;
    const contrastRatio =
      row.appointment && row.appointment.notesColor ? hex(row.appointment.notesColor, "#575757") : undefined;
    const fontColor = contrastRatio ? (contrastRatio > 3 ? "#575757" : "#ffffff") : "#575757";
    const popoverContent = (
      <div>
        <GithubPicker width="212px" triangle="hide" onChange={(value) => updateValue("notesColor", value.hex)} />
        {row.appointment && row.appointment.notesColor && (
          <ResetButtonContainer>
            <Button
              type="dashed"
              size="small"
              style={{ fontSize: "12px" }}
              onClick={() => updateValue("notesColor", "")}>
              Reset color
            </Button>
          </ResetButtonContainer>
        )}
      </div>
    );

    const urls = row.appointment && row.appointment.notes ? this.recognizeUrlsInText(row.appointment.notes || "") : [];
    const popoverNotesContent = (
      <div>
        <div>
          <Input.TextArea
            rows={6}
            defaultValue={row.appointment && row.appointment.notes ? row.appointment.notes : ""}
            style={{ width: 400 }}
            onBlur={(e) => updateValue("notes", e.target.value)}
          />
        </div>
        <div>
          <ul
            style={{
              listStylePosition: "outside",
              marginTop: 10,
              paddingLeft: 20,
            }}>
            {urls.map((u) => (
              <li>
                <a href={u} target="_blank">
                  {u.substr(0, 50)}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );

    return row.appointment ? (
      <div className="notes-field">
        <Popover placement="bottom" content={popoverContent} trigger="click">
          <Icon type="bg-colors" style={{ color: fontColor }} />
        </Popover>
        <Popover placement="bottomLeft" content={popoverNotesContent} trigger="click">
          <Icon type="caret-down" style={{ margin: "0 10px", color: fontColor }} />
        </Popover>
        <span style={{ color: fontColor }}>{row.appointment.notes ? row.appointment.notes : ""}</span>
      </div>
    ) : null;
  }
}

export default NotesFormatter;

const ResetButtonContainer = styled.div`
  padding: 5px;
`;
