import { call, put, takeEvery, all, select } from "redux-saga/effects";
import moment from "moment-timezone";
import { GET_WAITING_ROOM_DATA, SELECT_PATIENT_FOR_EXAM } from "./WaitingRoom.constants";
import { updateLoadingStatus, saveWaitingRoomData, getWaitingRoomPatients } from "./WaitingRoom.actions";
import Logger from "../../Infrastructure/Logger";
import API from "../../Infrastructure/API/index";
import { IStore } from "src/Infrastructure/Store/store.interface";

function* getWaitingRoomPatientsAsync(): Generator {
  try {
    yield put(updateLoadingStatus(true));
    // @ts-ignore
    const state: IStore = yield select();
    const date = state.topbar.selectedDate;

    const availableTrackersForSpecificDay: any = yield call(API.getTrackers, {
      startDate: moment(date).utc(true).startOf("day").toDate(),
      endDate: moment(date).utc(true).startOf("day").toDate(),
    });

    yield put(saveWaitingRoomData(availableTrackersForSpecificDay));

    yield put(updateLoadingStatus(false));
  } catch (error) {
    Logger.error(error);
  }
}

function* selectPatientForExam(action: {
  type: string;
  payload: {
    trackerId: string;
    timeslotId: string;
    appointmentId: string;
    history: any;
  };
}): Generator {
  try {
    const { trackerId, timeslotId, appointmentId, history } = action.payload;

    // @ts-ignore
    const state: IStore = yield select();
    const tracker = state.waitingRoom.trackers.find((t) => t.id === trackerId);
    const timeslot = tracker!.timeslots.find((t) => t.id === timeslotId);
    const appointment = timeslot?.appointments.find((a) => a.id === appointmentId);

    if (appointment) {
      yield call(
        // @ts-ignore
        API.updateAppointment,
        trackerId,
        appointmentId,
        {
          status: "exam",
          type: appointment.type,
          dilationType: appointment.dilationType,
          payment: appointment.payment,
          survey: appointment.survey,
          materialsResponsibility: appointment.materialsResponsibility,
          examResponsibility: appointment.examResponsibility,
          notes: appointment.notes,
          notesColor: appointment.notesColor,
          examInsurance: appointment.examInsurance,
          materialsInsurance: appointment.materialsInsurance,
        }
      );
      yield put(getWaitingRoomPatients());
    }
  } catch (error) {
    Logger.error(error);
  }
}

export default function* root(): Generator {
  yield all([
    takeEvery(GET_WAITING_ROOM_DATA, getWaitingRoomPatientsAsync),
    takeEvery(SELECT_PATIENT_FOR_EXAM, selectPatientForExam),
  ]);
}
