import React from "react";
import { getStatus } from "./../../../../Features/Tracker/Tracker.helpers";
import { Icon } from "antd";

const StatusFormatter = (props: any) => {
  return (
    <span>
      {getStatus(props.row.appointment.status)!.name} <Icon style={{ fontSize: 10 }} type="down" />
    </span>
  );
};

export default StatusFormatter;
