import { IWeek } from "./../../Features/Calendar/Calendar.interfaces";

export const GET_CALENDAR_TRACKERS = "GET_CALENDAR_TRACKERS";
export const SAVE_CALENDAR_TRACKERS = "SAVE_CALENDAR_TRACKERS";
export const UPDATE_MONTH = "UPDATE_MONTH";

export const getCalendarTrackers = (date: Date) => {
  return {
    type: GET_CALENDAR_TRACKERS,
    payload: date,
  };
};

export const saveCalendarTrackers = (data: IWeek[]) => {
  return {
    type: SAVE_CALENDAR_TRACKERS,
    payload: data,
  };
};

export const updateMonth = (date: Date) => {
  return {
    type: UPDATE_MONTH,
    payload: date,
  };
};
