import React from "react";
import { Row, Col, Spin } from "antd";
import styled from "styled-components";

import { IPatient } from "./../../../Features/PatientLookup/PatientLookup.interfaces";
import ListItem from "./../../../Features/PatientLookup/components/ListItem.component";

interface IProps {
  isLoading: boolean;
  results: IPatient[];
}

const PatientsList = (props: IProps) => {
  const { isLoading, results } = props;
  return isLoading ? (
    <SpinContainer>
      <Spin />
    </SpinContainer>
  ) : (
    <Row>
      <Col span={24}>
        {results.map((item) => (
          <ListItem patient={item} />
        ))}
      </Col>
    </Row>
  );
};

export default PatientsList;

const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
