import { Actions, ITrackerStatistics } from "./../../Features/Statistics/Statistics.interfaces";
import { SAVE_TRACKER_STATISTICS } from "./Statistics.constants";

export interface IState extends ITrackerStatistics {}

const initialState: IState = {
  attachmentRate: 0,
  attachmentRateGoal: 0,
  soldGlasses: 0,
  soldContacts: 0,
  remakeGlasses: 0,
  checkedOutAppointments: 0,
  lateCancelAppointments: 0,
  noShowAppointments: 0,
  refusedExamAppointments: 0,
  totalExamResponsibility: 0,
  totalMaterialsResponsibility: 0,
  signUps: 0,
  examsCompleted: 0,
  retailOnly: 0,
};

const statisticsReducer = (state = initialState, action: Actions): IState => {
  switch (action.type) {
    case SAVE_TRACKER_STATISTICS:
      const trackerStatistics = action.payload as ITrackerStatistics;
      return { ...trackerStatistics };
    default:
      return state;
  }
};

export default statisticsReducer;
