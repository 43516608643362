import React from "react";
import { IState as ISpreadsheetState } from "../Tracker/Tracker.reducer";
import { IStore } from "./../../Infrastructure/Store/store.interface";
import { connect } from "react-redux";
import { TableOrderedProducts } from "./../../Features/OrderedProducts/components/Table.component";

interface IProps {
  spreadsheet: ISpreadsheetState;
}

export class OrderedProducts extends React.Component<IProps> {
  public render() {
    const { spreadsheet } = this.props;
    return <TableOrderedProducts spreadsheet={spreadsheet} />;
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    spreadsheet: state.spreadsheet,
  };
};

export default connect(mapStateToProps)(OrderedProducts);
