export interface IEnvironmentConfig {
  API_URL: string;
  AUTHENTICATION_APP_URL: string;
  AUTHORIZATION_URL: string;
  ADMIN_PANEL_URL: string;
  SALESFORCE_URL: string;
  PATIENT_TRACKER_URL: string;
}

export default class Config {
  public static get(key: string): any {
    return {
      API_URL: process.env.API_URL,
      AUTHENTICATION_APP_URL: process.env.AUTHENTICATION_APP_URL,
      AUTHORIZATION_URL: process.env.AUTHORIZATION_URL,
      ADMIN_PANEL_URL: process.env.ADMIN_PANEL_URL,
      SALESFORCE_URL: process.env.SALESFORCE_CONNECTOR_URL,
      PATIENT_TRACKER_URL: process.env.PATIENT_TRACKER_URL,
    }[key as keyof IEnvironmentConfig];
  }
}
