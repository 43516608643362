import produce from "immer";
import { IState, Actions, IWeek } from "./../../Features/Calendar/Calendar.interfaces";
import moment from "moment-timezone";

import { SAVE_CALENDAR_TRACKERS, UPDATE_MONTH } from "./../../Features/Calendar/Calendar.actions";

const initialState: IState = {
  selectedMonth: moment().tz("America/New_York").toDate(),
  weeks: [],
};

const calendarReducer = (state = initialState, action: Actions): IState => {
  switch (action.type) {
    case SAVE_CALENDAR_TRACKERS:
      const weeks = action.payload as IWeek[];
      return produce(state, (draftState: IState) => {
        draftState.weeks = weeks;
      });
    case UPDATE_MONTH:
      const date = action.payload as Date;
      return produce(state, (draftState: IState) => {
        draftState.selectedMonth = moment(date).tz("America/New_York").toDate();
      });
    default:
      return state;
  }
};

export default calendarReducer;
