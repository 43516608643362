import React from "react";
import styled from "styled-components";
import { uniqBy, range } from "lodash";
import moment from "moment";

import { getCellColor } from "./../../../Features/Calendar/Calendar.helper";
import { IWeek } from "./../../../Features/Calendar/Calendar.interfaces";

interface IProps {
  weekNumber: number;
  week: IWeek;
  selectedMonth: Date;
  showHoldReservations: boolean;
}

const Week = (props: IProps) => {
  const { selectedMonth, week, showHoldReservations } = props;
  let { trucks } = props.week;
  const allTrucks = trucks.map((truck) => {
    return {
      ...truck,
      trackers: uniqBy(truck.trackers, "weekDay"),
    };
  });

  const confirmedTrucks = allTrucks.filter((truck) => !truck.name.includes("Hold"));

  trucks = showHoldReservations ? allTrucks : confirmedTrucks;

  const dates = [
    moment(selectedMonth).startOf("year").weeks(week.weekNumber).day(1).toDate(),
    moment(selectedMonth).startOf("year").weeks(week.weekNumber).day(2).toDate(),
    moment(selectedMonth).startOf("year").weeks(week.weekNumber).day(3).toDate(),
    moment(selectedMonth).startOf("year").weeks(week.weekNumber).day(4).toDate(),
    moment(selectedMonth).startOf("year").weeks(week.weekNumber).day(5).toDate(),
  ];

  const trackersRows = {
    1: { id: "", name: "", weekDay: 1, date: dates[0] },
    2: { id: "", name: "", weekDay: 2, date: dates[1] },
    3: { id: "", name: "", weekDay: 3, date: dates[2] },
    4: { id: "", name: "", weekDay: 4, date: dates[3] },
    5: { id: "", name: "", weekDay: 5, date: dates[4] },
  };

  return (
    <Row>
      <WeekNumber>{props.weekNumber}</WeekNumber>
      <div style={{ width: "98%" }}>
        <Dates>
          <Cell width="10%">
            <Date>Date</Date>
          </Cell>
          {range(5).map((index) => {
            return (
              <Cell key={index} width="15%">
                <Date>{moment(dates[index]).format("dddd, MM/DD/YYYY")}</Date>
              </Cell>
            );
          })}
        </Dates>
        {trucks.map((truck) => {
          const rows = { ...trackersRows };
          truck.trackers.forEach((t) => (rows[t.weekDay] = t));
          return (
            <Truck key={truck.name}>
              <Cell width="10%">
                <RowInCell textAlign="left">{truck.name}</RowInCell>
              </Cell>
              {Object.values(rows).map((tracker, index) => (
                <Cell key={index} width="15%">
                  <RowInCell bgColor={getCellColor(truck.name, tracker.name, tracker.date, selectedMonth)}>
                    {tracker.name ? tracker.name : <span>-</span>}
                  </RowInCell>
                </Cell>
              ))}
            </Truck>
          );
        })}
      </div>
    </Row>
  );
};

export default Week;

const Row = styled.div`
  border-top: 1px solid #000000;
  height: 100%;
  width: 100%;
  display: flex;
`;

export const WeekNumber = styled.div`
  width: 2%;
  border-right: 1px solid #000000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Dates = styled.div`
  width: 100%;
  display: table;
  background-color: #efefef;
  border-bottom: 1px solid #000000;
`;

const Date = styled.div`
  font-weight: bold;
  color: #c5392a;
`;

export const Truck = styled.div`
  width: 100%;
  display: table;
  border-bottom: 1px solid #000000;

  &:last-child {
    border: none;
  }
`;

export const Cell = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  border-right: 1px solid #000000;
  &:last-child {
    border-right: none;
  }
  width: ${(props: { width?: string }) => (props.width ? props.width : "auto")};
`;

const RowInCell = styled.div`
  text-align: ${(props: { bgColor?: string; textAlign?: string }) => (props.textAlign ? props.textAlign : "center")};
  background-color: ${(props: { bgColor?: string; textAlign?: string }) =>
    props.bgColor ? props.bgColor : "transparent"};
  padding: 0 5px;

  span {
    color: transparent;
  }
`;
