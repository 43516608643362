import { ISalesforceAccountInfo } from "./SalesforceAccount.interfaces";

export const GET_SALESFORCE_ACCOUNT_INFO = "GET_SALESFORCE_ACCOUNT_INFO";
export const SAVE_SALESFORCE_ACCOUNT_INFO = "SAVE_SALESFORCE_ACCOUNT_INFO";
export const SALESFORCE_ACCOUNT_TOGGLE_LOADING_STATUS = "SALESFORCE_ACCOUNT_TOGGLE_LOADING_STATUS";
export const SAVE_SALESFORCE_ACCOUNT_INFO_ERROR = "SAVE_SALESFORCE_ACCOUNT_INFO_ERROR";

export const getSalesforceAccountInfo = (SFAccountId: string) => {
  return {
    type: GET_SALESFORCE_ACCOUNT_INFO,
    payload: SFAccountId,
  };
};

export const saveSalesforceAccountInfo = (payload: ISalesforceAccountInfo) => {
  return {
    type: SAVE_SALESFORCE_ACCOUNT_INFO,
    payload,
  };
};

export const saveSalesforceAccountInfoError = (message: string | null) => {
  return {
    type: SAVE_SALESFORCE_ACCOUNT_INFO_ERROR,
    payload: message,
  };
};

export const toggleLoadingStatus = (isLoading: boolean) => {
  return {
    type: SALESFORCE_ACCOUNT_TOGGLE_LOADING_STATUS,
    payload: isLoading,
  };
};
