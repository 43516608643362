import React from "react";
import styled from "styled-components";
import { Table, Tag } from "antd";
import moment from "moment-timezone";

interface IProps {
  history: any;
  waitingPatients: any;
  selectPatientForExam: (trackerId: string, timeslotId: string, appointmentId: string, history: any) => void;
}

const WaitingPatientsTable = (props: IProps) => {
  const { history, waitingPatients } = props;
  const columns = [
    {
      title: "Appointment Time",
      width: "100px",
      dataIndex: "appointmentTime",
      key: "appointmentTime",
      render: (_text: string, row: any) =>
        row.startDate ? (
          <Tag>{moment(row.startDate).tz(row.timezone).format("LT")}</Tag>
        ) : (
          <Tag color="red">Invalid time</Tag>
        ),
    },
    {
      title: "Name",
      width: "100px",
      dataIndex: "name",
      key: "name",
      render: (_text: string, row: any) => (
        <div>
          {row.appointment.patient.firstName} {row.appointment.patient.lastName}
        </div>
      ),
    },
    {
      title: "EHR ID#",
      width: "50px",
      dataIndex: "patientTrackerId",
      key: "patientTrackerId",
      render: (_text: string, row: any) => <div>-</div>,
    },
    {
      title: "Exam Type",
      width: "75px",
      dataIndex: "examType",
      key: "examType",
      render: (_text: string, row: any) => <div>{row.appointment.type}</div>,
    },
    {
      title: "Dilation option",
      width: "100px",
      dataIndex: "dilationOption",
      key: "dilationOption",
      render: (_text: string, row: any) => <div>{row.appointment.dilationType}</div>,
    },
    {
      title: "Select",
      key: "select",
      width: "75px",
      render: (_text: string, row: any) => (
        <a onClick={() => props.selectPatientForExam(row.trackerId, row.timeslotId, row.appointment.id, history)}>
          Select
        </a>
      ),
    },
    {
      title: "Location",
      width: "100px",
      dataIndex: "location",
      key: "location",
      render: (_text: string, row: any) => <div>{row.truck}</div>,
    },
    {
      title: "Notes",
      width: "200px",
      dataIndex: "notes",
      key: "notes",
      render: (_text: string, row: any) => <div>{row.appointment.notes}</div>,
    },
  ];

  return (
    <Container>
      <div>
        <Table columns={columns} dataSource={waitingPatients} pagination={false} bordered />
      </div>
    </Container>
  );
};

export default WaitingPatientsTable;

const Container = styled.div`
  margin-bottom: 50px;
`;
