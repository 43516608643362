import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import logger from "redux-logger";

import rootSaga from "./../../Infrastructure/Saga/index";

import { topbarReducer } from "./../../Features/Topbar/Topbar.reducer";
import spreadsheetReducer from "./../../Features/Tracker/Tracker.reducer";
import statisticsReducer from "./../../Features/Statistics/Statistics.reducer";
import authenticationReducer from "./../../Features/Authentication/Authentication.reducer";
import { staffReducer } from "./../../Features/Staff/Staff.reducer";
import calendarReducer from "./../../Features/Calendar/Calendar.reducer";
import patientLookupReducer from "./../../Features/PatientLookup/PatientLookup.reducer";
import notesReducer from "./../../Features/Notes/Notes.reducer";
import waitingRoomReducer from "./../../Features/WaitingRoom/WaitingRoom.reducer";
import salesforceAccountReducer from "./../../Features/SalesforceAccount/SalesforceAccount.reducer";

const rootReducer = combineReducers({
  authentication: authenticationReducer,
  topbar: topbarReducer,
  spreadsheet: spreadsheetReducer,
  statistics: statisticsReducer,
  staff: staffReducer,
  calendar: calendarReducer,
  patientLookup: patientLookupReducer,
  notes: notesReducer,
  waitingRoom: waitingRoomReducer,
  salesforceAccount: salesforceAccountReducer,
});

const sagaMiddleware = createSagaMiddleware();
// const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

const composeEnhancers =
  typeof window === "object" && window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]
    ? window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]({})
    : compose;

let enhancer = composeEnhancers(applyMiddleware(sagaMiddleware));

if (process.env.NODE_ENV === "local") {
  enhancer = composeEnhancers(applyMiddleware(sagaMiddleware, logger));
}

const store = createStore(rootReducer, enhancer);

sagaMiddleware.run(rootSaga);
export { store };
