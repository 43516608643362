import momentTimezone from "moment-timezone";

export const formatDate = (date: Date, timezone: string, format: string) => {
  return momentTimezone(date).tz(timezone).format(format);
};

export const COLORS = {
  green: "#b7e1cd",
  yellow: "#fce8b2",
  red: "#f4c7c3",
  red2: "#e74c3c",
  blue: "#a4c2f4",
  violet: "#b4a7d6",
  grey: "#f4f5f6",
  darkGrey: "#dfdfdf",
  white: "#ffffff",
  black: "#000000",
};
