export const formatStringField = (value: string | null) => {
  if (value === null) {
    return "-";
  }

  return value;
};

export const formatLogicField = (value: boolean) => {
  if (value) {
    return "Yes";
  }

  return "No";
};
