import { produce } from "immer";
import { IAction } from "./WaitingRoom.iterfaces";
import { SAVE_WAITING_ROOM_DATA, UPDATE_LOADING_STATUS_WAITING_ROOM } from "./WaitingRoom.constants";
import { ITracker } from "../Tracker/Tracker.interfaces";

export interface IState {
  isLoading: boolean;
  trackers: ITracker[];
}

const initialState: IState = {
  isLoading: false,
  trackers: [],
};

const waitingRoomReducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case UPDATE_LOADING_STATUS_WAITING_ROOM: {
      return produce(state, (draftState: IState) => {
        draftState.isLoading = action.payload as boolean;
      });
    }
    case SAVE_WAITING_ROOM_DATA: {
      const result = action.payload as ITracker[];

      return produce(state, (draftState: IState) => {
        draftState.trackers = result;
      });
    }
    default:
      return state;
  }
};

export default waitingRoomReducer;
