import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import styled from "styled-components";
import { Form, Divider, Button, Pagination, Empty } from "antd";
import { FormComponentProps } from "antd/es/form";

import { IStore } from "./../../Infrastructure/Store/store.interface";
import { ISearchResultsResponse, IAction, ISearchQuery } from "./../../Features/PatientLookup/PatientLookup.interfaces";

import { searchPatients } from "./../../Features/PatientLookup/PatientLookup.actions";

import Finder from "./../../Features/PatientLookup/components/Finder.component";
import PatientsList from "./../../Features/PatientLookup/components/PatientsList.component";

interface IProps extends FormComponentProps {
  isLoading: boolean;
  finder: ISearchResultsResponse;
  searchPatients: (payload: ISearchQuery) => IAction;
}

class PatientLookup extends Component<IProps> {
  public handlePageChange = (page: number) => {
    const { form, searchPatients } = this.props;
    const fields = form.getFieldsValue(["firstName", "lastName", "email", "phoneNumber", "birth"]);

    searchPatients({
      firstName: fields.firstName || "",
      lastName: fields.lastName || "",
      email: fields.email || "",
      phoneNumber: fields.phoneNumber || "",
      birth: fields.birth || "",
      limit: 25,
      offset: page - 1,
    });
  };

  public render() {
    const { form, isLoading, finder, searchPatients } = this.props;
    return (
      <>
        <Topbar>
          <TopbarMenu>
            <Link to="/">
              <Button icon="left">Back to Patient Tracker</Button>
            </Link>
          </TopbarMenu>
        </Topbar>
        <Container>
          <Finder form={form} searchPatients={searchPatients} isLoading={isLoading} />
          <Divider dashed />
          <PatientsList isLoading={isLoading} results={finder.result} />
          {finder.result.length === 0 && !isLoading && <Empty description="No results" />}
          {finder.total > 0 && (
            <Pagination
              current={finder.offset + 1}
              onChange={this.handlePageChange}
              pageSize={25}
              total={finder.total}
            />
          )}
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    finder: state.patientLookup.finder,
    isLoading: state.patientLookup.isLoading,
  };
};

const mapDispatchToProps = { searchPatients };

export default compose(connect(mapStateToProps, mapDispatchToProps), Form.create({}))(PatientLookup);

const Container = styled.div`
  width: 960px;
  margin: 0 auto 40px auto;
`;

const Topbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  margin-bottom: 15px;
  padding: 15px 10px;
  border-bottom: 1px solid #dddddd;
`;

const TopbarMenu = styled.div`
  width: 980px;
  margin: 0 auto;
`;
