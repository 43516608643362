import React from "react";
import { getDilationType } from "./../../../../Features/Tracker/Tracker.helpers";
import { Icon } from "antd";

const DilationTypeFormatter = (props: any) => {
  return (
    <span>
      {getDilationType(props.row.appointment.dilationType)!.name} <Icon style={{ fontSize: 10 }} type="down" />
    </span>
  );
};

export default DilationTypeFormatter;
