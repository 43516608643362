import React from "react";
import { Row } from "react-data-grid";

import CellRenderer from "./../../../../Features/Tracker/components/spreadsheet/CellRenderer.component";

const RowRenderer = (props: any) => {
  return <Row cellRenderer={CellRenderer} {...props} />;
};

export default RowRenderer;
