import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Form } from "antd";
import styled from "styled-components";
import { FormComponentProps } from "antd/es/form";

import { IStore } from "./../../../Infrastructure/Store/store.interface";

import DayOfIssueComponent from "../components/DayOfIssue.component";
import { IState as ISpreadsheetState } from "../../Tracker/Tracker.reducer";
import API from "../../../Infrastructure/API/index";

interface IProps extends FormComponentProps {
  spreadsheet: ISpreadsheetState;
}

interface IState {
  isUpdating: boolean;
}

class DayOfIssue extends Component<IProps, IState> {
  public state: IState = {
    isUpdating: false,
  };

  public updateIssueNotes = async () => {
    const { form, spreadsheet } = this.props;
    const { tracker } = spreadsheet;

    this.setState({ isUpdating: true });

    await API.updateTracker(tracker!.id, {
      name: tracker!.name,
      date: tracker!.date,
      locationId: tracker!.locationId,
      timezone: tracker!.timezone,
      parkingLocationDetails: tracker!.parkingLocationDetails,
      SFVisitId: tracker!.SFVisitId,
      notes: tracker!.notes,
      trackingNumber: tracker!.trackingNumber,
      issueNotes: form.getFieldValue("issueNotes"),
    });

    this.setState({ isUpdating: false });
  };

  public render() {
    const { form, spreadsheet } = this.props;
    return (
      <Container>
        <DayOfIssueComponent
          form={form}
          notes={spreadsheet.tracker?.issueNotes}
          isUpdating={this.state.isUpdating}
          updateIssueNotes={this.updateIssueNotes}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    spreadsheet: state.spreadsheet,
  };
};

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), Form.create({}))(DayOfIssue);

const Container = styled.div`
  margin-bottom: 20px;
`;
