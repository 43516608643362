import React, { Component } from "react";
import Config from "./../../config/config";

class Authentication extends Component<{}> {
  public componentDidMount = () => {
    this.redirectToAuthenticationApp();
  };

  public redirectToAuthenticationApp = () => {
    location.href = `${Config.get("AUTHENTICATION_APP_URL")}/?target=${Config.get("PATIENT_TRACKER_URL")}`;
  };

  public render() {
    return null;
  }
}

export default Authentication;
