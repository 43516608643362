import React, { useEffect, useState } from "react";
import { Input, Button, Card, Form, Checkbox } from "antd";
import styled from "styled-components";

interface IProps {
  form: any;
  notes: string | undefined;
  isUpdating: boolean;
  updateIssueNotes: () => void;
}

const DayOfIssue = (props: IProps) => {
  const { form, notes, isUpdating, updateIssueNotes } = props;
  const [dayHadIssue, updateDayHadIssue] = useState(false);

  useEffect(() => {
    updateDayHadIssue(!notes ? false : true);
  }, [notes]);

  return (
    <Card size="small" title="Issue">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          updateIssueNotes();
        }}>
        <Checkbox style={{ marginBottom: 10 }} checked={dayHadIssue} onChange={() => updateDayHadIssue(!dayHadIssue)}>
          Day-of issue
        </Checkbox>
        {dayHadIssue && (
          <>
            <Form.Item>
              {form.getFieldDecorator("issueNotes", {
                initialValue: notes,
                rules: [
                  {
                    required: false,
                    message: "Please input issue notes!",
                  },
                ],
              })(<Input.TextArea rows={4} />)}
            </Form.Item>
            <Form.Item style={{ marginBottom: "10px" }}>
              <ButtonContainer>
                <Button type="primary" htmlType="submit" loading={isUpdating}>
                  Save
                </Button>
              </ButtonContainer>
            </Form.Item>
          </>
        )}
      </Form>
    </Card>
  );
};

export default DayOfIssue;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
