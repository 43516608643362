import styled from "styled-components";

export const ElementContainer = styled.div`
  margin-bottom: 25px;
`;

export const Label = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 5px;
  padding-left: 5px;
`;

export const DateHeading = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const EmptyList = styled.div`
  text-align: center;
  margin: 15px 0;
  font-style: italic;
  font-size: 12px;
`;
