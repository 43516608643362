import React from "react";
import io from "socket.io-client";
import { connect } from "react-redux";
import { message } from "antd";
import Config from "../../../config/config";

import { IStore } from "./../../../Infrastructure/Store/store.interface";
import { refreshTrackerData, IAction as ISpreadsheetAction } from "./../../../Features/Tracker/Tracker.actions";
import { IState as ITopbarState } from "../../Topbar/Topbar.reducer";
import { IState as ITrackerState } from "../../Tracker/Tracker.reducer";

interface IProps {
  topbar: ITopbarState;
  spreadsheet: ITrackerState;
  refreshTrackerData: (id: string) => ISpreadsheetAction;
}

class Socket extends React.Component<IProps> {
  public socket: any;

  public componentDidUpdate = async (prevProps: IProps) => {
    const { topbar } = this.props;

    if (topbar.selectedTrackerId !== null && topbar.selectedTrackerId !== prevProps.topbar.selectedTrackerId) {
      if (this.socket) {
        await this.socket.disconnect();
      }

      await this.connectToSocket();
    }
  };

  public getPatientsIds = () => {
    const { spreadsheet } = this.props;
    const ids: string[] = [];

    if (spreadsheet.tracker) {
      spreadsheet.tracker.timeslots.forEach((timeslot) => {
        timeslot.appointments.forEach((appointment) => {
          ids.push(appointment.patientId);
        });
      });
    }

    return ids;
  };

  public connectToSocket = () => {
    const { topbar, refreshTrackerData } = this.props;
    this.socket = io(`${Config.get("API_URL")}/trackers`);
    this.socket.on("connect", () => {
      console.log(`Connected (SOCKET) --> TRACKER #${topbar.selectedTrackerId}`);
    });
    this.socket.on("disconnect", () => {
      console.log(`Disconnected (SOCKET) --> TRACKER #${topbar.selectedTrackerId}`);
    });
    this.socket.on("update", (data: { visitId?: string; patientId?: string }) => {
      const { visitId, patientId } = data;
      if (visitId && visitId === topbar.selectedTrackerId) {
        refreshTrackerData(topbar.selectedTrackerId);
        this.sendNotification("Tracker updated");
      }
      if (patientId && this.getPatientsIds().includes(patientId)) {
        refreshTrackerData(topbar.selectedTrackerId);
        this.sendNotification("Tracker updated");
      }
    });
  };

  private sendNotification(messageText: string) {
    const text = this.splitCamelCase(messageText);
    message.success(text);
  }

  private splitCamelCase = (text: string) => text.replace(/([a-z])([A-Z])/g, "$1 $2");

  public render() {
    return null;
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    topbar: state.topbar,
    spreadsheet: state.spreadsheet,
  };
};

const mapDispatchToProps = {
  refreshTrackerData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Socket);
