import produce from "immer";
import { IState, IAction } from "./../../Features/Notes/Notes.interfaces";
import {
  SAVE_VISIT_NOTES,
  UPDATE_FIELD,
  SAVE_TRACKING_NUMBER,
  SAVE_SITE_NOTES,
  SAVE_ISSUE_NOTES,
} from "./../../Features/Notes/Notes.actions";

const initialState: IState = {
  visitNotes: "",
  siteNotes: "",
  issueNotes: "",
  trackingNumber: "",
};

const notesReducer = (state = initialState, action: IAction): IState => {
  switch (action.type) {
    case SAVE_VISIT_NOTES:
      const visitNotes = action.payload as string;
      return produce(state, (draftState: IState) => {
        draftState.visitNotes = visitNotes;
      });

    case SAVE_SITE_NOTES:
      const siteNotes = action.payload as string;
      return produce(state, (draftState: IState) => {
        draftState.siteNotes = siteNotes;
      });

    case SAVE_ISSUE_NOTES:
      const issueNotes = action.payload as string;
      return produce(state, (draftState: IState) => {
        draftState.issueNotes = issueNotes;
      });

    case SAVE_TRACKING_NUMBER:
      const trackingNumber = action.payload as string;
      return produce(state, (draftState: IState) => {
        draftState.trackingNumber = trackingNumber;
      });

    case UPDATE_FIELD: {
      const { property, value } = action.payload as { property: string; value: string };
      return produce(state, (draftState: IState) => {
        draftState[property] = value;
      });
    }

    default:
      return state;
  }
};

export default notesReducer;
