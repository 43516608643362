import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { DatePicker, Empty, Dropdown, Menu, Icon, Checkbox } from "antd";
import moment from "moment";
const { MonthPicker } = DatePicker;

import { IStore } from "./../../Infrastructure/Store/store.interface";
import { IWeek } from "./../../Features/Calendar/Calendar.interfaces";
import { getCalendarTrackers, updateMonth } from "./../../Features/Calendar/Calendar.actions";
import Header from "./../../Features/Calendar/components/Header.component";
import { Calendar as CalendarComponent } from "./../../Features/Calendar/components/Calendar.component";

interface IProps {
  selectedMonth: Date;
  weeks: IWeek[];
  getCalendarTrackers: (date: Date) => { type: string; payload: Date };
  updateMonth: (date: Date) => { type: string; payload: Date };
}

interface IState {
  showHoldReservations: boolean;
}

export class Calendar extends Component<IProps, IState> {
  public state: IState = {
    showHoldReservations: true,
  };

  public componentDidMount = () => {
    const { selectedMonth, getCalendarTrackers } = this.props;
    getCalendarTrackers(selectedMonth);
  };

  public componentDidUpdate = (prevProps: IProps) => {
    const { selectedMonth, getCalendarTrackers } = this.props;
    if (prevProps.selectedMonth !== selectedMonth) {
      getCalendarTrackers(selectedMonth);
    }
  };

  public updateMonth = (date: any) => {
    const formattedDate = date.set({ hour: "18" }).toDate();
    this.props.updateMonth(formattedDate);
  };

  public updateVisibilityOfHoldReservations = () => {
    this.setState({
      showHoldReservations: !this.state.showHoldReservations,
    });
  };

  public render() {
    const { selectedMonth, weeks } = this.props;
    const { showHoldReservations } = this.state;
    const menu = (
      <Menu>
        <Menu.Item key="1">
          <Link to="/">Patient Tracker</Link>
        </Menu.Item>
      </Menu>
    );
    return (
      <>
        <DatepickerContainer>
          <MonthPicker placeholder="Select Month" onChange={this.updateMonth} />
          <DropdownStyled overlay={menu} trigger={["click"]}>
            <a className="ant-dropdown-link" style={{ color: "#1890FF" }} href="#">
              Calendar <Icon type="down" />
            </a>
          </DropdownStyled>
        </DatepickerContainer>
        {weeks.length > 0 ? (
          <>
            <FiltersContainer>
              <Checkbox checked={showHoldReservations} onChange={this.updateVisibilityOfHoldReservations}>
                Show hold reservations
              </Checkbox>
            </FiltersContainer>
            <CalendarContainer>
              <Header content={moment(selectedMonth).format("MMMM YYYY")} />
              <CalendarComponent
                weeks={weeks}
                selectedMonth={selectedMonth}
                showHoldReservations={showHoldReservations}
              />
            </CalendarContainer>
          </>
        ) : (
          <Empty description="Reservations list is empty. Please select another date." />
        )}
      </>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    selectedMonth: state.calendar.selectedMonth,
    weeks: state.calendar.weeks,
  };
};

const mapDispatchToProps = { getCalendarTrackers, updateMonth };

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);

const CalendarContainer = styled.div`
  padding: 10px;
`;

const DatepickerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  margin-bottom: 15px;
  padding: 15px 10px;
  border-bottom: 1px solid #dddddd;
`;

const DropdownStyled = styled(Dropdown)`
  margin-right: 10px;
`;

const FiltersContainer = styled.div`
  margin-left: 10px;
`;
