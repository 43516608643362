import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { Card, Icon } from "antd";

import { IStore } from "./../../Infrastructure/Store/store.interface";
import { IState as IStatisticsState } from "./../../Features/Statistics/Statistics.reducer";
import { IState as ISpreadsheetState } from "./../../Features/Tracker/Tracker.reducer";
import StatisticsTables from "./../../Features/Statistics/components/StatisticsTables.component";
import { getTrackerStatistics } from "./../../Features/Statistics/Statistics.actions";
import { Actions } from "./../../Features/Statistics/Statistics.interfaces";

interface IProps {
  spreadsheet: ISpreadsheetState;
  statistics: IStatisticsState;
  getTrackerStatistics: (trackerId: string) => Actions;
}

interface IState {
  isDataRefreshing: boolean;
}

export class Statistics extends React.Component<IProps, IState> {
  public state = {
    isDataRefreshing: false,
  };

  public componentDidMount = () => {
    const { spreadsheet, getTrackerStatistics } = this.props;

    if (spreadsheet.tracker && spreadsheet.tracker.id) {
      getTrackerStatistics(spreadsheet.tracker.id);
    }
  };

  public componentDidUpdate = (prevProps: IProps) => {
    const { spreadsheet, getTrackerStatistics } = this.props;

    if (spreadsheet.tracker && spreadsheet.tracker !== prevProps.spreadsheet.tracker) {
      getTrackerStatistics(spreadsheet.tracker.id);
    }
  };

  public render() {
    const { spreadsheet, statistics, getTrackerStatistics } = this.props;

    return (
      <Container>
        <Card
          size="small"
          title="Statistics"
          extra={
            <Icon
              type="reload"
              style={{ fontSize: 18, cursor: "pointer" }}
              spin={this.state.isDataRefreshing}
              onClick={() => {
                this.setState({ isDataRefreshing: true });
                getTrackerStatistics(spreadsheet.tracker!.id);

                setTimeout(() => this.setState({ isDataRefreshing: false }), 2000);
              }}
            />
          }>
          <StatisticsTables statistics={statistics} />
        </Card>
      </Container>
    );
  }
}
const mapStateToProps = (state: IStore) => {
  return {
    spreadsheet: state.spreadsheet,
    statistics: state.statistics,
  };
};

const mapDispatchToProps = {
  getTrackerStatistics,
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);

const Container = styled.div``;
