import React from "react";
import { getType } from "./../../../../Features/Tracker/Tracker.helpers";
import { Icon } from "antd";

const ExamTypeFormatter = (props: any) => {
  return (
    <span>
      {getType(props.row.appointment.type)!.name} <Icon style={{ fontSize: 10 }} type="down" />
    </span>
  );
};

export default ExamTypeFormatter;
