export const GET_VISIT_NOTES = "GET_VISIT_NOTES";
export const SAVE_VISIT_NOTES = "SAVE_VISIT_NOTES";
export const UPDATE_VISIT_NOTES = "UPDATE_VISIT_NOTES";

export const GET_SITE_NOTES = "GET_SITE_NOTES";
export const SAVE_SITE_NOTES = "SAVE_SITE_NOTES";
export const UPDATE_SITE_NOTES = "UPDATE_SITE_NOTES";

export const GET_ISSUE_NOTES = "GET_ISSUE_NOTES";
export const SAVE_ISSUE_NOTES = "SAVE_ISSUE_NOTES";
export const UPDATE_ISSUE_NOTES = "UPDATE_ISSUE_NOTES";

export const GET_TRACKING_NUMBER = "GET_TRACKING_NUMBER";
export const SAVE_TRACKING_NUMBER = "SAVE_TRACKING_NUMBER";
export const UPDATE_TRACKING_NUMBER = "UPDATE_TRACKING_NUMBER";

export const UPDATE_FIELD = "UPDATE_FIELD";

export const SYNCHRONIZE_TRACKER = "SYNCHRONIZE_TRACKER";

export const getVisitNotes = (trackerId: number) => {
  return {
    type: GET_VISIT_NOTES,
    payload: trackerId,
  };
};

export const saveVisitNotes = (notes: string) => {
  return {
    type: SAVE_VISIT_NOTES,
    payload: notes,
  };
};

export const updateVisitNotes = (payload: { trackerId: number; notes: string }) => {
  return {
    type: UPDATE_VISIT_NOTES,
    payload,
  };
};

export const getSiteNotes = (trackerId: number) => {
  return {
    type: GET_SITE_NOTES,
    payload: trackerId,
  };
};

export const saveSiteNotes = (notes: string) => {
  return {
    type: SAVE_SITE_NOTES,
    payload: notes,
  };
};

export const updateSiteNotes = (payload: { trackerId: number; notes: string }) => {
  return {
    type: UPDATE_SITE_NOTES,
    payload,
  };
};

export const getIssueNotes = (trackerId: number) => {
  return {
    type: GET_ISSUE_NOTES,
    payload: trackerId,
  };
};

export const saveIssueNotes = (notes: string) => {
  return {
    type: SAVE_ISSUE_NOTES,
    payload: notes,
  };
};

export const updateIssueNotes = (payload: { trackerId: number; notes: string }) => {
  return {
    type: UPDATE_ISSUE_NOTES,
    payload,
  };
};

export const getTrackingNumber = (trackerId: number) => {
  return {
    type: GET_TRACKING_NUMBER,
    payload: trackerId,
  };
};

export const saveTrackingNumber = (trackingNumber: string) => {
  return {
    type: SAVE_TRACKING_NUMBER,
    payload: trackingNumber,
  };
};

export const updateTrackingNumber = (payload: { trackerId: number; trackingNumber: string }) => {
  return {
    type: UPDATE_TRACKING_NUMBER,
    payload,
  };
};

export const updateField = (payload: { property: string; value: string }) => {
  return {
    type: UPDATE_FIELD,
    payload,
  };
};

export const synchronizeTracker = (payload: { trackerId: string }) => {
  return {
    type: SYNCHRONIZE_TRACKER,
    payload,
  };
};
