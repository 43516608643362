import { ISearchResultsResponse, ISearchQuery } from "./../../Features/PatientLookup/PatientLookup.interfaces";
import { SAVE_SEARCH_RESULT, SEARCH_PATIENTS, UPDATE_SEARCH_LOADING_STATUS } from "./PatientLookup.constants";

export const searchPatients = (payload: ISearchQuery) => {
  return {
    type: SEARCH_PATIENTS,
    payload,
  };
};

export const saveSearchResult = (payload: ISearchResultsResponse) => {
  return {
    type: SAVE_SEARCH_RESULT,
    payload,
  };
};

export const updateLoadingStatus = (payload: boolean) => {
  return {
    type: UPDATE_SEARCH_LOADING_STATUS,
    payload,
  };
};
