import React from "react";
import { Row, Col, Input, Button, Card, Form } from "antd";
import styled from "styled-components";

interface IProps {
  form: any;
  notes: string | undefined;
  isUpdating: boolean;
  updateVisitNotes: () => void;
}

const VisitNotes = (props: IProps) => {
  const { notes, updateVisitNotes, form, isUpdating } = props;
  const { getFieldDecorator } = form;

  return (
    <Row>
      <Col span={24}>
        <Card size="small" title="Visit notes">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              updateVisitNotes();
            }}>
            <Form.Item style={{ marginBottom: "10px" }}>
              {getFieldDecorator("visitNotes", {
                initialValue: notes,
                rules: [{ required: false, message: "Please input visit notes!" }],
              })(<Input.TextArea rows={4} />)}
            </Form.Item>
            <Form.Item style={{ marginBottom: "10px" }}>
              <ButtonContainer>
                <Button type="primary" htmlType="submit" loading={isUpdating}>
                  Save
                </Button>
              </ButtonContainer>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default VisitNotes;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
