import React from "react";
import { compose } from "redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import { Spin, message, Alert } from "antd";
import moment from "moment-timezone";
import { orderBy } from "lodash";

import API from "./../../Infrastructure/API/index";
import TrackerSpreadsheet from "./../../Features/Tracker/components/TrackerSpreadsheet.component";
import TimepickerModal from "./../../Features/Tracker/components/timepicker/TimepickerModal.component";
import Legend from "./../../Features/Tracker/components/spreadsheet/Legend.component";

import { IStore } from "./../../Infrastructure/Store/store.interface";
import {
  getTrackerData,
  clearTrackerData,
  getInsuranceProviders,
  getAnotherTrackers,
  updateAppointment,
  IUpdateAppointment,
  updatePatient,
  IUpdatePatient,
  addPatientToWaitlist,
} from "./../../Features/Tracker/Tracker.actions";

import { IState as ITopbarState } from "../Topbar/Topbar.reducer";
import { IState as ISpreadsheetState } from "./Tracker.reducer";
import { IAction as ISpreadsheetAction } from "./Tracker.actions";
import { IAddPatientToWaitlist, ISelectedAppointmentForRescheduling } from "src/Features/Tracker/Tracker.interfaces";

interface IProps extends RouteComponentProps {
  topbar: ITopbarState;
  spreadsheet: ISpreadsheetState;

  getTrackerData: (trackerId: string) => ISpreadsheetAction;
  getInsuranceProviders: () => ISpreadsheetAction;
  getAnotherTrackers: () => ISpreadsheetAction;
  clearTrackerData: () => ISpreadsheetAction;
  updateAppointment: (payload: IUpdateAppointment) => ISpreadsheetAction;
  updatePatient: (payload: IUpdatePatient) => ISpreadsheetAction;
  addPatientToWaitlist: (payload: IAddPatientToWaitlist) => ISpreadsheetAction;
}

interface IState {
  isTimepickerModalVisible: boolean;
  selectedTimeslot: ISelectedAppointmentForRescheduling | null;
}

export class Tracker extends React.Component<IProps, IState> {
  public state: IState = {
    isTimepickerModalVisible: false,
    selectedTimeslot: null,
  };

  public componentDidMount = () => {
    const { getInsuranceProviders } = this.props;
    getInsuranceProviders();
  };

  public componentDidUpdate = (prevProps: IProps) => {
    const { topbar, getTrackerData, clearTrackerData } = this.props;
    if (topbar.selectedTrackerId !== prevProps.topbar.selectedTrackerId) {
      if (topbar.selectedTrackerId) getTrackerData(topbar.selectedTrackerId);
    }

    if (topbar.selectedDate !== prevProps.topbar.selectedDate) {
      clearTrackerData();
    }
  };

  public selectNewTimeslot = (payload: { trackerId: string; timeslotId: string }) => {
    this.setState({
      selectedTimeslot: {
        ...this.state.selectedTimeslot,
        target: payload,
      },
    });
  };

  public toggleTimepickerModal = (row: ISelectedAppointmentForRescheduling | null) => {
    const { isTimepickerModalVisible } = this.state;
    const { getAnotherTrackers } = this.props;
    this.setState({
      isTimepickerModalVisible: !isTimepickerModalVisible,
      selectedTimeslot: isTimepickerModalVisible ? null : row,
    });

    if (!isTimepickerModalVisible) {
      getAnotherTrackers();
    }
  };

  public rescheduleAppointment = async () => {
    try {
      const { selectedTimeslot } = this.state;
      await API.rescheduleAppointment(selectedTimeslot!.trackerId, selectedTimeslot!.appointment!.id, {
        targetVisitId: selectedTimeslot!.target!.trackerId,
        targetTimeslotId: selectedTimeslot!.target!.timeslotId,
        timeslotId: selectedTimeslot!.id,
      });
      await this.toggleTimepickerModal(null);
    } catch (error) {
      message.error("Sorry, something went wrong");
    }
  };

  public linkify = (str: string) => {
    const urlRegex = /(?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$])/gim;
    const urls = str.match(urlRegex);
    const nonUrls = str.split(urlRegex);
    let separatedStrings: any[] = [];
    if (urls) {
      for (let i = 0; i < urls.length; i++) {
        separatedStrings.push(nonUrls[i], <a href={urls[i]}>{urls[i]}</a>);
      }
    } else {
      separatedStrings.push(str);
    }

    return (
      <span>
        {separatedStrings.map((fragment) => {
          return <span>{fragment}</span>;
        })}
      </span>
    );
  };

  public render() {
    const { isTimepickerModalVisible, selectedTimeslot } = this.state;
    const { history, topbar, spreadsheet, updateAppointment, updatePatient, addPatientToWaitlist } = this.props;
    const orderedTimeslots = spreadsheet.tracker ? orderBy(spreadsheet.tracker.timeslots, "startDate", "asc") : [];

    return (
      <Container>
        {!spreadsheet.isLoading.tracker ? (
          spreadsheet.tracker !== null ? (
            <>
              {spreadsheet.tracker.status.includes("inactive") && (
                <Alert
                  message="Inactive visit"
                  description={
                    <>
                      <strong>{spreadsheet.tracker.name}</strong> is currently <strong>inactive</strong>. When you are
                      ready to add appointments to this visit, please change the status to <strong>active</strong>.
                    </>
                  }
                  type="warning"
                  style={{
                    marginBottom: 20,
                  }}
                />
              )}

              {spreadsheet.tracker.status.includes("cancelled") && (
                <Alert
                  message="Cancelled visit"
                  description={
                    <>
                      <strong>{spreadsheet.tracker.name}</strong> is <strong>cancelled</strong>.
                    </>
                  }
                  type="error"
                  style={{
                    marginBottom: 20,
                  }}
                />
              )}

              <div>
                <strong>Location: </strong> {this.linkify(spreadsheet.tracker.parkingLocationDetails)}
              </div>
              {orderedTimeslots.length > 0 && (
                <div>
                  <strong>Hours:</strong>{" "}
                  {moment(orderedTimeslots[0].startDate).tz(spreadsheet.tracker.timezone).format("LT")} -{" "}
                  {moment(orderedTimeslots[orderedTimeslots.length - 1].endDate)
                    .tz(spreadsheet.tracker.timezone)
                    .format("LT")}
                </div>
              )}
              <TrackerSpreadsheet
                history={history}
                topbar={topbar}
                spreadsheet={spreadsheet}
                toggleTimepickerModal={this.toggleTimepickerModal}
                updateAppointment={updateAppointment}
                updatePatient={updatePatient}
                addPatientToWaitlist={addPatientToWaitlist}
              />
              {/*<Legend />*/}
            </>
          ) : (
            <>
              <Alert
                message="Select tracker"
                style={{
                  maxWidth: 700,
                  margin: "0 auto",
                }}
                type="info"
                description="There is no tracker selected. Please select the day and tracker you would like to work with using the form above."
                showIcon
              />
            </>
          )
        ) : (
          <SpinContainer>
            <Spin size="large" />
          </SpinContainer>
        )}
        <TimepickerModal
          selectedTimeslot={selectedTimeslot}
          isTimepickerModalVisible={isTimepickerModalVisible}
          spreadsheet={spreadsheet}
          selectNewTimeslot={this.selectNewTimeslot}
          toggleTimepickerModal={this.toggleTimepickerModal}
          rescheduleAppointment={this.rescheduleAppointment}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    topbar: state.topbar,
    spreadsheet: state.spreadsheet,
  };
};

const mapDispatchToProps = {
  getTrackerData,
  clearTrackerData,
  getInsuranceProviders,
  getAnotherTrackers,
  updateAppointment,
  updatePatient,
  addPatientToWaitlist,
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Tracker);

const Container = styled.div`
  width: 100%;
  padding: 0px 10px;
`;

const SpinContainer = styled.div`
  text-align: center;
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
`;
