import React, { Component } from "react";
import styled from "styled-components";
import { Button, Select, Tag } from "antd";
import { IEmployee } from "./../../../Features/Staff/Staff.interfaces";

interface IProps {
  color: string;
  position: { i: number; name: string };
  staff: IEmployee[];
  trackerEmployee: { id: string; position: string } | undefined;
  selectPerson: (data: { uuid: string; position: { i: number; name: string } }) => void;
  removeEmployee: (userId: string) => void;
}

class TableRow extends Component<IProps> {
  private getReadableUser = (userId: string) => {
    const user = this.props.staff.find((s) => s.id === userId);
    if (!user) return "-";
    return `${user.firstName} ${user.lastName} ${user.position ? `[${user.position}]` : ""}`;
  };

  private removeEmployee = (userId: string) => {
    this.props.removeEmployee(userId);
  };

  public render() {
    const { staff, trackerEmployee, color, selectPerson, position } = this.props;

    return (
      <tr>
        <td className="label" style={{ verticalAlign: "baseline", paddingTop: 17 }}>
          <TagStyled color={color} style={{ display: 'flex', justifyContent: 'center' }}>{position.name}</TagStyled>
        </td>
        <td>
          {trackerEmployee ? (
            <React.Fragment>
              <EmployeeStyled>
                <strong>{this.getReadableUser(trackerEmployee.id)}</strong>
                <Button
                  type="danger"
                  size="small"
                  icon="minus"
                  onClick={() => this.removeEmployee(trackerEmployee.id)}
                />
              </EmployeeStyled>
            </React.Fragment>
          ) : (
            <EmployeeStyled>
              <SelectStyled
                placeholder="Select a user"
                size="small"
                onChange={(value) => {
                  selectPerson({
                    uuid: value,
                    position,
                  });
                }}>
                {staff
                  .filter((user) => {
                    if (position.name === "EXTRA" && user.position) {
                      return true;
                    }
                    return user.position === position.name;
                  })
                  .map((user) => (
                    <Select.Option key={user.id} value={user.id}>
                      {this.getReadableUser(user.id)}
                    </Select.Option>
                  ))}
              </SelectStyled>
            </EmployeeStyled>
          )}
        </td>
      </tr>
    );
  }
}

export default TableRow;

export const TagStyled = styled(Tag)`
  width: 50px;
`;

const SelectStyled = styled(Select)`
  width: 100%;
`;

export const EmployeeStyled = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;

  span {
    font-weight: bold;
  }

  .ant-btn {
    padding: 0 4px !important;
    margin-left: 10px;
  }
`;
