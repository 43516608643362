import React, { useState } from "react";
import { Row, Col, Input, Button, Card, Form } from "antd";
import styled from "styled-components";

import { IAction } from "./../../../Features/Notes/Notes.interfaces";

interface IProps {
  form: any;
  trackingNumber: string | undefined;
  trackerId: string | undefined;
  isUpdating: boolean;
  updateTrackingNumber: () => void;
  synchronizeTracker: (payload: { trackerId: string }) => IAction;
}

const TrackingNumber = (props: IProps) => {
  const [isSynchronizing, updateSynchronizingState] = useState(false);
  const { form, trackerId, trackingNumber, isUpdating, synchronizeTracker, updateTrackingNumber } = props;
  const { getFieldDecorator } = form;

  const updateSyncButton = async () => {
    updateSynchronizingState(true);
    synchronizeTracker({ trackerId });
    setTimeout(() => updateSynchronizingState(false), 1000);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Card size="small" title="Tracking number">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                updateTrackingNumber();
              }}>
              <Form.Item style={{ marginBottom: "10px" }}>
                {getFieldDecorator("trackingNumber", {
                  initialValue: trackingNumber,
                  rules: [
                    {
                      required: false,
                      message: "Please input tracking number!",
                    },
                  ],
                })(<Input placeholder="e.g. 1263172368761823141" />)}
              </Form.Item>
              <Form.Item style={{ marginBottom: "10px" }}>
                <ButtonContainer>
                  <Button type="primary" htmlType="submit" loading={isUpdating}>
                    Save
                  </Button>
                </ButtonContainer>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px" }}>
        <Col span={24}>
          <Button type="default" loading={isSynchronizing} onClick={() => updateSyncButton()}>
            Sync with Salesforce
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default TrackingNumber;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
