import produce from "immer";
import { IAvailableTracker } from "./../../Features/Topbar/Topbar.interfaces";

import { IAction } from "./Topbar.actions";
import {
  CLEAR_AVAILABLE_TRACKERS,
  CLEAR_SELECTED_TRACKER,
  SAVE_TRACKERS,
  SELECT_TRACKER,
  UPDATE_DATEPICKER,
} from "../Topbar/Topbar.constants";
import moment from "moment-timezone";

export interface IState {
  availableTrackers: IAvailableTracker[];
  selectedDate: Date;
  selectedTrackerId: string;
}

const initialState: IState = {
  availableTrackers: [],
  selectedDate: moment().toDate(),
  selectedTrackerId: "",
};

export const topbarReducer = (state: IState = initialState, action: IAction): IState => {
  switch (action.type) {
    case UPDATE_DATEPICKER:
      return { ...state, selectedDate: action.payload as Date };
    case SAVE_TRACKERS:
      return {
        ...state,
        availableTrackers: action.payload as IAvailableTracker[],
      };
    case SELECT_TRACKER:
      const trackerId = action.payload as string;
      return produce(state, (draftState: IState) => {
        draftState.selectedTrackerId = trackerId;
      });
    case CLEAR_SELECTED_TRACKER:
      return produce(state, (draftState: IState) => {
        draftState.selectedTrackerId = "";
      });
    case CLEAR_AVAILABLE_TRACKERS:
      return produce(state, (draftState: IState) => {
        draftState.availableTrackers = [];
      });
    default:
      return state;
  }
};
