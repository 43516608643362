import { produce } from "immer";
import { ISearchResultsResponse, IAction, IState } from "./../../Features/PatientLookup/PatientLookup.interfaces";
import {
  SAVE_SEARCH_RESULT,
  UPDATE_SEARCH_LOADING_STATUS,
} from "./../../Features/PatientLookup/PatientLookup.constants";

const initialState: IState = {
  finder: {
    total: 0,
    limit: 0,
    offset: 0,
    result: [],
  },
  isLoading: false,
};

const patientLookupReducer = (state = initialState, action: IAction): IState => {
  switch (action.type) {
    case SAVE_SEARCH_RESULT: {
      const result = action.payload as ISearchResultsResponse;
      return produce(state, (draftState: IState) => {
        draftState.finder = result;
      });
    }

    case UPDATE_SEARCH_LOADING_STATUS: {
      return produce(state, (draftState: IState) => {
        draftState.isLoading = action.payload as boolean;
      });
    }

    default:
      return state;
  }
};

export default patientLookupReducer;
