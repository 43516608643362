import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Col, Collapse, Row } from "antd";

import Topbar from "../Features/Topbar/Topbar.container";
import Tracker from "../Features/Tracker/Tracker.container";
import Statistics from "../Features/Statistics/Statistics.container";
import VisitNotes from "../Features/Notes/containers/VisitNotes.container";
import DayOfIssue from "../Features/Notes/containers/DayOfIssue.container";
import TrackingNumber from "../Features/Notes/containers/TrackingNumber.container";
import Staff from "../Features/Staff/Staff.container";
import InsuranceInfo from "../Features/SalesforceAccount/containers/InsuranceInfo.container";
import SiteInfo from "../Features/SalesforceAccount/containers/SiteInfo.container";
import ContactInfo from "../Features/SalesforceAccount/containers/ContactInfo.container";
import { IStore } from "../Infrastructure/Store/store.interface";
import { IState as ISpreadsheetState } from "../Features/Tracker/Tracker.reducer";
import OrderedProducts from "../Features/OrderedProducts/OrderedProducts.container";

interface IProps {
  spreadsheet: ISpreadsheetState;
}

class TrackerInfo extends React.Component<IProps> {
  render() {
    const { spreadsheet } = this.props;
    return (
      <div style={{ marginBottom: "40px" }}>
        <Topbar />
        <Tracker />

        {!spreadsheet.isLoading.tracker && spreadsheet.tracker && (
          <>
            <Row gutter={16}>
              <Col className="gutter-row" span={24}>
                <Collapse style={{ margin: "20px 10px 0 10px" }}>
                  <Collapse.Panel header="Visit summary" key="1">
                    <Row gutter={16}>
                      <Col className="gutter-row" span={14}>
                        <Statistics />
                      </Col>
                      <Col className="gutter-row" span={8}>
                        <VisitNotes />
                        <DayOfIssue />
                        <TrackingNumber />
                      </Col>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Collapse style={{ margin: "20px 10px 0 10px" }}>
                  <Collapse.Panel header="Ordered products" key="1">
                    <OrderedProducts />
                  </Collapse.Panel>
                </Collapse>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col className="gutter-row" span={24}>
                <Collapse style={{ margin: "20px 10px 0 10px" }}>
                  <Collapse.Panel header="Staff" key="1">
                    <Row gutter={16}>
                      <Col className="gutter-row" span={10}>
                        <Staff />
                      </Col>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col className="gutter-row" span={24}>
                <Collapse style={{ margin: "20px 10px 0 10px" }}>
                  <Collapse.Panel header="Insurance Info" key="1">
                    <Row gutter={16}>
                      <Col className="gutter-row" span={24}>
                        <InsuranceInfo />
                      </Col>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col className="gutter-row" span={24}>
                <Collapse style={{ margin: "20px 10px 0 10px" }}>
                  <Collapse.Panel header="Site Info" key="1">
                    <Row gutter={16}>
                      <Col className="gutter-row" span={24}>
                        <SiteInfo />
                      </Col>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col className="gutter-row" span={24}>
                <Collapse style={{ margin: "20px 10px 0 10px" }}>
                  <Collapse.Panel header="Contact Info" key="1">
                    <Row gutter={16}>
                      <Col className="gutter-row" span={24}>
                        <ContactInfo />
                      </Col>
                    </Row>
                  </Collapse.Panel>
                </Collapse>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    spreadsheet: state.spreadsheet,
  };
};

export default compose(connect(mapStateToProps))(TrackerInfo);
