import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button, message, Popover } from "antd";

interface IProps {
  row: any;
  field: string;
}

class PopoverMenu extends React.Component<IProps> {
  public returnFieldValue = () => {
    const { field } = this.props;
    const { row } = this.props.row;
    switch (field) {
      case "name": {
        return row.appointment ? `${row.appointment.patient.lastName}, ${row.appointment.patient.firstName}` : "";
      }
      case "ehrId": {
        return `-`;
      }
      default: {
        return "";
      }
    }
  };

  public redirectToEditPatient = () => {
    const { row } = this.props.row;
    window.top.postMessage(
      JSON.stringify({
        action: "redirectToEditPatient",
        payload: {
          patientId: row.appointment.patient.id,
          trackerId: row.trackerId,
        },
      }),
      "*"
    );
  };

  public redirectToEditQuestionnaire = () => {
    const { row } = this.props.row;
    window.top.postMessage(
      JSON.stringify({
        action: "redirectToEditQuestionnaire",
        payload: {
          patientId: row.appointment.patient.id,
          trackerId: row.trackerId,
        },
      }),
      "*"
    );
  };

  public render() {
    const { row } = this.props.row;

    const fieldValue = this.returnFieldValue();

    const content = (
      <>
        <CopyToClipboard
          style={{ cursor: "pointer", marginBottom: "6px" }}
          text={row.appointment ? `${row.appointment.patient.lastName}, ${row.appointment.patient.firstName}` : ""}
          onCopy={() =>
            message.info(
              <>
                <strong>
                  {row.appointment ? `${row.appointment.patient.lastName}, ${row.appointment.patient.firstName}` : ""}
                </strong>{" "}
                copied to clipboard
              </>
            )
          }>
          <Button size="small" block>
            Copy name
          </Button>
        </CopyToClipboard>
        <CopyToClipboard
          style={{ cursor: "pointer", marginBottom: "6px" }}
          text={row.appointment ? row.appointment.patient.email : ""}
          onCopy={() =>
            message.info(
              <>
                <strong>{row.appointment ? row.appointment.patient.email : ""}</strong> copied to clipboard
              </>
            )
          }>
          <Button size="small" block>
            Copy email
          </Button>
        </CopyToClipboard>
        <Button size="small" type="primary" block onClick={this.redirectToEditPatient} style={{ marginBottom: "6px" }}>
          Edit patient
        </Button>
        <Button size="small" type="primary" block onClick={this.redirectToEditQuestionnaire}>
          Edit Questionnaire
        </Button>
      </>
    );

    return (
      <Popover placement="right" content={content} trigger="click">
        {row.isQuestionnaireCompleted ? <>✅</> : <></>} {fieldValue}
      </Popover>
    );
  }
}

export default PopoverMenu;
