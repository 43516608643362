import { call, put, takeLatest, all, select } from "redux-saga/effects";
import { message } from "antd";
import API from "./../../Infrastructure/API";
import Logger from "./../../Infrastructure/Logger";
import { getSalesforceAccountInfo } from "./../../Features/SalesforceAccount/SalesforceAccount.actions";
import {
  saveTrackerData,
  updateLoadingStatus,
  clearTrackerData,
  IAction,
  saveInsuranceProviders,
  saveAnotherTrackers,
  IUpdateAppointment,
  IUpdatePatient,
  saveStatusOfQuestionnaires,
} from "./../../Features/Tracker/Tracker.actions";
import {
  ADD_PATIENT_TO_WAITLIST,
  GET_ANOTHER_TRACKERS,
  GET_INSURANCE_PROVIDERS,
  GET_TRACKER_DATA,
  REFRESH_TRACKER_DATA,
  UPDATE_APPOINTMENT,
  UPDATE_PATIENT,
} from "./Tracker.constants";
import { IAddPatientToWaitlist, IInsuranceProvider, ITracker } from "./Tracker.interfaces";
import { IStore } from "src/Infrastructure/Store/store.interface";
import moment from "moment";

function* getSingleTrackerAsync(action: IAction): Generator {
  try {
    const trackerId = action.payload as string;
    yield put(clearTrackerData());
    yield put(updateLoadingStatus({ property: "tracker", value: true }));
    const tracker: any = yield call(API.getSingleTracker, trackerId);

    const company: any = yield call(API.getCompanyByLocationId, tracker.locationId);

    const patientIds = tracker.timeslots
      .map((timeslot) => timeslot.appointments.map((appointment) => appointment.patientId).flat())
      .flat();

    const questionnairesAvailability: any = yield call(API.getQuestionnaireAvailabilityByPatientIds, patientIds);
    yield put(saveStatusOfQuestionnaires(questionnairesAvailability));

    yield put(getSalesforceAccountInfo(company.SFAccountId));

    yield put(saveTrackerData({ ...tracker, company } as ITracker));
    yield put(updateLoadingStatus({ property: "tracker", value: false }));
  } catch (error) {
    Logger.error(error);
  }
}

function* refreshTrackerAsync(action: IAction): Generator {
  try {
    const trackerId = action.payload as string;
    const tracker: any = yield call(API.getSingleTracker, trackerId);
    const company: any = yield call(API.getCompanyByLocationId, tracker.locationId);

    yield put(getSalesforceAccountInfo(company.SFAccountId));

    yield put(saveTrackerData({ ...tracker, company } as ITracker));
  } catch (error) {
    Logger.error(error);
  }
}

function* getInsuranceProvidersAsync(): Generator {
  try {
    const insuranceProviders: any = yield call(API.getInsuranceProviders);
    yield put(saveInsuranceProviders(insuranceProviders as IInsuranceProvider));
  } catch (error) {
    Logger.error(error);
  }
}

function* getAnotherTrackersAsync(): Generator {
  try {
    // @ts-ignore
    const state: IStore = yield select();
    const locationId = state.spreadsheet.tracker?.locationId;

    yield put(updateLoadingStatus({ property: "anotherTrackers", value: true }));

    // @ts-ignore
    const trackers: ITracker[] = yield call(API.getTrackers, {
      locationIds: [locationId],
      statuses: ["active_booked"],
    });

    yield put(saveAnotherTrackers(trackers));
    yield put(updateLoadingStatus({ property: "anotherTrackers", value: false }));
  } catch (error) {
    Logger.error(error);
  }
}

function* updateAppointmentAsync(action: IAction): Generator {
  try {
    // @ts-ignore
    const state: IStore = yield select();
    const tracker = state.spreadsheet.tracker;
    const visitId = tracker!.id;
    const { timeslotId, appointmentId, property, value } = action.payload as IUpdateAppointment;
    const timeslot = tracker?.timeslots.find((t) => t.id === timeslotId);
    const appointment = timeslot?.appointments.find((a) => a.id === appointmentId);

    if (appointment) {
      yield call(API.updateAppointment, visitId, appointmentId, {
        status: appointment.status,
        type: appointment.type,
        dilationType: appointment.dilationType,
        payment: appointment.payment,
        survey: {
          ...appointment.survey,

          ...(property === "examInsurance" && {
            primaryInsurance: {
              ...appointment.survey.primaryInsurance,
              insuranceId: value.id,
              noInsurance: value === null ? true : false,
            },
          }),

          ...(property === "materialsInsurance" && {
            secondaryInsurance: {
              ...appointment.survey.secondaryInsurance,
              insuranceId: value.id,
              noInsurance: value === null ? true : false,
            },
          }),
        },
        materialsResponsibility: appointment.materialsResponsibility,
        examResponsibility: appointment.examResponsibility,
        notes: appointment.notes,
        notesColor: appointment.notesColor,
        examInsurance: appointment.examInsurance,
        materialsInsurance: appointment.materialsInsurance,
        examCode: appointment.examCode,
        [property]: value,
        ...(property === "materialsInsurance" && {
          materialsInsurance: value.value,
        }),
        ...(property === "examInsurance" && {
          examInsurance: value.value,
        }),
      });
    }
  } catch (error) {
    Logger.error(error);
  }
}

function* updatePatientAsync(action: IAction): Generator {
  try {
    const { patientId, property, value } = action.payload as IUpdatePatient;

    const patient: any = yield call(API.getPatient, patientId);
    if (patient) {
      yield call(API.updatePatient, patient.id, {
        firstName: patient.firstName,
        lastName: patient.lastName,
        email: patient.email,
        gender: patient.gender,
        birth: patient.birth,
        phoneNumber: patient.phoneNumber,
        notes: patient.notes,
        ehrId: patient.ehrId,
        ...(patient.addresses.length > 0
          ? {
              address: {
                type: patient.addresses[0].type,
                city: patient.addresses[0].city,
                street: patient.addresses[0].street,
                zipCode: patient.addresses[0].zipCode,
                state: patient.addresses[0].state,
              },
            }
          : {}),
        [property]: value,
      });
    }
  } catch (error) {
    Logger.error(error);
  }
}

function* addPatientToWaitlistAsync(action: IAction): Generator {
  try {
    const body = action.payload as IAddPatientToWaitlist;
    yield call(API.addPatientToWaitlist, body);
    message.success("Added to waitlist");
  } catch (error) {
    message.error("Failed to add user to waitlist");
  }
}

export default function* root(): Generator {
  yield all([
    takeLatest(GET_TRACKER_DATA, getSingleTrackerAsync),
    takeLatest(GET_INSURANCE_PROVIDERS, getInsuranceProvidersAsync),
    takeLatest(GET_ANOTHER_TRACKERS, getAnotherTrackersAsync),
    takeLatest(REFRESH_TRACKER_DATA, refreshTrackerAsync),
    takeLatest(UPDATE_APPOINTMENT, updateAppointmentAsync),
    takeLatest(UPDATE_PATIENT, updatePatientAsync),
    takeLatest(ADD_PATIENT_TO_WAITLIST, addPatientToWaitlistAsync),
  ]);
}
