import React from "react";
import styled from "styled-components";

import Week from "./../../../Features/Calendar/components/Week.component";
import { IWeek } from "./../../../Features/Calendar/Calendar.interfaces";

interface IProps {
  selectedMonth: Date;
  weeks: IWeek[];
  showHoldReservations: boolean;
}

const Calendar = (props: IProps) => {
  const { weeks, selectedMonth, showHoldReservations } = props;

  return (
    <Container>
      {weeks.map((week, index) => (
        <Week
          key={week.weekNumber}
          showHoldReservations={showHoldReservations}
          selectedMonth={selectedMonth}
          weekNumber={index + 1}
          week={week}
        />
      ))}
    </Container>
  );
};

export { Calendar };

const Container = styled.div`
  border: 1px solid #000000;
`;
