import React from "react";
import { orderBy } from "lodash";
import { Modal, Button, Divider, Tag, Popconfirm, Spin } from "antd";
import styled from "styled-components";
import moment from "moment-timezone";

import { formatDate } from "./../../../../Features/UI/helpers/index.helper";
import TimeslotsContainer from "./../../../../Features/UI/components/TimeslotsContainer.component";
import { EmptyList, DateHeading } from "./../../../../Features/UI/components/global.components";
import { IState as ISpreadsheetState } from "../../Tracker.reducer";
import { ISelectedAppointmentForRescheduling, ISpreadsheetRow } from "../../Tracker.interfaces";
import { isTimeslotAvailableToBook } from "../../Tracker.helpers";

interface IProps {
  spreadsheet: ISpreadsheetState;
  selectedTimeslot: ISelectedAppointmentForRescheduling | null;
  isTimepickerModalVisible: boolean;
  selectNewTimeslot: (payload: { trackerId: string; timeslotId: string }) => void;
  toggleTimepickerModal: (row: ISpreadsheetRow | null) => void;
  rescheduleAppointment: () => void;
}

const TimepickerModal = (props: IProps) => {
  const {
    spreadsheet,
    selectedTimeslot,
    isTimepickerModalVisible,
    toggleTimepickerModal,
    selectNewTimeslot,
    rescheduleAppointment,
  } = props;
  const currentTracker = spreadsheet.anotherTrackers.find((t) => t.id === spreadsheet.tracker?.id);
  const currentTrackerTimeslots = currentTracker
    ? orderBy(currentTracker.timeslots.filter(isTimeslotAvailableToBook), "startDate", "asc")
    : [];

  const anotherTrackers = spreadsheet.anotherTrackers
    .filter((t) => t.id !== spreadsheet.tracker?.id)
    .sort((t1, t2) => new Date(t1.date).getTime() - new Date(t2.date).getTime());

  return (
    <Modal
      title="Change appointment time"
      visible={isTimepickerModalVisible}
      onCancel={() => toggleTimepickerModal(null)}
      footer={[
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to change appointment time?"
          onConfirm={rescheduleAppointment}
          okText="Yes, I am"
          cancelText="No"
          disabled={!(selectedTimeslot?.id !== selectedTimeslot?.target?.timeslotId)}>
          <Button
            data-cy="reschedule-appointment-save-button"
            type="primary"
            disabled={!(selectedTimeslot?.id !== selectedTimeslot?.target?.timeslotId)}>
            Save
          </Button>
        </Popconfirm>,
      ]}>
      {!spreadsheet.isLoading.anotherTrackers && currentTracker && currentTracker.id && (
        <div>
          <DateHeading>
            {formatDate(moment(currentTracker.date, "YYYY-MM-DD").hour(11).toDate(), currentTracker.timezone, "LL")}
            <Tag style={{ marginLeft: "15px" }} color="blue">
              Current tracker
            </Tag>
          </DateHeading>
          {currentTrackerTimeslots.length > 0 ? (
            <TimeslotsContainer
              trackerId={currentTracker.id}
              selectedTimeslot={selectedTimeslot}
              availableTimeslots={currentTrackerTimeslots}
              timezone={currentTracker.timezone}
              selectNewTimeslot={selectNewTimeslot}
            />
          ) : (
            <EmptyList>No available timeslots</EmptyList>
          )}
        </div>
      )}
      {!spreadsheet.isLoading.anotherTrackers && anotherTrackers && anotherTrackers.length > 0 && (
        <>
          {anotherTrackers.map((tracker) => {
            const anotherTrackerTimeslots = orderBy(
              tracker.timeslots.filter(isTimeslotAvailableToBook),
              "startDate",
              "asc"
            );
            return (
              <div key={tracker.id}>
                <Divider dashed />
                <DateHeading>
                  {formatDate(moment(tracker.date, "YYYY-MM-DD").hour(11).toDate(), tracker.timezone, "LL")}
                </DateHeading>
                {anotherTrackerTimeslots.length > 0 ? (
                  <TimeslotsContainer
                    trackerId={tracker.id}
                    timezone={tracker.timezone}
                    selectedTimeslot={selectedTimeslot}
                    availableTimeslots={anotherTrackerTimeslots}
                    selectNewTimeslot={selectNewTimeslot}
                  />
                ) : (
                  <EmptyList>No available timeslots</EmptyList>
                )}
              </div>
            );
          })}
        </>
      )}

      {spreadsheet.isLoading.anotherTrackers && (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      )}
    </Modal>
  );
};

export default TimepickerModal;

const SpinContainer = styled.div`
  text-align: center;
`;
