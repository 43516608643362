import {
  GET_WAITING_ROOM_DATA,
  SAVE_WAITING_ROOM_DATA,
  SELECT_PATIENT_FOR_EXAM,
  UPDATE_LOADING_STATUS_WAITING_ROOM,
} from "./WaitingRoom.constants";
import { ITracker } from "../Tracker/Tracker.interfaces";

export const updateLoadingStatus = (isUpdating: boolean) => {
  return {
    type: UPDATE_LOADING_STATUS_WAITING_ROOM,
    payload: isUpdating,
  };
};

export const getWaitingRoomPatients = () => {
  return {
    type: GET_WAITING_ROOM_DATA,
  };
};

export const saveWaitingRoomData = (data: ITracker[]) => {
  return {
    type: SAVE_WAITING_ROOM_DATA,
    payload: data,
  };
};

export const selectPatientForExam = (trackerId: string, timeslotId: string, appointmentId: string, history: any) => {
  return {
    type: SELECT_PATIENT_FOR_EXAM,
    payload: {
      trackerId,
      timeslotId,
      appointmentId,
      history,
    },
  };
};
