import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import styled from "styled-components";
import { Form } from "antd";
import { FormComponentProps } from "antd/es/form";

import { IStore } from "./../../../Infrastructure/Store/store.interface";

import VisitNotesComponent from "./../../../Features/Notes/components/VisitNotes.component";
import { IState as ISpreadsheetState } from "../../Tracker/Tracker.reducer";
import API from "../../../Infrastructure/API/index";

interface IProps extends FormComponentProps {
  spreadsheet: ISpreadsheetState;
}

interface IState {
  isUpdating: boolean;
}

class VisitNotes extends Component<IProps, IState> {
  public state: IState = {
    isUpdating: false,
  };

  public updateVisitNotes = async () => {
    const { form, spreadsheet } = this.props;
    const { tracker } = spreadsheet;

    this.setState({ isUpdating: true });

    await API.updateTracker(tracker!.id, {
      name: tracker!.name,
      date: tracker!.date,
      locationId: tracker!.locationId,
      timezone: tracker!.timezone,
      parkingLocationDetails: tracker!.parkingLocationDetails,
      SFVisitId: tracker!.SFVisitId,
      trackingNumber: tracker!.trackingNumber,
      issueNotes: tracker!.issueNotes,
      notes: form.getFieldValue("visitNotes"),
    });

    this.setState({ isUpdating: false });
  };

  public render() {
    const { spreadsheet, form } = this.props;
    return (
      <Container>
        <VisitNotesComponent
          form={form}
          notes={spreadsheet.tracker?.notes}
          isUpdating={this.state.isUpdating}
          updateVisitNotes={this.updateVisitNotes}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state: IStore) => {
  return {
    spreadsheet: state.spreadsheet,
  };
};

const mapDispatchToProps = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), Form.create({}))(VisitNotes);

const Container = styled.div`
  margin-bottom: 20px;
`;
