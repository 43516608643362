import axios from "axios";
import Logger from "./../../Infrastructure/Logger/index";
import Config from "../../config/config";
import { ISearchQuery } from "./../../Features/PatientLookup/PatientLookup.interfaces";
import {
  IAddEmployeeToTracker,
  IAddPatientToWaitlistBody,
  IAddProductToAppointment,
  IFindVisitsBody,
  IUpdateAppointment,
  IUpdateAppointmentProduct,
  IUpdatePatient,
  IUpdateTracker,
} from "./interfaces/request";

axios.defaults.withCredentials = true;

export default class API {
  public static API_URL = Config.get("API_URL");
  public static headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  public static trackerURL = `${Config.get("API_URL")}/tracker`;
  public static usersURL = `${Config.get("AUTHORIZATION_URL")}/authorization`;
  public static salesforceURL = `${Config.get("SALESFORCE_URL")}`;

  public static async isUserAuthenticated() {
    return axios.get(`${API.API_URL}/authorization/accounts/me`, {
      headers: API.headers,
    });
  }

  public static async getTrackers(payload: IFindVisitsBody) {
    try {
      const trackers = await axios.get(`${API.API_URL}/booking/visits/trackers`, {
        headers: API.headers,
        params: payload,
      });

      return trackers.data;
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async getSingleTracker(trackerId: string) {
    try {
      const tracker = await axios.get(`${API.API_URL}/booking/visits/${trackerId}/tracker`, { headers: API.headers });
      return tracker.data;
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async getQuestionnaireAvailabilityByPatientIds(patientIds: string[]) {
    try {
      const result = await axios.post(
        `${API.API_URL}/booking/questionnaires/patients/check-questionnaire-availability`,
        { patientIds },
        { headers: API.headers }
      );
      return result.data;
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async generateTrackerCSV(trackerId: string) {
    try {
      const tracker = await axios.get(`${API.API_URL}/booking/visits/${trackerId}/tracker/csv`, {
        headers: API.headers,
      });
      return tracker.data;
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async rescheduleAppointment(
    id: string,
    appointmentId: string,
    body: {
      targetVisitId: string;
      targetTimeslotId: string;
      timeslotId: string;
    }
  ) {
    try {
      await axios.post(`${API.API_URL}/booking/visits/${id}/appointments/${appointmentId}/reschedule`, body, {
        headers: API.headers,
      });
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async getInsuranceProviders() {
    try {
      const tracker = await axios.get(`${API.API_URL}/booking/insurances`, {
        headers: API.headers,
      });
      return tracker.data;
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async updateAppointment(id: string, appointmentId: string, body: IUpdateAppointment) {
    try {
      await axios.patch(`${API.API_URL}/booking/visits/${id}/appointments/${appointmentId}`, body, {
        headers: API.headers,
      });
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async addProductToAppointment(id: string, appointmentId: string, body: IAddProductToAppointment[]) {
    try {
      await axios.post(`${API.API_URL}/booking/visits/${id}/appointments/${appointmentId}/products`, body, {
        headers: API.headers,
      });
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async updateAppointmentProduct(id: string, appointmentId: string, body: IUpdateAppointmentProduct[]) {
    try {
      await axios.patch(`${API.API_URL}/booking/visits/${id}/appointments/${appointmentId}/products`, body, {
        headers: API.headers,
      });
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async removeAppointmentProduct(id: string, appointmentId: string, productId: string) {
    try {
      await axios.delete(`${API.API_URL}/booking/visits/${id}/appointments/${appointmentId}/products/${productId}`, {
        headers: API.headers,
      });
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async updateTracker(id: string, body: IUpdateTracker) {
    try {
      await axios.patch(`${API.API_URL}/booking/visits/${id}`, body, {
        headers: API.headers,
      });
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async synchronizeTracker(trackerId: string) {
    try {
      const response = await axios.post(
        `${API.API_URL}/salesforce/synchronizer/visit/${trackerId}`,
        {},
        { headers: API.headers }
      );

      return response.data;
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async getTrackerStatistics(trackerId: string) {
    try {
      const statistics = await axios.get(`${API.API_URL}/booking/visits/${trackerId}/statistics`, {
        headers: API.headers,
      });

      return statistics.data;
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async getEmployees() {
    try {
      const statistics = await axios.get(`${API.API_URL}/authorization/accounts-with-positions`, {
        headers: API.headers,
      });

      return statistics.data;
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async addEmployeeToTracker(id: string, body: IAddEmployeeToTracker) {
    try {
      const statistics = await axios.post(`${API.API_URL}/booking/visits/${id}/employees`, body, {
        headers: API.headers,
      });

      return statistics.data;
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async removeEmployeeFromVisit(id: string, employeeId: string) {
    try {
      const statistics = await axios.delete(`${API.API_URL}/booking/visits/${id}/employees/${employeeId}`, {
        headers: API.headers,
      });

      return statistics.data;
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async updatePatient(id: string, body: IUpdatePatient) {
    try {
      await axios.patch(`${API.API_URL}/booking/patients/${id}`, body, {
        headers: API.headers,
      });
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async getPatient(id: string) {
    try {
      const response = await axios.get(`${API.API_URL}/booking/patients/${id}`, {
        headers: API.headers,
      });
      return response.data;
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async getSalesforceAccountInfo(SFAccountId: string) {
    try {
      const response = await axios.get(`${API.API_URL}/salesforce/accounts/${SFAccountId}/info`, {
        headers: API.headers,
      });

      return response.data;
    } catch (error) {
      Logger.error(error);
      throw error;
    }
  }

  public static async searchPatients(params: ISearchQuery) {
    try {
      const patients = await axios.get(`${API.API_URL}/booking/patients`, {
        headers: API.headers,
        params,
      });

      return patients.data;
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async findTrucks() {
    try {
      const trucks = await axios.get(`${API.API_URL}/booking/trucks`, {
        headers: API.headers,
      });

      return trucks.data;
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async getCompanyByLocationId(locationId: string) {
    try {
      const response = await axios.get(`${API.API_URL}/booking/companies/by-location/${locationId}`, {
        headers: API.headers,
      });

      return response.data;
    } catch (error) {
      return Logger.error(error);
    }
  }

  public static async addPatientToWaitlist(body: IAddPatientToWaitlistBody) {
    try {
      const response = await axios.post(`${API.API_URL}/waitlist/subscriptions`, body, {
        headers: API.headers,
      });

      return response.data;
    } catch (error) {
      Logger.error(error);
      throw error;
    }
  }
}
