import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "antd/dist/antd.css";
import "./Features/UI/styles/index.css";
import "react-data-grid-multiline-header/style.css";

import Socket from "./Features/UI/components/Socket.component";
import Calendar from "./Features/Calendar/Calendar.container";
import PatientLookup from "./Features/PatientLookup/PatientLookup.container";
import WaitingRoom from "./Features/WaitingRoom/WaitingRoom.container";
import TrackerInfo from "./Pages/TrackerInfo.page";

class Main extends React.Component {
  public render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={() => <TrackerInfo />} />

          <Route
            path="/patient-lookup"
            component={() => {
              return (
                <div>
                  <PatientLookup />
                </div>
              );
            }}
          />

          <Route
            path="/calendar"
            component={() => {
              return <Calendar />;
            }}
          />

          <Route
            path="/waiting-room"
            component={() => {
              return (
                <div>
                  <WaitingRoom />
                </div>
              );
            }}
          />
        </Switch>
        <Socket />
      </Router>
    );
  }
}

export default Main;
